import { INITIAL_FORM_VALUES } from 'constants/profile';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  settingsData: INITIAL_FORM_VALUES,
  loading: false,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SETTINGS_FETCH_SETTINGS:
    case actionTypes.SETTINGS_UPDATE_SETTINGS:
      return {
        ...state,
        settingsData: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
