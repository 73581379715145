export default function Input(theme) {
  const { palette, spacing } = theme;
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 40,
          backgroundColor: palette.common.white,
          '&.Mui-disabled': {
            '& svg': {
              color: palette.text.disabled,
            },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: palette.grey[500_56],
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiFormLabel-root': {
            transform: `translate(${spacing(2)}, ${spacing(1)}) scale(1)`,
          },
        },
        shrink: {
          '&.MuiInputLabel-outlined.MuiFormLabel-root': {
            transform: `translate(${spacing(2)}, ${spacing(-1)}) scale(0.75)`,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[500_12],
          '&:hover': {
            backgroundColor: palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey[500_32],
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.action.disabledBackground,
            },
          },
        },
        input: {
          padding: `${spacing(1)} ${spacing(2)}`,
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: 0,
          },
        },
      },
    },
  };
}
