import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

const ButtonStyle = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    ml: pxToRem(16),
    p: pxToRem(8),
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
    height: pxToRem(40),
    width: pxToRem(40),
    '&.Mui-disabled': {
      color: theme.palette.grey[600],
    },
  }),
);

type CircleButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  id?: string;
  onClick?: () => void;
};

const CircleButton = ({
  children,
  disabled,
  id,
  onClick,
}: CircleButtonProps) => (
  <ButtonStyle
    aria-label="refresh"
    id={id}
    color="primary"
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </ButtonStyle>
);

export default CircleButton;
