import { LAST_REQUEST_ID } from 'constants/localStorage';
import { logAction } from 'lib/amplitude';
import { ApiError, MessageConfig } from 'types/global.d';
import actionTypes from './actionTypes';
import '../reducers/messages';

/**
 * Gets the ID of the last request executed.
 */
export const getLastRequestId = () => {
  const lastRequestId = localStorage.getItem(LAST_REQUEST_ID) || '';

  return {
    type: actionTypes.SET_LAST_REQUEST_ID,
    payload: lastRequestId,
  };
};

/**
 * Updates the "Message Config" value into the Store.
 * @param message
 */
export const onUpdateMessageConfig = (message: MessageConfig) => {
  if (message.requestId) {
    // Store the Last Request Id into the Session Storage.
    localStorage.setItem(LAST_REQUEST_ID, message.requestId);
  }

  return {
    type: actionTypes.UPDATE_MESSAGE_CONFIG,
    payload: message,
  };
};

/**
 * Handles an API error by generating an error on the console, loging the error into Amplitude
 * and displaying an alert with a customized message.
 * @param errorData: Object
 */
export const handleError =
  ({
    error,
    consoleMessage,
    amplitudePage,
    amplitudeEvent,
    alertMessage,
  }: ApiError) =>
  (dispatch) => {
    // Log the error into the console.
    console.error(`${consoleMessage}: ${error}`);

    // Log an action into Amplitude letting it know there was an error.
    logAction(amplitudePage, `${amplitudeEvent}: Failed`, {
      error,
      status_code: error.status,
      request_id: error.requestId,
    });

    const errorMessage = error.details?.length
      ? error.details[0].detail
      : error.description;

    // Set a "Message Config" based on the error and update it into the Store.
    const message = {
      open: true,
      message: errorMessage || alertMessage,
      position: {
        vertical: 'top',
        horizontal: 'center',
      },
      alertSeverity: 'error',
      status: error.status,
      requestId: error.requestId,
    };

    dispatch(onUpdateMessageConfig(message));
  };

export const closeAlertMessage = () => (dispatch, getState) => {
  const {
    messages: { messageConfig },
  } = getState();

  if (messageConfig.open) {
    dispatch(
      onUpdateMessageConfig({
        ...messageConfig,
        open: false,
      }),
    );
  }
};
