import React from 'react';
import palette from 'components/theme/palette';

type Props = {
  taskType:
    | 'charge'
    | 'diagnose'
    | 'move'
    | 'move'
    | 'retrieve'
    | 'service'
    | 'swap';
};

export const Ebike = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M37.043 26h-2.45l-3.81 9.044M42.608 30.174h-1.46l-.627 1.391M34.4 30.638l3.328 4.266H40.8"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={31.2}
      cy={34.904}
      r={3.2}
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
    <circle
      cx={40.8}
      cy={34.904}
      r={3.2}
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
  </>
);

export const EbikeHighest = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M37.043 26h-2.45l-3.81 9.044M42.608 30.174h-1.46l-.627 1.391M34.4 30.638l3.328 4.266H40.8"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={31.2}
      cy={34.904}
      r={3.2}
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
    <circle
      cx={40.8}
      cy={34.904}
      r={3.2}
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
    <g>
      <path
        d="M45 30a7 7 0 0 1-7-7c0-3.786 3.22-7.004 7.006-7A7 7 0 0 1 45 30Z"
        fill={palette.common.black}
      />
      <path
        d="m42 22.2 3-3 3 3M42 26.2l3-3 3 3"
        stroke={palette.common.white}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
);

export const Moped = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.318 25.598a.478.478 0 0 1 .429-.268h2.167a.478.478 0 1 1 0 .955h-1.869l-1.178 2.485a.478.478 0 1 1-.859-.419l1.31-2.753ZM31.506 32.665c.217.112.303.38.19.597l-1.552 3.003a.443.443 0 1 1-.788-.407l1.552-3.003a.443.443 0 0 1 .598-.19Z"
      fill={palette.common.white}
      stroke={palette.common.white}
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.75 34.336a1.795 1.795 0 1 0 0 3.59 1.795 1.795 0 0 0 0-3.59ZM27 36.131a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM39.54 36.13a1.795 1.795 0 1 0 3.59 0h.956a2.75 2.75 0 0 1-5.501 0h.956Z"
      fill={palette.common.white}
      stroke={palette.common.white}
      strokeWidth={0.6}
    />
    <path
      d="M34.434 27.82s-2.46 6.763 1.24 6.763c3.5 0 3.559-1.908 2.917-2.55H42.5c.776.592 3.637 4.03 1.645 4.03-3.39 0-6.7-.004-9.916-.004-.822.036-.919-.633-.919-.633-.25-.919-1.203-2.561-2.45-2.561-1.157 0 1.441-4.083 2.328-5.044h1.247Z"
      fill={palette.common.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.067 27.71a.163.163 0 0 1 .12-.053h1.247a.163.163 0 0 1 .154.22l-.154-.056.154.056-.002.004-.005.014a7.32 7.32 0 0 0-.09.267c-.06.183-.14.444-.226.758a13.72 13.72 0 0 0-.435 2.297c-.076.837-.033 1.647.238 2.242.135.294.324.531.58.696.255.165.59.265 1.027.265 1.728 0 2.548-.472 2.875-.962.327-.49.194-1.041-.074-1.31a.163.163 0 0 1 .116-.278h3.907c.036 0 .07.012.1.034.208.158.541.495.89.902.35.41.723.903 1.01 1.379.144.238.268.475.357.698.087.221.144.438.144.633 0 .198-.06.386-.216.521-.153.13-.37.188-.64.188l-5.023-.001h-.07l-4.82-.002c-.461.02-.74-.162-.9-.366a.967.967 0 0 1-.181-.393v-.002a4.625 4.625 0 0 0-.819-1.557c-.414-.512-.922-.876-1.472-.876a.465.465 0 0 1-.266-.074.41.41 0 0 1-.157-.215c-.053-.158-.034-.36.015-.565.1-.42.353-.976.662-1.549.62-1.147 1.498-2.42 1.954-2.915Zm.383 7.615.829.57 4.777.002h.065l5.023.002c.23 0 .358-.05.427-.11.065-.055.103-.14.102-.273 0-.137-.04-.31-.121-.513-.08-.2-.194-.42-.332-.65a10.552 10.552 0 0 0-.98-1.335 7.28 7.28 0 0 0-.797-.821H38.91c.21.395.24.95-.09 1.442-.416.625-1.376 1.107-3.146 1.107-.488 0-.887-.111-1.204-.316a1.912 1.912 0 0 1-.7-.836c-.307-.673-.344-1.553-.267-2.407.078-.858.272-1.714.446-2.353.087-.32.17-.586.23-.773.01-.027.018-.053.025-.077h-.945c-.444.502-1.269 1.702-1.86 2.797-.306.568-.541 1.093-.63 1.469-.047.192-.047.318-.024.386.01.03.021.041.03.048.011.007.035.018.083.018.697 0 1.289.456 1.726.996.422.522.726 1.152.865 1.627Z"
      fill={palette.common.white}
    />
    <path
      d="M36.914 31.618c0-.208.169-.376.376-.376h5.678a.376.376 0 0 1 0 .752H37.29a.376.376 0 0 1-.376-.376Z"
      fill={palette.common.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.47 31.618a.82.82 0 0 1 .82-.82h5.678a.82.82 0 0 1 0 1.64H37.29a.82.82 0 0 1-.82-.82Z"
      fill={palette.common.white}
    />
  </>
);

export const MopedHighest = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.318 25.598a.478.478 0 0 1 .429-.268h2.167a.478.478 0 1 1 0 .955h-1.869l-1.178 2.485a.478.478 0 1 1-.859-.419l1.31-2.753ZM31.506 32.665c.217.112.303.38.19.597l-1.552 3.003a.443.443 0 1 1-.788-.407l1.552-3.003a.443.443 0 0 1 .598-.19Z"
      fill={palette.common.white}
      stroke={palette.common.white}
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.75 34.336a1.795 1.795 0 1 0 0 3.59 1.795 1.795 0 0 0 0-3.59ZM27 36.131a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM39.54 36.13a1.795 1.795 0 1 0 3.59 0h.956a2.75 2.75 0 0 1-5.501 0h.956Z"
      fill={palette.common.white}
      stroke={palette.common.white}
      strokeWidth={0.6}
    />
    <path
      d="M34.434 27.82s-2.46 6.763 1.24 6.763c3.5 0 3.559-1.908 2.917-2.55H42.5c.776.592 3.637 4.029 1.645 4.029-3.39 0-6.7-.003-9.916-.003-.822.035-.919-.633-.919-.633-.25-.919-1.203-2.561-2.45-2.561-1.157 0 1.441-4.083 2.328-5.044h1.247Z"
      fill={palette.common.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.067 27.71a.163.163 0 0 1 .12-.053h1.247a.163.163 0 0 1 .154.22l-.154-.056.154.056-.002.004-.005.014a7.32 7.32 0 0 0-.09.267c-.06.183-.14.444-.226.758a13.72 13.72 0 0 0-.435 2.297c-.076.837-.033 1.647.238 2.242.135.294.324.531.58.696.255.165.59.265 1.027.265 1.728 0 2.548-.472 2.875-.962.327-.49.194-1.041-.074-1.31a.163.163 0 0 1 .116-.278h3.907c.036 0 .07.012.1.034.208.158.541.495.89.902.35.41.723.903 1.01 1.379.144.238.268.475.357.698.087.221.144.438.144.633 0 .198-.06.386-.216.521-.153.13-.37.188-.64.188l-5.023-.001h-.07l-4.82-.002c-.461.02-.74-.162-.9-.366a.967.967 0 0 1-.181-.393v-.002a4.625 4.625 0 0 0-.819-1.557c-.414-.512-.922-.876-1.472-.876a.465.465 0 0 1-.266-.074.41.41 0 0 1-.157-.215c-.053-.158-.034-.36.015-.565.1-.42.353-.976.662-1.549.62-1.147 1.498-2.42 1.954-2.915Zm.383 7.615.829.57 4.777.002h.065l5.023.002c.23 0 .358-.05.427-.11.065-.055.103-.14.102-.273 0-.137-.04-.31-.121-.513-.08-.2-.194-.42-.332-.65a10.552 10.552 0 0 0-.98-1.335 7.28 7.28 0 0 0-.797-.821H38.91c.21.395.24.95-.09 1.442-.416.625-1.376 1.107-3.146 1.107-.488 0-.887-.111-1.204-.316a1.912 1.912 0 0 1-.7-.836c-.307-.673-.344-1.553-.267-2.407.078-.858.272-1.714.446-2.353.087-.32.17-.586.23-.773.01-.027.018-.053.025-.077h-.945c-.444.502-1.269 1.702-1.86 2.797-.306.568-.541 1.093-.63 1.469-.047.192-.047.318-.024.386.01.03.021.041.03.048.011.007.035.018.083.018.697 0 1.289.456 1.726.996.422.522.726 1.152.865 1.627Z"
      fill={palette.common.white}
    />
    <path
      d="M36.914 31.618c0-.208.169-.376.376-.376h5.678a.376.376 0 0 1 0 .752H37.29a.376.376 0 0 1-.376-.376Z"
      fill={palette.common.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.47 31.618a.82.82 0 0 1 .82-.82h5.678a.82.82 0 0 1 0 1.64H37.29a.82.82 0 0 1-.82-.82Z"
      fill={palette.common.white}
    />
    <g>
      <path
        d="M45 30a7 7 0 0 1-7-7c0-3.786 3.22-7.004 7.006-7A7 7 0 0 1 45 30Z"
        fill={palette.common.black}
      />
      <path
        d="m42 22.2 3-3 3 3M42 26.2l3-3 3 3"
        stroke={palette.common.white}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
);

export const Scooter = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M40.371 37.696H33.75l-2.81-3.763 3.39-9.183h3.092"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.125 37.468a1.31 1.31 0 0 1-1.313 1.307 1.31 1.31 0 0 1-1.312-1.307c0-.72.586-1.308 1.313-1.308a1.31 1.31 0 0 1 1.312 1.308ZM43.5 37.468a1.31 1.31 0 0 1-1.313 1.307 1.31 1.31 0 0 1-1.312-1.307c0-.72.586-1.308 1.313-1.308a1.31 1.31 0 0 1 1.312 1.308Z"
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
  </>
);

export const ScooterHighest = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M40.371 37.696H33.75l-2.81-3.763 3.39-9.183h3.092"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.125 37.468a1.31 1.31 0 0 1-1.313 1.307 1.31 1.31 0 0 1-1.312-1.307c0-.72.586-1.308 1.313-1.308a1.31 1.31 0 0 1 1.312 1.308ZM43.5 37.468a1.31 1.31 0 0 1-1.313 1.307 1.31 1.31 0 0 1-1.312-1.307c0-.72.586-1.308 1.313-1.308a1.31 1.31 0 0 1 1.312 1.308Z"
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
    <g>
      <path
        d="M45 30a7 7 0 0 1-7-7c0-3.786 3.22-7.004 7.006-7A7 7 0 0 1 45 30Z"
        fill={palette.common.black}
      />
      <path
        d="m42 22.2 3-3 3 3M42 26.2l3-3 3 3"
        stroke={palette.common.white}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
);

export const ScooterSeated = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M36.84 24.75h-3.498l-2.404 8.668 2.812 3.933h6.443"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.404 31.156h-3.656l-1.423 5.71"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.125 37.696c0 .717-.585 1.304-1.313 1.304a1.308 1.308 0 0 1-1.312-1.304c0-.717.585-1.304 1.313-1.304.727 0 1.312.587 1.312 1.304ZM43.5 37.696c0 .717-.585 1.304-1.313 1.304a1.308 1.308 0 0 1-1.312-1.304c0-.717.585-1.304 1.313-1.304.727 0 1.312.587 1.312 1.304Z"
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
  </>
);

export const ScooterSeatedHighest = ({ taskType }: Props) => (
  <>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.69 47.572C46.747 45.907 52 39.567 52 32c0-8.837-7.163-16-16-16s-16 7.163-16 16c0 7.567 5.253 13.907 12.31 15.572L36 52l3.69-4.428Z"
        fill={palette.common.white}
      />
    </g>
    <circle cx={36} cy={32} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M36.84 24.75h-3.498l-2.404 8.668 2.812 3.933h6.443"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.404 31.156h-3.656l-1.423 5.71"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.125 37.696c0 .717-.585 1.304-1.313 1.304a1.308 1.308 0 0 1-1.312-1.304c0-.717.585-1.304 1.313-1.304.727 0 1.312.587 1.312 1.304ZM43.5 37.696c0 .717-.585 1.304-1.313 1.304a1.308 1.308 0 0 1-1.312-1.304c0-.717.585-1.304 1.313-1.304.727 0 1.312.587 1.312 1.304Z"
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
    <g>
      <path
        d="M45 30a7 7 0 0 1-7-7c0-3.786 3.22-7.004 7.006-7A7 7 0 0 1 45 30Z"
        fill={palette.common.black}
      />
      <path
        d="m42 22.2 3-3 3 3M42 26.2l3-3 3 3"
        stroke={palette.common.white}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
);
