import { pxToRem } from 'components/theme/typography';

export default function Tab(theme) {
  const { palette } = theme;
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: pxToRem(8),
          minHeight: pxToRem(32),
          '& .MuiTab-root.MuiButtonBase-root': {
            minHeight: pxToRem(32),
          },
          '& .MuiTabs-flexContainer': {
            gap: theme.spacing(1),
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          color: palette.grey[600],
          fontFamily: 'Be Vietnam Pro',
          '&.Mui-selected': {
            color: palette.grey[800],
          },
        },
      },
    },
  };
}
