import {
  CURRENT_LP_REGION,
  LP_PHONE,
  LP_PROFILE,
} from 'constants/localStorage';
import { Regions } from 'types/lp.d';

export const getUserProperties = () => {
  const lpProfile = localStorage.getItem(LP_PROFILE);
  const lpPhone = localStorage.getItem(LP_PHONE);

  const isImpersonated = lpPhone?.includes('#');
  const realPhone = isImpersonated ? lpPhone?.split('#')[0] : '';

  const {
    id = '',
    name = '',
    attributes = {},
  } = JSON.parse(lpProfile as string) || {};
  const {
    country,
    emailAddress,
    fleetGroupRelationship,
    phoneNumber,
    regions,
  } = attributes;
  return {
    // id here is juicer profile token
    id,
    name,
    email: emailAddress,
    country,
    lpAccountType: fleetGroupRelationship,
    phone: phoneNumber,
    regions:
      regions && Array.isArray(regions) && regions.map((region) => region.id),
    isImpersonated,
    realPhone,
  };
};

// combine top level and nested parent region data
export const getParentRegionMap = (regions) => {
  const parentRegions = {};

  regions.forEach((region) => {
    const regionToken = region.id || region.regionToken;
    // only sub-regions will have populated parent_region field
    if (region.attributes?.parentRegion) {
      const parentRegion = region.attributes?.parentRegion;
      if (!(parentRegion.id in parentRegions)) {
        parentRegions[parentRegion.id] = {
          id: parentRegion.id,
          attributes: parentRegion,
        };
      }
      // assume is parent region if parent_region field is empty
    } else if (!(regionToken in parentRegions)) {
      parentRegions[regionToken] = region;
    }
  });

  return parentRegions;
};

// filter region response for whole set of parent regions (sub-regions not included)
export const getUserRegions = (regions) => {
  const parentRegionMap = getParentRegionMap(regions) as any[];
  return Object.values(parentRegionMap).map((r) => {
    const { name } = r.attributes;
    return {
      regionName: name,
      regionToken: r.id,
      attributes: r.attributes,
    };
  });
};

export const getUserDefaultRegion = (regions) => getUserRegions(regions)[0];

export const getCurrentRegion = () =>
  JSON.parse(localStorage.getItem(CURRENT_LP_REGION) as string);

export const getParentRegionTokens = (regions: Regions) => {
  const parentRegions = getParentRegionMap(regions) as any[];

  return Object.keys(parentRegions).map((key) => key);
};
