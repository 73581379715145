import { datadogRum } from '@datadog/browser-rum';

const datadogEnvironments = ['staging', 'production'];
const environment = process.env.NODE_ENV || 'development';

const getVersion = () =>
  environment === 'production'
    ? process.env.VERSION
    : `${environment}-${process.env.VERSION}`;

export const initDatadog = (): void => {
  if (
    datadogEnvironments.includes(environment) &&
    !window.location.host.includes('local-lp-dashboard-test.lime.bike:4001')
  ) {
    datadogRum.init({
      applicationId: 'f0ea7e32-5b2d-4106-8443-1f49c3452c21',
      clientToken: 'pub3ee4d4073016fb063b080745493209c2',
      site: 'datadoghq.com',
      service: 'lp-dashboard',
      env: environment,
      version: getVersion(),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
};
