import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import { pxToRem } from 'components/theme/typography';

const DialogTitleStyle = styled(DialogTitle)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(24),
    py: pxToRem(8),
    mb: pxToRem(24),
    borderBottom: `1px solid ${theme.palette.border.color}`,
  }),
);

const DialogContentStyle = styled(DialogContent)(({ theme }) =>
  theme.unstable_sx({
    p: pxToRem(24),
  }),
);

const DialogActionsStyle = styled(DialogActions)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(24),
    py: pxToRem(16),
    justifyContent: 'center',
  }),
);

const CloseIconStyle = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    p: pxToRem(8),
    position: 'absolute',
    right: 8,
    top: 1,
    color: theme.palette.grey[600],
    width: pxToRem(40),
    height: pxToRem(40),
  }),
);

type FilterDialogProps = {
  actions?: ReactNode;
  content: ReactNode;
  closable?: boolean;
  height?: number;
  maxHeight?: number;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  open: boolean;
  title?: string;
  style?: 'light' | 'dark';
  width?: number;
  onClose: () => void;
};

export const FilterDialog = (props: FilterDialogProps) => {
  const { palette } = useTheme();
  const {
    actions,
    content,
    closable,
    height,
    maxHeight,
    maxWidth,
    open,
    title,
    style,
    width,
    onClose,
  } = props;
  const dialogStyles = {
    '& .MuiDialog-paperScrollPaper': {
      backgroundColor:
        style === 'dark' ? palette.grey[900] : palette.common.white,
      color: style === 'dark' ? palette.common.white : palette.grey[800],
      height: height || 'inherit',
      width: width || 'inherit',
      maxHeight: maxHeight || 'inherit',
      maxWidth: 'inherit',
    },
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      sx={dialogStyles}
      scroll="paper"
    >
      {!!title && (
        <DialogTitleStyle>
          {title}
          {closable && (
            <CloseIconStyle aria-label="close" onClick={onClose}>
              <CloseIcon />
            </CloseIconStyle>
          )}
        </DialogTitleStyle>
      )}
      <DialogContentStyle>{content}</DialogContentStyle>
      {!!actions && <DialogActionsStyle>{actions}</DialogActionsStyle>}
    </Dialog>
  );
};

FilterDialog.defaultProps = {
  closable: false,
  style: 'light',
};
