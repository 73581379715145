import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import {
  DEFAULT_MIN_START_DATE,
  FUTURE_DATE_ERROR,
  INVALID_DATE_ERROR,
  MAX_DATE_ERROR,
  MIN_DATE_ERROR,
} from 'constants/filterOptions';
import { DATE_RANGE_ERRORS } from 'types/tasks.d';

const TitleStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    pb: pxToRem(8),
  }),
);

const getEndDateAfter = (
  date: Date | null,
  amount: number,
  timePeriod: moment.unitOfTime.Base,
  asMoment?: boolean,
) => {
  if (!date || !amount) {
    return undefined;
  }

  const momentDate = moment(date).add(amount, timePeriod);
  return asMoment ? momentDate : momentDate.toDate();
};

type CustomDateRangePickerProps = {
  disableFuture?: boolean;
  disablePast?: boolean;
  endDate: Date | null;
  endDateLabel?: string;
  endDateLimit?: {
    timeAmount: number;
    timePeriod: moment.unitOfTime.Base;
  };
  minDate?: Date;
  startDate: Date | null;
  startDateLabel?: string;
  title?: string;
  hideHelper?: boolean;
  onChange: (newDateRange: [Date, Date], rangeHasError: boolean) => void;
};

export const CustomDateRangePicker = ({
  disableFuture = false,
  disablePast = false,
  endDate,
  endDateLabel = 'End Date',
  endDateLimit = {
    timeAmount: 0,
    timePeriod: 'days',
  },
  minDate = new Date(DEFAULT_MIN_START_DATE),
  startDate,
  startDateLabel = 'Start Date',
  title,
  hideHelper = false,
  onChange,
}: CustomDateRangePickerProps) => {
  const { timeAmount, timePeriod } = endDateLimit;
  const [startErrorMsg, setStartErrorMsg] = useState<string>('');
  const [endErrorMsg, setEndErrorMsg] = useState<string>('');

  const handleStartChange = (newStartMoment: Moment) => {
    const endDateRange = endDate || new Date();
    onChange(
      [newStartMoment.startOf('day').toDate(), endDateRange],
      !!startErrorMsg || !!endErrorMsg,
    );
  };

  const handleEndChange = (newEndMoment: Moment) => {
    const startDateRange = startDate || newEndMoment.toDate();
    onChange(
      [startDateRange, newEndMoment.endOf('day').toDate()],
      !!startErrorMsg || !!endErrorMsg,
    );
  };

  const getErrorMapForType = (
    type: 'Start' | 'End',
  ): Record<keyof DateValidationError, string> => ({
    [DATE_RANGE_ERRORS.disableFuture]: `${type} ${FUTURE_DATE_ERROR}`,
    [DATE_RANGE_ERRORS.invalidDate]: `${type} ${INVALID_DATE_ERROR}`,
    [DATE_RANGE_ERRORS.minDate]: `${type} ${MIN_DATE_ERROR}`,
    [DATE_RANGE_ERRORS.maxDate]: `${type} ${MAX_DATE_ERROR}`,
  });

  const handleStartError = (
    reason: DateValidationError,
    startMoment: Moment,
  ) => {
    const errorMessageMap = getErrorMapForType('Start');
    const errorMessage = (reason && errorMessageMap[reason]) || '';
    setStartErrorMsg(errorMessage);
    onChange(
      [startMoment.toDate(), endDate || new Date()],
      !!errorMessage || !!endErrorMsg,
    );
  };

  const handleEndError = (reason: DateValidationError, endMoment: Moment) => {
    const errorMessageMap = getErrorMapForType('End');
    const errorMessage = (reason && errorMessageMap[reason]) || '';
    setEndErrorMsg(
      errorMessage.replace(
        '{DATE}',
        getEndDateAfter(startDate, timeAmount, timePeriod),
      ),
    );
    onChange(
      [startDate || new Date(), endMoment.toDate()],
      !!startErrorMsg || !!errorMessage,
    );
  };

  const sharedProps = {
    disableFuture,
    disablePast,
    maxDate: getEndDateAfter(startDate, timeAmount, timePeriod, true) as Moment, // cast due to indeterminate types in return type,
    minDate: moment(minDate),
    slotProps: {
      textField: {
        helperText: hideHelper ? undefined : 'yyy/mm/dd',
      },
    },
  };

  return (
    <>
      {!!title && <TitleStyle variant="body1">{title}</TitleStyle>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box display="flex" flexDirection="row" gap={2}>
          <Tooltip
            arrow
            placement="top"
            open={!!startErrorMsg}
            title={startErrorMsg}
          >
            <DatePicker
              {...sharedProps}
              label={startDateLabel}
              onChange={handleStartChange}
              onError={handleStartError}
              value={moment(startDate)}
            />
          </Tooltip>
          <Tooltip
            arrow
            placement="top"
            open={!!endErrorMsg}
            title={endErrorMsg}
          >
            <DatePicker
              {...sharedProps}
              label={endDateLabel}
              onChange={handleEndChange}
              onError={handleEndError}
              value={moment(endDate)}
            />
          </Tooltip>
        </Box>
      </LocalizationProvider>
    </>
  );
};
