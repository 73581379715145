import React from 'react';
import { defaultColor } from 'constants/svg';

export const Bus = ({ color = defaultColor }) => (
  <>
    <path
      d="M17.5 19h-11a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2ZM7.5 16h1M15.5 16h1M4.5 13h15"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 11V8a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v3M8.236 19v1.5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1.956M18.764 18.544V20.5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V19"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Car = ({ color = defaultColor }) => (
  <>
    <path
      d="m22 9-2.62 1M4.63 10 2 9M18.25 14h-2.5M8.25 14h-2.5M19.379 10l-.839-4.012A2.501 2.501 0 0 0 16.092 4h-8.08a2.5 2.5 0 0 0-2.434 1.931L4.627 10"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.25 20H5.5A1.5 1.5 0 0 0 7 18.5v-1h10v1a1.5 1.5 0 0 0 1.5 1.5h2.25c.69 0 1.25-.56 1.25-1.25V13a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v5.75c0 .69.56 1.25 1.25 1.25Z"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Ebike = ({ color = defaultColor }) => (
  <>
    <path
      d="M13.466 3h-3.3L5.033 16.2M21.533 8.867h-2.567l-1.1 2.933M9.8 10.333l4.576 5.867H18.6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={5.4} cy={16.2} r={4.4} stroke={color} strokeWidth={2} />
    <circle cx={18.6} cy={16.2} r={4.4} stroke={color} strokeWidth={2} />
  </>
);

export const Moped = ({ color = defaultColor }) => (
  <>
    <path
      d="M9.912 6.321s-3.279 9.017 1.654 9.017c4.666 0 4.745-2.545 3.89-3.4h5.21c1.035.788 4.849 5.372 2.193 5.372-4.521 0-8.932-.005-13.222-.005-1.095.048-1.225-.843-1.225-.843-.333-1.225-1.604-3.415-3.267-3.415-1.541 0 1.922-5.445 3.105-6.726h1.662Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09 6.174c.04-.045.099-.07.16-.07h1.662a.218.218 0 0 1 .205.292l-.205-.075.205.075v.001l-.002.005-.007.018a22.285 22.285 0 0 0-.421 1.367c-.229.84-.481 1.953-.58 3.062-.101 1.116-.045 2.197.318 2.99.179.392.43.708.772.928.34.22.786.353 1.37.353 2.303 0 3.397-.629 3.833-1.282.436-.653.259-1.388-.098-1.746a.218.218 0 0 1 .154-.372h5.21c.047 0 .094.016.132.045.278.212.722.66 1.186 1.204.467.546.965 1.203 1.349 1.838.192.318.357.634.474.93.117.295.193.584.193.844 0 .265-.079.516-.288.695-.203.175-.494.251-.853.251-2.26 0-4.494 0-6.698-.002h-.092l-6.427-.002c-.616.026-.988-.216-1.2-.488a1.292 1.292 0 0 1-.242-.523v-.003c-.161-.583-.547-1.404-1.092-2.077-.552-.682-1.23-1.168-1.963-1.168a.62.62 0 0 1-.354-.099.547.547 0 0 1-.21-.286c-.07-.211-.044-.48.02-.753.134-.56.471-1.301.883-2.065.826-1.53 1.997-3.228 2.606-3.887Zm.51 10.153 1.105.76 6.37.003h.086l6.698.002c.306 0 .477-.067.57-.146.085-.074.136-.187.136-.365 0-.182-.055-.412-.162-.683a6.024 6.024 0 0 0-.443-.866c-.368-.609-.85-1.246-1.307-1.78-.425-.498-.817-.895-1.063-1.096h-4.708a1.87 1.87 0 0 1-.12 1.924c-.556.832-1.835 1.476-4.196 1.476-.65 0-1.182-.15-1.605-.422-.424-.274-.725-.661-.932-1.114-.41-.898-.46-2.072-.357-3.21.103-1.145.363-2.285.595-3.137a22.718 22.718 0 0 1 .34-1.134h-1.26c-.593.67-1.692 2.27-2.48 3.729-.408.758-.722 1.457-.841 1.959-.062.256-.062.423-.032.514.013.04.029.056.042.064.014.01.045.024.11.024.929 0 1.718.61 2.3 1.329A6.645 6.645 0 0 1 8.6 16.327Z"
      fill={color}
    />
    <circle cx={3.75} cy={17.25} r={2.75} stroke={color} strokeWidth={1.8} />
    <circle cx={18.75} cy={17.25} r={2.75} stroke={color} strokeWidth={1.8} />
    <path
      d="M15 11.2h6.5M4 17l6.218-13h2.328"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Scooter = ({ color = defaultColor }) => (
  <>
    <circle cx={4.426} cy={18.197} r={1.804} stroke={color} strokeWidth={2} />
    <circle cx={19.576} cy={18.197} r={1.804} stroke={color} strokeWidth={2} />
    <path
      d="M17.736 17.8H10L6 13M6.5 10.5l2.218-7.85h4.329"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ScooterSeated = ({ color = defaultColor }) => (
  <>
    <circle cx={4.803} cy={18.547} r={1.804} stroke={color} strokeWidth={2} />
    <circle cx={19.953} cy={18.547} r={1.804} stroke={color} strokeWidth={2} />
    <path
      d="M18.113 18.15h-7.735l-4-4.8"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.378 11.35h-3.58l-1.42 6M6.878 10.85 9.096 3h4.328"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Train = ({ color = defaultColor }) => (
  <>
    <path
      d="M19 14.429V9a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v5.429c0 2.153 1.154 3.141 3.023 4.209l1 .571a6 6 0 0 0 5.954 0l1-.571C17.846 17.57 19 16.582 19 14.429v0ZM9 2h6M10.5 5V2M13.5 5V2M5 11h14M8.02 14.43l1 .57M15.98 14.43l-1 .57M18 21l-1.7-2.55M6 21l1.7-2.55"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Truck = ({ color = defaultColor }) => (
  <>
    <path
      d="M18.704 18.296a1.583 1.583 0 1 1-2.24 2.24 1.583 1.583 0 1 1 2.24-2.24ZM7.704 18.296a1.583 1.583 0 1 1-2.238 2.24 1.583 1.583 0 0 1 2.238-2.24ZM10 4h4a1 1 0 0 1 1 1v10H2"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 19.416H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h6.5M15 7h4.323c.409 0 .777.249.928.629l1.606 4.014c.094.236.143.488.143.742v5.948a1 1 0 0 1-1 1h-1.831M16 19.42H8.17"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 14h-4v-4h3.2"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Walk = ({ color = defaultColor }) => (
  <>
    <path
      d="M9.911 7.345h2.09l1.627-.992v0a1 1 0 0 1 1.47.538L17.4 13.8l3.24 4.32v0A1.8 1.8 0 0 1 19.2 21h-3.93v0a3.994 3.994 0 0 1-2.929-1.276l-8.54-9.193h0a3 3 0 0 1 .246-4.317L7.47 3.281v0a1.159 1.159 0 0 1 1.913.88v3.184H12M3 17.5h2M8 21H3"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.547 18h-5.024v0a2.982 2.982 0 0 1-2.194-.954L3.655 6.628M13 13l3.91-.68M12 10.5l4.06-.7"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
