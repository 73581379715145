import { sortBy, uniq, pick } from 'lodash';
import { FILTERS_MAP } from 'constants/filterOptions';
import { MOVE_TASK, REBALANCE_TASK } from 'constants/tasks';
import { DEFAULT_MAP_ZOOM } from 'constants/maps';
import { setupFilterSelect, setupFilterSelectv2 } from 'lib/tasks';
import { SelectFilterType } from 'types/filtering.d';
import actionTypes from '../actions/actionTypes';

const formatFilters = ({ assigneeName, taskType }) => {
  const newAssigneeNames = uniq(assigneeName.map((assignee) => assignee));
  const assigneeOptions: SelectFilterType[] =
    setupFilterSelect(newAssigneeNames);
  const priorityOptions: SelectFilterType[] = setupFilterSelectv2(
    FILTERS_MAP.priorityType,
  );
  const taskOptions: SelectFilterType[] = setupFilterSelectv2(
    pick(
      FILTERS_MAP.taskType,
      taskType.map((task) => task.replace(MOVE_TASK, REBALANCE_TASK)),
    ),
  );
  const vehicleOptions: SelectFilterType[] = setupFilterSelectv2(
    FILTERS_MAP.vehicleType,
  );

  const newAssigneesList = sortBy(
    assigneeOptions,
    (assignee) => assignee.label,
  ).map((assignee, index) => ({
    id: index + 1,
    ...assignee,
  }));

  return {
    assigneeName: newAssigneesList,
    priority: priorityOptions,
    taskType: taskOptions,
    vehicleType: vehicleOptions,
  };
};

const { taskType, vehicleType } = FILTERS_MAP;

const INITIAL_STATE = {
  dataDownloading: false,
  myTasks: {
    data: {},
    filters: {
      plateNumber: '',
      vehicleType: Object.keys(FILTERS_MAP.vehicleType).map((key) => key),
      taskType: Object.keys(FILTERS_MAP.taskType).map((key) => key),
      priority: '',
      assigneeName: '',
      batteryLevelRange: [0, 100],
      regionToken: '',
      regionName: '',
      completedAtStartTime: '',
      completedAtEndTime: '',
    },
    loading: false,
    operatorFilters: {
      assigneeName: [],
      priority: [],
      taskType: [],
      vehicleType: [],
    },
    status: 'active',
    tablePageNumber: 0,
    tablePageSize: 50,
    totalTasks: undefined,
  },
  mapData: {
    data: [],
    filteredData: [],
    filters: {
      plateNumber: '',
      vehicleType: [
        {
          label: vehicleType.scooter,
          value: 'scooter',
          checked: true,
        },
        {
          label: vehicleType.electric,
          value: 'electric',
          checked: true,
        },
      ],
      taskType: Object.keys(taskType).map((key) => {
        const label = taskType[key];
        return {
          label,
          value: key,
          checked: true,
        };
      }),
      batteryLevelMin: 0,
      batteryLevelMax: 100,
      mapOptions: [
        {
          label: 'Show Prices',
          value: 'showPrices',
          checked: false,
        },
      ],
    },
    loading: false,
    regionCenter: [],
    regionBounds: [],
    mapZoom: DEFAULT_MAP_ZOOM,
  },
};

const tasksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.TASKS_UNSET_TOTAL_TASKS:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          totalTasks: action.payload,
        },
      };
    case actionTypes.TASKS_UPDATE_DATA: {
      const { tasksData, totalTasks } = action.payload;
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          data: {
            ...tasksData,
          },
          totalTasks,
        },
      };
    }
    case actionTypes.TASKS_UPDATE_DOWNLOADING_STATUS:
      return {
        ...state,
        dataDownloading: action.payload,
      };
    case actionTypes.TASKS_UPDATE_FILTER_OPTIONS:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          operatorFilters: {
            ...formatFilters(action.payload),
          },
        },
      };
    case actionTypes.TASKS_UPDATE_FILTERS:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          filters: action.payload,
        },
      };
    case actionTypes.TASKS_UPDATE_LOADING_STATUS:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          loading: action.payload,
        },
      };
    case actionTypes.TASKS_UPDATE_PAGE_NUMBER:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          tablePageNumber: action.payload,
        },
      };
    case actionTypes.TASKS_UPDATE_PAGE_SIZE:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          tablePageSize: action.payload,
        },
      };
    case actionTypes.TASKS_UPDATE_STATUS:
      return {
        ...state,
        myTasks: {
          ...state.myTasks,
          status: action.payload,
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_DATA:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          data: action.payload,
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_FILTERED_DATA:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          filteredData: action.payload,
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_FILTERS:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          filters: action.payload,
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_MAP_CENTER:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          regionCenter: [action.payload.lng, action.payload.lat],
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_MAP_BOUNDS:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          regionBounds: action.payload,
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_LOADING_STATUS:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          loading: action.payload,
        },
      };
    case actionTypes.TASKS_MAP_UPDATE_ZOOM_LEVEL:
      return {
        ...state,
        mapData: {
          ...state.mapData,
          mapZoom: action.payload,
        },
      };
    default:
      return state;
  }
};

export default tasksReducer;
