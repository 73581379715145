import {
  AssetsState,
  AssetSummaryParsed,
  BatteryPossessionsProcessed,
  BatteryInventoryParsed,
  SmartCartsListResponse,
  TimelineApiResponse,
  BatteryDetailsApiResponse,
} from 'types/assets.d';
import { ApiError } from 'types/global.d';
import { partition } from 'lodash';
import { SmartCartDetails } from 'types/smartCarts.d';
import { ASSETS_BATTERY_DETAIL } from 'constants/amplitude';
import { AppState } from '../types';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE: AppState['assets'] = {
  battery: {
    details: { data: {}, isLoading: true },
    timeline: { data: [], isLoading: true },
  },
  batteryPossessions: {
    data: [],
    isLoading: true,
  },
  batteryInventory: {
    data: { batteries: [] },
    isLoading: true,
  },
  smartCarts: {
    data: [],
    details: {},
    meta: { totalRecords: 0, done: null },
    isLoading: true,
  },
  summary: {
    data: {
      batteries: {
        total: 0,
        swappable: 0,
        notSwappable: 0,
        atRisk: 0,
        decayed: 0,
        needsRepair: 0,
        inSmartCarts: 0,
      },
      smartCarts: {
        total: 0,
        onlineWifi: 0,
        onlineCellular: 0,
        offline: 0,
      },
    },
    isLoading: true,
  },
};
// need to use actual strings due to how actionTypes is setup :(
// TODO: fix actionTypes to be a typed array so we can avoid all the cases in this file
type AssetsAction =
  | {
      type: 'ASSETS_ERROR_SUMMARY';
      payload: ApiError;
    }
  | {
      type: 'ASSETS_ERROR_BATTERY_POSSESSIONS';
      payload: ApiError;
    }
  | {
      type: 'ASSETS_ERROR_BATTERY_INVENTORY';
      payload: ApiError;
    }
  | {
      type: 'ASSETS_UPDATE_SUMMARY';
      payload: AssetSummaryParsed;
    }
  | {
      type: 'ASSETS_UPDATE_BATTERY_POSSESSIONS';
      payload: BatteryPossessionsProcessed;
    }
  | {
      type: 'ASSETS_UPDATE_BATTERY_INVENTORY';
      payload: BatteryInventoryParsed;
    }
  | {
      type: 'ASSETS_BATTERY_DETAILS';
      payload: BatteryDetailsApiResponse;
    }
  | {
      type: 'ASSETS_BATTERY_TIMELINE';
      payload: TimelineApiResponse;
    }
  | {
      type: 'ASSETS_CLEAR_BATTERY_INVENTORY';
      payload: never;
    }
  | {
      type: 'ASSETS_SMART_CARTS_LIST';
      payload: SmartCartsListResponse;
    }
  | {
      type: 'UPDATE_IS_SMART_CARTS_LIST_LOADING';
      payload: boolean;
    }
  | {
      type: 'UPDATE_IS_ASSET_SUMMARY_PAGE_LOADING';
      payload: boolean;
    }
  | {
      type: 'UPDATE_IS_BATTERY_INVENTORY_LOADING';
      payload: boolean;
    }
  | {
      type: 'UPDATE_IS_BATTERY_POSSESSIONS_LOADING';
      payload: boolean;
    }
  | {
      type: 'UPDATE_IS_BATTERY_DETAILS_LOADING';
      payload: boolean;
    }
  | {
      type: 'UPDATE_IS_BATTERY_TIMELINE_LOADING';
      payload: boolean;
    }
  | {
      type: 'ASSETS_UPDATE_SMART_CART_DETAIL';
      payload: SmartCartDetails;
    };

const assetsReducer = (
  state = INITIAL_STATE,
  action: AssetsAction,
): AssetsState => {
  switch (action.type) {
    case actionTypes.ASSETS_BATTERY_TIMELINE:
      return {
        ...state,
        battery: {
          details: {
            data: {
              ...state.battery.details.data,
            },
            isLoading: state.battery.details.isLoading,
          },
          timeline: {
            data: (action.payload as TimelineApiResponse).data,
            isLoading: false,
          },
        },
      };
    case actionTypes.ASSETS_BATTERY_DETAILS:
      return {
        ...state,
        battery: {
          details: {
            data: {
              ...(action.payload as BatteryDetailsApiResponse).data,
            },
            isLoading: false,
          },
          timeline: {
            data: [...state.battery.timeline.data],
            isLoading: state.battery.timeline.isLoading,
          },
        },
      };
    case actionTypes.ASSETS_UPDATE_SMART_CART_DETAIL: {
      // formatting the response from the BE because it's a mix of smart cart slot objects,
      // followed by a battery object if a battery is plugged into the slot (meaning 2 seperate objects).
      // each single object should really only be representing a slot and that same object
      // should contain the data of the battery plugged into it
      const { included } = action.payload as SmartCartDetails;
      const [includedSmartCartSlots, includedBatteries] = partition(
        included,
        (obj) => obj.type === 'charging_cart_slots',
      );
      const updatedIncludedResponse = includedSmartCartSlots
        // filter out empty slots
        .filter((slot) => Boolean(slot.relationships.battery.data?.id))
        .map((slot) => {
          if (slot.relationships?.battery.data?.id) {
            return {
              ...slot,
              linkRowTo: `${ASSETS_BATTERY_DETAIL}`.replace(
                ':id',
                slot.relationships.battery.data.id,
              ),
              relationships: {
                battery: {
                  ...includedBatteries.find(
                    (battery) =>
                      battery.id === slot.relationships.battery.data?.id,
                  ),
                },
              },
            };
          }
          return slot;
        });

      return {
        ...state,
        smartCarts: {
          ...state.smartCarts,
          details: {
            data: {
              ...(action.payload as SmartCartDetails)?.data,
            },
            included: updatedIncludedResponse,
          },
        },
      };
    }
    case actionTypes.ASSETS_UPDATE_SUMMARY:
      return {
        ...state,
        summary: {
          data: {
            ...(action.payload as AssetSummaryParsed),
          },
          isLoading: false,
        },
      };
    case actionTypes.UPDATE_IS_BATTERY_DETAILS_LOADING:
      return {
        ...state,
        battery: {
          ...state.battery,
          details: {
            data: {
              ...state.battery.details.data,
            },
            isLoading: action.payload,
          },
        },
      };
    case actionTypes.UPDATE_IS_BATTERY_TIMELINE_LOADING:
      return {
        ...state,
        battery: {
          ...state.battery,
          timeline: {
            data: [...state.battery.timeline.data],
            isLoading: action.payload as boolean,
          },
        },
      };
    case actionTypes.UPDATE_IS_SMART_CARTS_LIST_LOADING:
      return {
        ...state,
        smartCarts: {
          ...state.smartCarts,
          isLoading: action.payload as boolean,
        },
      };
    case actionTypes.UPDATE_IS_ASSET_SUMMARY_PAGE_LOADING:
      return {
        ...state,
        summary: {
          ...state.summary,
          isLoading: action.payload as boolean,
        },
      };
    case actionTypes.UPDATE_IS_BATTERY_INVENTORY_LOADING:
      return {
        ...state,
        batteryInventory: {
          ...state.batteryInventory,
          isLoading: action.payload as boolean,
        },
      };
    case actionTypes.UPDATE_IS_BATTERY_POSSESSIONS_LOADING:
      return {
        ...state,
        batteryPossessions: {
          ...state.batteryPossessions,
          isLoading: action.payload as boolean,
        },
      };
    case actionTypes.ASSETS_ERROR_SUMMARY:
      return {
        ...state,
        summary: {
          data: {
            ...state.summary.data,
          },
          error: action.payload as ApiError,
          isLoading: false,
        },
      };
    case actionTypes.ASSETS_UPDATE_BATTERY_POSSESSIONS:
      return {
        ...state,
        batteryPossessions: {
          ...state.batteryPossessions,
          isLoading: false,
          data: action.payload as BatteryPossessionsProcessed,
        },
      };
    case actionTypes.ASSETS_ERROR_BATTERY_POSSESSIONS:
      return {
        ...state,
        batteryPossessions: {
          ...state.batteryPossessions,
          isLoading: false,
          error: action.payload as ApiError,
        },
      };

    case actionTypes.ASSETS_UPDATE_BATTERY_INVENTORY:
      return {
        ...state,
        batteryInventory: {
          data: action.payload as BatteryInventoryParsed,
          isLoading: false,
        },
      };
    case actionTypes.ASSETS_ERROR_BATTERY_INVENTORY:
      return {
        ...state,
        batteryInventory: {
          data: {
            batteries: [],
          },
          isLoading: true,
          error: action.payload as ApiError,
        },
      };
    case actionTypes.ASSETS_CLEAR_BATTERY_INVENTORY:
      return {
        ...state,
        batteryInventory: {
          data: {
            batteries: [],
          },
          isLoading: true,
          error: undefined,
        },
      };
    case actionTypes.ASSETS_SMART_CARTS_LIST:
      return {
        ...state,
        smartCarts: {
          ...state.smartCarts,
          data: (action.payload as SmartCartsListResponse).data,
          meta: (action.payload as SmartCartsListResponse).meta,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default assetsReducer;
