import { ReactNode } from 'react';

/**
 * Enum representing sort direction
 *
 * @property {string} Asc='asc' - Ascending order
 * @property {string} Desc='desc' - Descending order
 *
 */

export const enum ORDER_VALUES {
  Asc = 'asc',
  Desc = 'desc',
}
export type Order = ORDER_VALUES;

export type Row = {
  id: string;
  isTall?: boolean;
  [key: string]: React.ReactText | ReactNode | Row[] | Row;
};

export type Column = {
  addAvatar?: boolean;
  addIcon?: boolean;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  content?: (value) => ReactNode;
  id: string;
  initialOrder?: Order;
  label: string;
  minWidth?: number | string;
  numeric?: boolean;
  sortable?: boolean;
  width?: number | string;
};

export type FilterConfigColumn = string | string[];
export type FilterConfigFilterBySingleValue = string;
export type FilterConfigFilterByMultipleValues = string[];
export type FilterConfigFilterByMultipleColumns = string[][];
export type FilterConfigFilterByMultipleMinMax = [
  number | null,
  number | null,
][];

export type FilterConfig = {
  column: FilterConfigColumn;
  customFilter?: (
    row: Row,
    filterBy:
      | FilterConfigFilterBySingleValue
      | FilterConfigFilterByMultipleValues
      | FilterConfigFilterByMultipleColumns
      | FilterConfigFilterByMultipleMinMax,
  ) => boolean;
};

export type OptionalFilterConfigFilterBys = {
  filterBySingleValue?: FilterConfigFilterBySingleValue;
  filterByMultipleValues?: FilterConfigFilterByMultipleValues;
  filterByMultipleColumns?: FilterConfigFilterByMultipleColumns;
  filterByMultipleMinMax?: FilterConfigFilterByMultipleMinMax;
};
export type FilterByKeys =
  | 'filterBySingleValue'
  | 'filterByMultipleValues'
  | 'filterByMultipleColumns'
  | 'filterByMultipleMinMax';
/**
 * Pass in a collection as the first arg, and the key that should be required as the second arg
 */
type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type CustomFilterConfig = {
  column: FilterConfigColumn;
  customFilter: (
    row: Row,
    filterBy:
      | FilterConfigFilterBySingleValue
      | FilterConfigFilterByMultipleValues
      | FilterConfigFilterByMultipleColumns
      | FilterConfigFilterByMultipleMinMax,
  ) => boolean;
} & RequireOnlyOne<OptionalFilterConfigFilterBys, FilterByKeys>;

export type RequiredFilterConfig = FilterConfig &
  RequireOnlyOne<OptionalFilterConfigFilterBys, FilterByKeys>;

export type FilterOnChange<T> = {
  currentState: Record<string, FilterConfig & OptionalFilterConfigFilterBys>;
  filterByKey: string;
  filterByValue: T;
};

/**
 * Object that tell useDataGrid what columns and value(s) to filter by
 * @example
 * const filterConfigList: FilterConfigList = [
 *     // filtering the 'name' column by a single value
 *    { column: 'name', filterBySingleValue: 'Bob' },
 *    // filtering the 'vehicles' column by multiple values
 *    { column: 'vehicles', filterByMultipleValues: ['bike', 'scooter'] }
 * ]
 */
export type FilterConfigList = RequiredFilterConfig[] | CustomFilterConfig[];

/**
 * @param {Row[]} originalRows - Original Rows being used in DataGrid
 * @param {string} [orderBy] - Column to order by
 * @param {ORDER_VALUES} [order] - Sort Order
 * @returns {Row[]} - The sorted rows
 */
type CustomSort = (
  originalRows: Row[],
  orderBy?: string,
  order?: Order,
) => Row[];

export type UseDataGridHookResult = {
  pagination: {
    onPageChange: React.Dispatch<React.SetStateAction<number>>;
    onRowsPerPageChange: (value: number) => void;
    pageNumber: number;
    rowsPerPage: number;
  };
  sorting: {
    onSort: (nextSortKey: string, nextSortDir: Order) => void;
    sortBy: string;
    sortDirection: ORDER_VALUES;
  };
  results: {
    totalCount: number;
    visibleRows: Row[];
    allSortedAndFilteredRows: Row[];
  };
};
/**
 * @type UseDataGridProps
 */
export type UseDataGridProps = {
  rows: Row[];
  initialSortBy: string;
  /**
   * @default ASC
   */
  initialSortDirection?: ORDER_VALUES;
  filterConfig?: FilterConfigList;
  customSort?: CustomSort;
};

export type HeadCell = Column;
