import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

const DateTimeContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: 0,
    width: 'auto',
    height: { xs: pxToRem(44), md: 'unset' },
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'flex-start',
      lg: 'flex-end',
    },
    backgroundColor: 'transparent',
  }),
);

const DateTimeStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.grey[800],
  }),
);

const DATETIME_FORMAT = 'ddd MMM D, hh:mm a';

type TimezoneCurrentDateTimeProps = {
  timezone: string;
};

const TimezoneCurrentDateTime = (props: TimezoneCurrentDateTimeProps) => {
  const { timezone } = props;
  const [currentDateTime, setCurrentDateTime] = useState('');
  const timer: any = useRef(null);

  const tick = () => {
    const newDateTime = moment().tz(timezone).format(DATETIME_FORMAT);

    setCurrentDateTime(newDateTime);
  };

  useEffect(() => {
    // Start interval on component mounting.
    timer.current = setInterval(tick, 1000); // useRef value stored in 'current' property

    // Clear Interval on component unmounting.
    return () => {
      clearInterval(timer.current);
    };
  });

  return (
    <DateTimeContainerStyle>
      <DateTimeStyle variant="subtitle2">{currentDateTime}</DateTimeStyle>
    </DateTimeContainerStyle>
  );
};

const mapStateToProps = ({ session }) => {
  const { timezone } = session;

  return {
    timezone,
  };
};

export default connect(mapStateToProps)(TimezoneCurrentDateTime);
