export enum VehicleTypes {
  Scooter = 'scooter',
  Ebike = 'electric',
}

export enum TaskTypes {
  SwapTask = 'swap_task',
  ChargeTask = 'charge_task',
  RetrieveTask = 'retrieval_task',
  MoveTask = 'move_task',
  DeployTask = 'deploy_task',
  ServiceTask = 'onsite_repair_task',
}

// API response format
export const DateKeyName = 'localDate';
export const DateShortKeyName = 'localDateShort';
export const VehicleTypeKeyName = 'vehicleType';
export const TaskTypeKeyName = 'taskType';
export const VolumeKeyName = 'gtValue';
export const ForecastingValueKeyName = 'forecastedValue';
export const ForecastingUpperBoundKeyName = 'forecastedValueUpperBound';
export const ForecastingLowerBoundKeyName = 'forecastedValueLowerBound';

export type VehicleDailyTaskVolume = {
  [DateKeyName]: string;
  [VehicleTypeKeyName]: VehicleTypes;
  [TaskTypeKeyName]: TaskTypes;
  [VolumeKeyName]: number | null;
  [ForecastingValueKeyName]: number | null;
  [ForecastingLowerBoundKeyName]: number | null;
  [ForecastingUpperBoundKeyName]: number | null;
};

export type VehilcesDailyTaskVolumes = VehicleDailyTaskVolume[];
export type DailyEntireTaskVolumes = VehilcesDailyTaskVolumes[];

export type RawResponse = {
  [key: string]: VehicleDailyTaskVolume[];
};
