import React from 'react';
import { defaultNavbarColor } from 'constants/svg';

export const Analytics = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      d="M20.33 2a1.833 1.833 0 1 1-.872 3.446l-3.025 3.48a1.833 1.833 0 1 1-3.454.945l-2.203-.759a1.83 1.83 0 0 1-2.315.432l-2.876 2.413a1.833 1.833 0 0 1-1.75 2.382 1.833 1.833 0 1 1 .91-3.426l2.87-2.408a1.833 1.833 0 1 1 3.577-.668l2.215.764a1.83 1.83 0 0 1 2.011-.551l3.17-3.646A1.833 1.833 0 0 1 20.33 2Z"
      fill={color}
    />
    <path
      d="M21.25 10.609c.506 0 .917.41.917.916V22.28a.5.5 0 0 1-.5.5H19a.5.5 0 0 1-.5-.5V11.525c0-.506.41-.916.917-.916h1.833Zm-5.5 6.425c.506 0 .917.41.917.917v4.328a.5.5 0 0 1-.5.5H13.5a.5.5 0 0 1-.5-.5v-4.328c0-.507.41-.917.917-.917h1.833Zm-5.5-1.84c.506 0 .917.41.917.916v6.17a.5.5 0 0 1-.5.5H8a.5.5 0 0 1-.5-.5v-6.17c0-.506.41-.916.917-.916h1.833Zm-5.5 4.713c.506 0 .917.41.917.917v1.455a.5.5 0 0 1-.5.5H2.5a.5.5 0 0 1-.5-.5v-1.455c0-.507.41-.917.917-.917H4.75Z"
      fill={color}
    />
  </>
);

export const Authenticator = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      d="M7.609 3.935c.924-1.182 2.409-1.916 3.913-1.893 1.504-.023 2.986.71 3.91 1.889a4.953 4.953 0 0 1 1.069 3.309c-.622-.14-1.254-.231-1.883-.334-.018-.96-.538-1.89-1.328-2.43a3.08 3.08 0 0 0-2.602-.443c-1.268.339-2.243 1.556-2.261 2.875-.63.1-1.261.19-1.883.333A4.95 4.95 0 0 1 7.61 3.935Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.001 9.616v-.733A23.068 23.068 0 0 0 4.056 8.88v1.03c0 .46 0 .918.002 1.376.077 1.712.511 3.411 1.293 4.938 1.287 2.569 3.525 4.634 6.172 5.744a12.304 12.304 0 0 0 4.785-3.598c1.693-2.084 2.665-4.746 2.685-7.433.012-.44.01-.88.01-1.32Zm-8.26 4.069c-.671-.305-1.129-1.034-1.068-1.776.008-.955.89-1.798 1.846-1.76.99-.044 1.898.86 1.852 1.85.03.712-.426 1.393-1.068 1.685.633.222 1.18.688 1.462 1.3.215.42.243.902.252 1.366h-4.99c-.007-.617.101-1.26.487-1.762.297-.424.743-.727 1.227-.903Z"
      fill={color}
    />
  </>
);

export const Booking = ({ color = defaultNavbarColor }) => (
  <>
    <path
      d="M16.813 2.833h-1.146v-.916a.916.916 0 1 0-1.834 0v.916H6.5v-.916a.916.916 0 1 0-1.833 0v.916H3.52C2.13 2.833 1 3.963 1 5.354v10.313a2.753 2.753 0 0 0 2.75 2.75h4.583a.916.916 0 1 0 0-1.834H3.75a.918.918 0 0 1-.917-.916V8.333H17.5a.916.916 0 1 0 1.833 0V5.354c0-1.39-1.13-2.52-2.52-2.52Z"
      fill={color}
    />
    <path
      opacity={0.48}
      d="M17.041 11.083a5.965 5.965 0 0 0-5.958 5.959A5.965 5.965 0 0 0 17.041 23 5.965 5.965 0 0 0 23 17.042a5.965 5.965 0 0 0-5.959-5.959Zm2.756 4.955-2.98 3.437a.921.921 0 0 1-.66.317h-.032a.916.916 0 0 1-.648-.269l-1.605-1.604a.916.916 0 1 1 1.297-1.296l.908.908 2.334-2.693a.917.917 0 0 1 1.386 1.2Z"
      fill={color}
    />
  </>
);

export const Calendar = ({ color = defaultNavbarColor }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.6 4.65h16.8c1.16.001 2.099.94 2.1 2.1V19.7a2.102 2.102 0 0 1-2.1 2.1H3.6a2.102 2.102 0 0 1-2.1-2.1V6.75a2.102 2.102 0 0 1 2.1-2.1Zm0 16.45h16.8a1.4 1.4 0 0 0 1.4-1.4V9.2H2.2v10.5a1.4 1.4 0 0 0 1.4 1.4Z"
      fill={color}
    />
    <path
      d="M17.95 3.95V2.2a.7.7 0 1 0-1.4 0v1.75h1.4ZM7.45 3.95V2.2a.7.7 0 1 0-1.4 0v1.75h1.4Z"
      fill={color}
    />
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.299 11.941a1 1 0 0 1 1-1h2.2a1 1 0 0 1 1 1v1.6a1 1 0 0 1-1 1h-2.2a1 1 0 0 1-1-1v-1.6Zm0 5a1 1 0 0 1 1-1h2.2a1 1 0 0 1 1 1v1.6a1 1 0 0 1-1 1h-2.2a1 1 0 0 1-1-1v-1.6Zm6.601-1a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-1.6a1 1 0 0 0-1-1h-2.2Zm-1-4a1 1 0 0 1 1-1h2.2a1 1 0 0 1 1 1v1.6a1 1 0 0 1-1 1h-2.2a1 1 0 0 1-1-1v-1.6Zm6.602-1a1 1 0 0 0-1 1v1.6a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-1.6a1 1 0 0 0-1-1h-2.2Z"
      fill={color}
    />
  </>
);

export const Charts = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      d="M14.905 10.886h6.352a.79.79 0 0 0 .79-.79 7.143 7.143 0 0 0-7.142-7.144.79.79 0 0 0-.79.79v6.353c0 .437.353.79.79.79Z"
      fill={color}
    />
    <path
      d="M21.171 12.476h-7.762a.866.866 0 0 1-.867-.867V3.83a.876.876 0 0 0-.971-.877A9.524 9.524 0 1 0 22.047 13.43a.877.877 0 0 0-.876-.953Z"
      fill={color}
    />
  </>
);

export const Copy = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      d="M9.792 18.75a3.546 3.546 0 0 1-3.542-3.542V6.667H4.792A2.293 2.293 0 0 0 2.5 8.958v11.25A2.294 2.294 0 0 0 4.792 22.5h10.416a2.294 2.294 0 0 0 2.292-2.292V18.75H9.792Z"
      fill={color}
    />
    <path
      d="M20.833 3.945c0-1.35-1.026-2.445-2.291-2.445h-8.75C8.526 1.5 7.5 2.594 7.5 3.945v11.11c0 1.35 1.026 2.445 2.292 2.445h8.75c1.265 0 2.291-1.094 2.291-2.444V3.944Z"
      fill={color}
    />
  </>
);

export const Dashboard = ({ color = defaultNavbarColor }) => (
  <>
    <path
      d="m20.38 8.57-1.23 1.85a8 8 0 0 1-.22 7.58H5.07A8 8 0 0 1 15.58 6.85l1.85-1.23A10 10 0 0 0 3.35 19a2 2 0 0 0 1.72 1h13.85a2 2 0 0 0 1.74-1 10 10 0 0 0-.27-10.44l-.01.01Z"
      fill={color}
    />
    <path
      opacity={0.48}
      d="M10.59 15.41a2 2 0 0 0 2.83 0l5.66-8.49-8.49 5.66a2 2 0 0 0 0 2.83Z"
      fill={color}
    />
  </>
);

export const Earnings = ({ color = defaultNavbarColor }) => (
  <>
    <path
      d="M13.822 8.333a1.833 1.833 0 1 0-.002-3.665 1.833 1.833 0 0 0 .002 3.665Z"
      fill={color}
    />
    <path
      d="M13.824 3.444a3.058 3.058 0 0 1 2.99 2.445h4.948V1.61A.611.611 0 0 0 21.15 1H6.496a.611.611 0 0 0-.61.611V5.89h4.947a3.058 3.058 0 0 1 2.99-2.445ZM4.053 15.74H1.611a.611.611 0 0 0-.611.611v6.038c0 .337.274.611.61.611h2.443c.337 0 .61-.274.61-.611V16.35a.61.61 0 0 0-.61-.611ZM21.762 11.389V7.11h-4.948a3.058 3.058 0 0 1-2.99 2.445 3.058 3.058 0 0 1-2.991-2.445H5.886v4.278c0 .337.273.611.61.611h14.655a.61.61 0 0 0 .61-.611Z"
      fill={color}
    />
    <path
      opacity={0.48}
      d="M22.943 16.942c-.293-.98-1.904-2.48-2.909-2.48-.2 0-.38.049-.538.145l-5.846 3.578h-1.66V16.35a.611.611 0 0 0-.61-.611H7.716a.611.611 0 0 0-.61.611v6.038c0 .337.273.611.61.611h7.214a.6.6 0 0 0 .334-.1l7.328-4.816c.362-.238.493-.665.351-1.142Z"
      fill={color}
    />
  </>
);

export const Error = ({ color = defaultNavbarColor }) => (
  <>
    <path
      d="M22.713 10.299a.66.66 0 0 0-.825-1.024 9.23 9.23 0 0 1-4.235 1.87v-.527a3.84 3.84 0 0 0-.737-2.2 6.787 6.787 0 0 0 1.903-4.697.666.666 0 0 0-1.331 0 5.5 5.5 0 0 1-1.54 3.707l-.11-.077a3.861 3.861 0 0 0-7.7 0l-.11.077a5.5 5.5 0 0 1-1.54-3.762.665.665 0 1 0-1.331 0A6.787 6.787 0 0 0 7.06 8.363a3.839 3.839 0 0 0-.737 2.2v.527a9.186 9.186 0 0 1-4.235-1.793.664.664 0 1 0-.847 1.023 10.572 10.572 0 0 0 5.06 2.2v1.925a8.415 8.415 0 0 0-2.783 6.248.666.666 0 0 0 1.33 0 7.04 7.04 0 0 1 1.486-4.3 5.599 5.599 0 0 0 5.18 5.357V7.13a.473.473 0 1 1 .936 0v14.62a5.599 5.599 0 0 0 5.148-5.358 7.04 7.04 0 0 1 1.485 4.302.665.665 0 1 0 1.33 0 8.415 8.415 0 0 0-2.782-6.248v-1.937a10.615 10.615 0 0 0 5.082-2.21Z"
      fill={color}
    />
  </>
);

export const Kanban = ({ color = defaultNavbarColor }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16Zm-8.5 3h-5A1.5 1.5 0 0 0 5 7.5v2A1.5 1.5 0 0 0 6.5 11h5A1.5 1.5 0 0 0 13 9.5v-2A1.5 1.5 0 0 0 11.5 6Z"
      fill={color}
    />
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 1-1Z"
      fill={color}
    />
  </>
);

export const Language = ({ color = defaultNavbarColor }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.226 8.806H12.71a1.776 1.776 0 0 0-1.775 1.775v7.806c.002.98.795 1.773 1.775 1.774h4.861l2.75 1.833a.355.355 0 0 0 .55-.295V20.16h.355A1.776 1.776 0 0 0 23 18.387v-7.806a1.776 1.776 0 0 0-1.774-1.775Zm-2.903 8.162-.386-1.266h-1.939l-.385 1.266h-1.216l1.878-5.342h1.38l1.883 5.342h-1.215Zm-2.041-2.212c.378-1.225.608-1.993.688-2.304.018.088.05.206.095.357.046.15.247.8.604 1.947h-1.387Z"
      fill={color}
    />
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.29 1H2.774A1.776 1.776 0 0 0 1 2.774v7.807c.001.979.795 1.773 1.774 1.774h.355v1.537a.355.355 0 0 0 .552.296l2.748-1.833h3.797V10.58a2.487 2.487 0 0 1 2.484-2.484h.354V2.774A1.776 1.776 0 0 0 11.29 1ZM9.43 9.762a7.288 7.288 0 0 1-2.492-1.246 7.552 7.552 0 0 1-2.562 1.252 3.327 3.327 0 0 0-.536-.958 6.529 6.529 0 0 0 2.37-.965A5.739 5.739 0 0 1 4.912 5.54h-.128a8.77 8.77 0 0 0-.85.038v-.99c.291.033.583.048.876.044h1.52v-.408a2.323 2.323 0 0 0-.038-.435h1.105a2.252 2.252 0 0 0-.038.428v.415h1.616c.295.004.59-.01.882-.044v.99a8.27 8.27 0 0 0-.843-.038h-.147A5.11 5.11 0 0 1 7.67 7.846c.698.466 1.49.776 2.32.907a3.382 3.382 0 0 0-.562 1.009ZM6.375 6.504c-.186-.301-.33-.626-.428-.965H7.8c-.08.34-.21.667-.383.97a3.478 3.478 0 0 1-.505.71 3.828 3.828 0 0 1-.537-.715Z"
      fill={color}
    />
  </>
);

export const Map = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.25 6.375A4.38 4.38 0 0 1 17.625 2 4.38 4.38 0 0 1 22 6.375c0 2.245-3.51 6.221-3.91 6.668a.628.628 0 0 1-.93 0c-.4-.447-3.91-4.423-3.91-6.668Zm2.5 0a1.876 1.876 0 1 0 3.751-.001 1.876 1.876 0 0 0-3.751.001Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.393 9.343a.627.627 0 0 0-.393.58v11.452a.626.626 0 0 0 .857.58l4.764-1.906a1 1 0 0 0 .629-.928V8.476a1 1 0 0 0-1.371-.929L2.393 9.343Zm16.627 4.534a1.88 1.88 0 0 1-1.395.623 1.88 1.88 0 0 1-1.395-.623 26.817 26.817 0 0 1-.48-.548v7.194a1 1 0 0 0 1.371.929l4.487-1.795a.623.623 0 0 0 .392-.58V9.89c-1.024 1.734-2.392 3.334-2.98 3.989ZM10.871 7.55l1.523.609c.473 1.23 1.307 2.526 2.106 3.602v8.56a1 1 0 0 1-1.371.929l-3-1.2a1 1 0 0 1-.629-.928V8.476a1 1 0 0 1 1.371-.928Z"
      fill={color}
    />
  </>
);

export const Settings = ({ color = defaultNavbarColor }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.556 12.98a7.93 7.93 0 0 1-.07-.98c0-.33.03-.66.07-.98L2.792 9.64a.997.997 0 0 1-.249-1.284L4.112 5.64a.998.998 0 0 1 1.236-.427l1.578.635c.313.125.66.075.942-.109.244-.161.497-.311.76-.444.298-.153.517-.426.565-.758l.242-1.682A.997.997 0 0 1 10.422 2h3.13c.495 0 .917.364.987.856l.24 1.68c.047.333.267.607.569.758.26.132.513.28.756.44.282.187.631.24.945.114l1.576-.634a.998.998 0 0 1 1.236.427l1.569 2.715a.998.998 0 0 1-.25 1.284l-1.764 1.38c.04.32.07.65.07.98 0 .33-.03.66-.07.98l1.765 1.38a.997.997 0 0 1 .249 1.284l-1.57 2.715a.998.998 0 0 1-1.235.427l-1.576-.634a1.017 1.017 0 0 0-.945.114 7.357 7.357 0 0 1-.756.44 1.02 1.02 0 0 0-.57.758l-.24 1.68a.997.997 0 0 1-.987.856h-3.13a.997.997 0 0 1-.987-.856l-.24-1.68a1.018 1.018 0 0 0-.566-.758 7.644 7.644 0 0 1-.76-.445 1.014 1.014 0 0 0-.94-.11l-1.58.635a.998.998 0 0 1-1.236-.427l-1.569-2.715a.998.998 0 0 1 .249-1.284l1.764-1.38Zm10.977 2.553a5.007 5.007 0 1 0-7.08-7.08 5.007 5.007 0 0 0 7.08 7.08Z"
      fill={color}
    />
    <path
      opacity={0.48}
      d="M14.107 9.879a3 3 0 1 1-4.243 4.242 3 3 0 0 1 4.243-4.242Z"
      fill={color}
    />
  </>
);

export const Team = ({ color = defaultNavbarColor }) => (
  <>
    <path opacity={0.48} d="M17 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.83 21c.11-.313.17-.65.17-1a8.968 8.968 0 0 0-2.4-6.12A7 7 0 0 1 24 20a1 1 0 0 1-1 1h-7.17Z"
      fill={color}
    />
    <path opacity={0.48} d="M7 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill={color} />
    <path
      d="M13 21a1 1 0 0 0 1-1 7 7 0 1 0-14 0 1 1 0 0 0 1 1h12Z"
      fill={color}
    />
  </>
);

export const Trophy = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      d="M10.89 19.778v-3.445h2.222v3.445h4.444V22H6.446v-2.222h4.444Z"
      fill={color}
    />
    <path
      d="M17.556 2v8.692l-.433 2.352a5.566 5.566 0 0 1-4.011 3.29H10.89a5.566 5.566 0 0 1-4.011-3.29L6.445 10.7V2h11.111Z"
      fill={color}
    />
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.878 13.044-.434-8.822H4.222C3 4.222 2 5.222 2 6.444v1.112c0 2.833 2.133 5.144 4.878 5.488Zm-2.656-6.6v1.112c0 1.444.934 2.666 2.223 3.133V6.444H4.222ZM17.122 13.044l.434-8.822h2.222C21 4.222 22 5.222 22 6.444v1.112c0 2.833-2.133 5.144-4.878 5.488Zm2.655-6.6v1.112c0 1.444-.933 2.666-2.222 3.133V6.444h2.223Z"
      fill={color}
    />
  </>
);

export const Upload = ({ color = defaultNavbarColor }) => (
  <>
    <path
      opacity={0.48}
      d="M15.634 12.516 13.17 10l-.003-.003-.007-.007-.005-.005-.006-.006a1.264 1.264 0 0 0-1.78.013l-2.536 2.535a1.268 1.268 0 0 0 1.793 1.792l.285-.285a.05.05 0 0 1 .086.035v4.958c0 .71.582 1.31 1.292 1.296a1.268 1.268 0 0 0 1.243-1.267v-4.94a.05.05 0 0 1 .087-.036l.225.23a1.264 1.264 0 0 0 1.812-.001c.486-.496.465-1.298-.02-1.793Z"
      fill={color}
    />
    <path
      d="M19.475 8.592a5.987 5.987 0 0 0-7.657-5.35 5.988 5.988 0 0 0-3.427 2.644A3.238 3.238 0 0 0 3.74 8.341c-.772.361-1.43.92-1.914 1.632A4.737 4.737 0 0 0 1 12.648 4.731 4.731 0 0 0 5.609 17.4h4.314v-1.643a2.326 2.326 0 0 1-1.85-.678 2.344 2.344 0 0 1 0-3.312l2.536-2.535a2.326 2.326 0 0 1 1.656-.686c.61 0 1.188.233 1.626.657l.014.013 2.498 2.548c.895.914.905 2.393.021 3.296a2.325 2.325 0 0 1-1.817.7v1.64h4.1A4.437 4.437 0 0 0 23 12.948a4.456 4.456 0 0 0-3.525-4.356Z"
      fill={color}
    />
  </>
);

export const User = ({ color = defaultNavbarColor }) => (
  <>
    <path opacity={0.48} d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill={color} />
    <path
      d="M18 21a1 1 0 0 0 1-1 7 7 0 1 0-14 0 1 1 0 0 0 1 1h12Z"
      fill={color}
    />
  </>
);

export const EmployeeManagement = () => (
  <>
    <path
      opacity={0.48}
      d="M17 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.83 21c.11-.313.17-.65.17-1a8.968 8.968 0 0 0-2.4-6.12A7 7 0 0 1 24 20a1 1 0 0 1-1 1h-7.17Z"
      fill="currentColor"
    />
    <path
      opacity={0.48}
      d="M7 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      fill="currentColor"
    />
    <path
      d="M13 21a1 1 0 0 0 1-1 7 7 0 1 0-14 0 1 1 0 0 0 1 1h12Z"
      fill="currentColor"
    />
  </>
);
