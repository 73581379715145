import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectFilterType } from 'types/filtering.d';

type FilterSelectProps = {
  id: string;
  label: string;
  value: string | string[];
  options: SelectFilterType[];
  multiple?: boolean;
  sx?: any;
  className?: string;
  fullWidth?: boolean;
  onChange: (event) => void;
};

export const FilterSelect = (props: FilterSelectProps) => {
  const {
    id,
    label,
    value,
    options,
    multiple,
    sx,
    className,
    fullWidth,
    onChange,
  } = props;

  return (
    <FormControl
      sx={{
        m: 0,
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        id={id}
        label={label}
        labelId={`${id}-label`}
        value={value}
        sx={sx}
        className={className}
        multiple={!!multiple}
        onChange={onChange}
      >
        {!!multiple && (
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterSelect.defaultProps = {
  multiple: false,
  sx: {},
};
