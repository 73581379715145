import React from 'react';

export const History = () => (
  <>
    <path
      d="M6.558 8.396h-2.02V6.375"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.925 12.537a8.574 8.574 0 0 0 8.574 8.538 8.577 8.577 0 0 0 8.576-8.575A8.577 8.577 0 0 0 12.5 3.925a8.57 8.57 0 0 0-7.144 3.834"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 9.438v3.312l2.45 1.588"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Income = () => (
  <>
    <path
      d="M5.184 11.5V7.366h8.268V11.5M13.452 7.366h1.034V3.23H6.217v4.135M17.41 12.71a4.134 4.134 0 1 1-5.847 5.848 4.134 4.134 0 0 1 5.846-5.847"
      stroke="currentColor"
      strokeWidth={1.715}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.184 15.634v4.135h9.302M14.486 11.5H4.15v4.134h6.202"
      stroke="currentColor"
      strokeWidth={1.715}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Loading = () => (
  <>
    <path
      d="M10.5 14.404a.887.887 0 0 0-.89.884v3.536c0 .488.398.884.89.884a.888.888 0 0 0 .891-.884v-3.536a.888.888 0 0 0-.891-.884ZM10.5 1.142a.888.888 0 0 0-.89.884v1.768c0 .489.398.884.89.884a.888.888 0 0 0 .891-.884V2.026a.888.888 0 0 0-.891-.884ZM6.045 9.983a.888.888 0 0 0-.89-.884H1.59a.888.888 0 0 0-.891.884c0 .488.399.884.89.884h3.564c.493 0 .891-.396.891-.884ZM19.41 9.099h-3.565a.888.888 0 0 0-.89.884c0 .488.399.884.89.884h3.564c.492 0 .891-.396.891-.884a.887.887 0 0 0-.89-.884ZM6.09 13.109l-2.52 2.5a.88.88 0 0 0 0 1.25.892.892 0 0 0 1.26 0l2.52-2.5a.88.88 0 0 0 0-1.25.896.896 0 0 0-1.26 0ZM14.28 7.116a.891.891 0 0 0 .63-.259l2.52-2.5a.88.88 0 0 0 0-1.25.896.896 0 0 0-1.26 0l-2.52 2.5a.88.88 0 0 0 0 1.25.892.892 0 0 0 .63.26ZM14.91 13.109a.896.896 0 0 0-1.26 0 .88.88 0 0 0 0 1.25l2.52 2.5a.892.892 0 0 0 1.26 0 .88.88 0 0 0 0-1.25l-2.52-2.5Z"
      fill="currentColor"
    />
  </>
);

export const Location = () => (
  <>
    <path
      d="M18.108 5.636v0a7.932 7.932 0 0 1 0 11.217l-4.287 4.287v0c-.73.73-1.913.73-2.643 0l-4.287-4.287h0a7.932 7.932 0 0 1 0-11.217v0h0a7.932 7.932 0 0 1 11.217 0h0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={12.5} cy={11.479} r={2.313} stroke="currentColor" />
  </>
);
