import React from 'react';
import { connect } from 'react-redux';
import { Box, Drawer, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

import LimeLogo from 'assets/img/lime_logo_green_horizontal.svg';
import SideMenuOptions from 'components/Layout/Menu/SideMenuOptions';
import Scrollbar from 'components/Layout/Scrollbar';
import UserInfo from 'components/Layout/UserInfo';
import { BASE_PATH, SIDE_MENU_WIDTH } from 'constants/app';
import MenuFooter from './MenuFooter';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: { xs: 'none', lg: 'flex' },
    flexShrink: { lg: 0 },
    width: SIDE_MENU_WIDTH,
  }),
);

const ScrollbarStyle = styled(Scrollbar)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '& .simplebar-content': {
      display: 'table',
      height: '100%',
      width: '100%',
    },
  }),
);

const LogoContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pt: 3.5,
    pb: 3,
    px: 3,
  }),
);

const LogoStyle = styled(Box)({
  width: 88,
}) as typeof Box;

const LinkStyle = styled(Link)({
  display: 'inline-flex',
});

type SideMenuProps = {
  isSignedIn: boolean;
};

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { isSignedIn } = props;
  const paperProps = {
    sx: {
      width: SIDE_MENU_WIDTH,
      bgcolor: 'background.default',
      position: 'relative',
    },
  };

  if (!isSignedIn) {
    return null;
  }

  return (
    <RootStyle data-testid="side-menu">
      <Drawer open variant="persistent" PaperProps={paperProps}>
        <ScrollbarStyle>
          <LogoContainerStyle>
            <LinkStyle href={`${BASE_PATH}/dashboard`} underline="none">
              <LogoStyle component="img" src={LimeLogo} />
            </LinkStyle>
          </LogoContainerStyle>
          <UserInfo />
          <SideMenuOptions />
          <MenuFooter />
        </ScrollbarStyle>
      </Drawer>
    </RootStyle>
  );
};

const mapStateToProps = ({ session }) => ({
  isSignedIn: session.isSignedIn,
});

export const UnconnectedSideMenu = SideMenu;
export default connect(mapStateToProps)(SideMenu);
