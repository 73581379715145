import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import { PAGE_NOT_FOUND } from 'constants/app';
import AccessControl from 'helpers/AccessControl';
import Page from 'pages';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.grey[600],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
);

const TitleStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    pb: pxToRem(16),
    color: theme.palette.grey[800],
  }),
);

const LogoStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: pxToRem(440),
  }),
) as typeof Box;

const MessageStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pt: pxToRem(16),
    display: 'flex',
    justifyContent: 'center',
  }),
);

const LinkStyle = styled(Link)(({ theme }) =>
  theme.unstable_sx({
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  }),
);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('assets/img/minimals-ui/404.png');

const PageNotFound = () => (
  <Page>
    <RootStyle>
      <TitleStyle variant="h2">{PAGE_NOT_FOUND}</TitleStyle>
      <LogoStyle component="img" src={logo} />
      <MessageStyle>
        <Typography variant="body1">
          Sorry, we could not find the page&nbsp;
        </Typography>
        <LinkStyle href={window.location.href} variant="body1">
          {window.location.href}
        </LinkStyle>
      </MessageStyle>
      <Typography variant="body1">
        Please select an option from the Side Menu.
      </Typography>
    </RootStyle>
  </Page>
);

export default AccessControl(PageNotFound);
