import { VariantTypes } from 'constants/svg';
import { compose } from 'redux';

export type Permission = {
  name: string;
  access: string; // read, write, delete, update..
};

export type Route = {
  label: string;
  path: string;
  component: React.FC<unknown> | React.ComponentClass<unknown>;
  amplitudeEvent: string;
  permission?: Permission;
  status?: number;
};

export type RoutesNested = Route & {
  subsections?: RoutesNested[];
};

export type RoutesFlattened = Route[];

export type MessageConfig = {
  message: string;
  open: boolean;
  position: {
    vertical: 'top' | 'bottom' | string | undefined;
    horizontal: 'center' | 'left' | 'right' | string | undefined;
  };
  alertSeverity: 'info' | 'success' | 'warning' | 'error' | string | undefined;
  status: string | number;
  requestId?: string;
};

export type Style = {
  [property: string]: string | number | { [key: string]: string | number };
};

export type ApiError = {
  error: any;
  consoleMessage: string;
  amplitudePage: string;
  amplitudeEvent: string;
  alertMessage: string;
};

export const enum CSV_REPORT_TYPES {
  tasks = 'tasks',
  earnings = 'earnings',
  performance = 'performance',
}
export type CsvReportName = CSV_REPORT_TYPES;

type MobileInfo = {
  vendor: string;
  model: string;
};

export type DeviceInfo = {
  osName: string;
  osVersion: string;
  type: string;
  mobile?: MobileInfo;
};

export type BrowserInfo = {
  browser_name: string;
  browser_version: string;
  browser_size: string;
  device: DeviceInfo;
};

export type BreakpointsValues = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

export const enum BREAKPOINTS {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type Breakpoint = BREAKPOINTS;

export type MenuSection = {
  id: string;
  items: {
    id: string;
    label: string;
    icon: VariantTypes;
    link: string;
    newRelease?: boolean;
    gateKey?: string;
  }[];
};

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export type ThemeCustomShape = {
  borderRadius: number;
  borderRadiusSm: number;
  borderRadiusMd: number;
  borderRadiusLg: number;
};

export interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
