import React, { useMemo, ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import componentsOverride from 'components/theme/overrides';
import palette from 'components/theme/palette';
import shadows, { customShadows } from 'components/theme/shadows';
import shape from 'components/theme/shape';
import typography from 'components/theme/typography';
import { ThemeCustomShape } from 'types/global.d';
import {
  PaletteColorScales,
  PaletteInputColors,
  PaletteGradients,
  PaletteBorder,
} from 'types/theme.d';

// declaring modules is necessary for TS to know custom variants
// mui docs here: https://mui.com/material-ui/customization/typography/#variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlinedInherit: true;
  }
}

declare module '@mui/material/styles' {
  interface ButtonVariants {
    outlinedInherit: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface ButtonVariantsOptions {
    outlinedInherit?: React.CSSProperties;
  }

  interface Palette {
    blue: PaletteColorScales;
    border: PaletteBorder;
    gradients: PaletteGradients;
    input: PaletteInputColors;
    orange: PaletteColorScales;
    purple: string;
    yellow: string;
  }
  interface PaletteOptions {
    blue: PaletteColorScales;
    border: PaletteBorder;
    gradients: PaletteGradients;
    input: PaletteInputColors;
    orange: PaletteColorScales;
    purple: string;
    yellow: string;
  }
  interface Theme {
    shape: ThemeCustomShape;
  }
  interface ThemeOptions {
    shape: ThemeCustomShape;
  }
}

type ThemeConfigProps = {
  children: ReactNode;
};

const ThemeConfig = ({ children }: ThemeConfigProps) => {
  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 412,
          md: 768,
          lg: 1024,
          xl: 1440,
        },
      },
      palette,
      shadows,
      shape,
      typography,
      customShadows,
    }),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeConfig;
