import React from 'react';
import { defaultColor } from 'constants/svg';

type IconProps = {
  color: string;
};

export const Download: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <rect x={4} y={18} width={16} height={2} rx={1} fill={color} />
    <rect
      x={4}
      y={20}
      width={4}
      height={2}
      rx={1}
      transform="rotate(-90 4 20)"
      fill={color}
    />
    <rect
      x={18}
      y={20}
      width={4}
      height={2}
      rx={1}
      transform="rotate(-90 18 20)"
      fill={color}
    />
    <path
      d="M12 15a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 1 1 1.16-1.63L12 12.76l3.4-2.56a1 1 0 1 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2Z"
      fill={color}
    />
    <path
      d="M12 13a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v8a1 1 0 0 1-1 1Z"
      fill={color}
    />
  </>
);

export const Eye: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <circle cx={12} cy={12} r={1.5} fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.73 5c5.98-.18 9.5 5.39 10.14 6.5a1 1 0 0 1 0 1c-.86 1.5-4.07 6.36-9.6 6.5h-.25c-5.89 0-9.26-5.41-9.89-6.5a1 1 0 0 1 0-1C3 10 6.2 5.14 11.73 5ZM8.5 12a3.5 3.5 0 1 0 7 0 3.5 3.5 0 0 0-7 0Z"
      fill={color}
    />
  </>
);

export const EyeOff: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <path
      d="M12 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM15.29 18.12 14 16.78l-.07-.07-1.27-1.27A3.501 3.501 0 0 1 8.5 12c.005-.205.025-.409.06-.61l-2-2L5 7.87a15.89 15.89 0 0 0-2.87 3.63 1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.481 9.481 0 0 0 3.23-.67l-.21-.21ZM8.59 5.76l2.8 2.8A4.07 4.07 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5 4.066 4.066 0 0 1-.06.61l2.68 2.68.84.84a15.89 15.89 0 0 0 2.91-3.63 1 1 0 0 0 0-1c-.64-1.11-4.16-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67l.09.09Z"
      fill={color}
    />
    <path
      d="M20.71 19.29 19.41 18l-2-2-9.52-9.53L6.42 5 4.71 3.29a1.004 1.004 0 1 0-1.42 1.42L5.53 7l1.75 1.7 7.31 7.3.07.07L16 17.41l.59.59 2.7 2.71a1 1 0 0 0 1.42 0 1.002 1.002 0 0 0 0-1.42Z"
      fill={color}
    />
  </>
);

export const Filter: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1Zm8 11h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1Zm6-5H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1Z"
      fill={color}
    />
  </>
);

export const Lime: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.312 12c0-4.583-3.73-8.312-8.312-8.312-4.583 0-8.312 3.73-8.312 8.312 0 4.583 3.729 8.312 8.312 8.312 4.583 0 8.312-3.73 8.312-8.312ZM2.625 12a9.375 9.375 0 1 1 18.75 0 9.375 9.375 0 0 1-18.75 0ZM12 10.787a1.213 1.213 0 1 0 0 2.426 1.213 1.213 0 0 0 0-2.426Zm2.054-.585 2.91-1.68a1.016 1.016 0 0 0 .219-1.589 7.241 7.241 0 0 0-3.38-1.955 1.017 1.017 0 0 0-1.273.983v3.361c0 .782.847 1.271 1.524.88Zm-2.584-4.24v3.36c0 .782-.846 1.271-1.524.88l-2.91-1.68a1.016 1.016 0 0 1-.22-1.589 7.24 7.24 0 0 1 3.38-1.955 1.017 1.017 0 0 1 1.274.983Zm3.114 6.918 2.911 1.68a1.016 1.016 0 0 0 1.486-.603A7.252 7.252 0 0 0 19.248 12c0-.678-.093-1.335-.267-1.957a1.015 1.015 0 0 0-1.486-.604l-2.911 1.681a1.016 1.016 0 0 0 0 1.76Zm-7.547 2.598 2.91-1.68a1.016 1.016 0 0 1 1.523.88v3.361c0 .666-.629 1.148-1.273.983a7.24 7.24 0 0 1-3.38-1.955 1.016 1.016 0 0 1 .22-1.59Zm2.38-4.358L6.504 9.44a1.016 1.016 0 0 0-1.486.603A7.255 7.255 0 0 0 4.752 12c0 .678.093 1.334.267 1.957.178.638.912.935 1.486.604l2.911-1.681a1.016 1.016 0 0 0 0-1.76Zm3.113 6.92v-3.362c0-.783.847-1.271 1.524-.88l2.91 1.68c.574.331.683 1.115.22 1.589a7.24 7.24 0 0 1-3.38 1.955 1.017 1.017 0 0 1-1.274-.983Z"
      fill={color}
    />
  </>
);

export const Refresh: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <path
      d="M17.65 6.35a7.95 7.95 0 0 0-6.48-2.31c-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20a7.98 7.98 0 0 0 7.21-4.56c.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53a5.994 5.994 0 0 1-6.8 3.31c-2.22-.49-4.01-2.3-4.48-4.52A6.002 6.002 0 0 1 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65Z"
      fill={color}
    />
  </>
);

export const BatteryHalf: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <path
      d="M7.36816 10.3682V13.7366"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7373 10.3682V13.7366"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7895 17.1053H5.68421C4.75368 17.1053 4 16.3516 4 15.4211V8.68421C4 7.75368 4.75368 7 5.68421 7H15.7895C16.72 7 17.4737 7.75368 17.4737 8.68421V15.4211C17.4737 16.3516 16.72 17.1053 15.7895 17.1053Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4736 9.52637H18.4983C19.0706 9.52637 19.9999 9.52637 19.9999 10.5731C19.9999 11.6198 19.9999 12.4855 19.9999 13.5323C19.9999 14.579 19.0707 14.579 18.4983 14.579H17.4736"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const BatteryFull: React.FC<IconProps> = ({ color = defaultColor }) => (
  <>
    <path
      d="M7.36816 10.3682V13.7366"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7373 10.3682V13.7366"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1055 10.3682V13.7366"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7895 17.1053H5.68421C4.75368 17.1053 4 16.3516 4 15.4211V8.68421C4 7.75368 4.75368 7 5.68421 7H15.7895C16.72 7 17.4737 7.75368 17.4737 8.68421V15.4211C17.4737 16.3516 16.72 17.1053 15.7895 17.1053Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4736 9.52637H18.4983C19.0706 9.52637 19.9999 9.52637 19.9999 10.5731C19.9999 11.6198 19.9999 12.4855 19.9999 13.5323C19.9999 14.579 19.0707 14.579 18.4983 14.579H17.4736"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
