import { INITIAL_FILTERS_FORM } from 'constants/performance';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  filters: INITIAL_FILTERS_FORM,
  loading: false,
  performancesData: [],
  tasksSummary: {},
  totalEmployees: 0,
  totalTasks: 0,
};

const performanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.PERFORMANCE_UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.PERFORMANCE_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case actionTypes.PERFORMANCE_FETCH_DATA:
    case actionTypes.PERFORMANCE_RESET_DATA:
      return {
        ...state,
        performancesData: action.payload.performancesData,
        tasksSummary: action.payload.tasksSummary,
        totalEmployees: action.payload.totalEmployees,
        totalTasks: action.payload.totalTasks,
      };
    default:
      return state;
  }
};

export default performanceReducer;
