import { startCase, fromPairs, omit, trim, uniq } from 'lodash';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from 'constants/app';
import { FILTERS_MAP } from 'constants/filterOptions';
import { TASKS_DATE_TIME_FORMAT } from 'constants/tasks';
import { MapTotals } from 'types/maps.d';
import { CheckboxType, SelectFilterType } from 'types/filtering.d';
import { MyTasksFiltersType, TasksDataType } from 'types/tasks.d';

export const initializeCheckboxValues = (filter, options): CheckboxType => {
  const selectedOptions = filter?.split(',');
  return options.map((option) => ({
    ...option,
    checked: selectedOptions?.includes(option.value) || false,
  }));
};

export const initializeRegionDropdownValues = (regions): SelectFilterType[] =>
  regions.map((region) => {
    const { regionName, regionToken } = region;

    return {
      label: regionName,
      value: regionToken,
    };
  });

export const changeTzToUtc = (datetime: string, timezone: string) =>
  moment.tz(datetime, DATE_TIME_FORMAT, timezone).utc().format();

export const formatMyTaskFilters = (
  filters: MyTasksFiltersType,
  timezone: string,
) => {
  const { batteryLevelRange, completedAtStartTime, completedAtEndTime } =
    filters;

  // Get the battery min/max levels.
  const [batteryLevelMin, batteryLevelMax] = batteryLevelRange;

  // Get the stat/end date in UTC format.
  const startDate = moment
    .tz(completedAtStartTime, timezone)
    .format(DATE_TIME_FORMAT);
  const endDate = moment
    .tz(completedAtEndTime, timezone)
    .format(DATE_TIME_FORMAT);
  const newStartDateUtc = changeTzToUtc(startDate, timezone);
  const newEndDateUtc = changeTzToUtc(endDate, timezone);

  // Remove the unnecessary properties (no needed for the API params).
  const validFilters: any = omit(filters, [
    'batteryLevelRange',
    'completedAtStartTime',
    'completedAtEndTime',
  ]);

  // Add the filters for the battery min/max levels.
  validFilters.batteryLevelMin = batteryLevelMin;
  validFilters.batteryLevelMax = batteryLevelMax;

  // Add the filters for the start/end date in UTC format.
  validFilters.endedAtUtcGeq = newStartDateUtc;
  validFilters.endedAtUtcLeq = newEndDateUtc;

  // Use 'reduce' for keeping the 'filters' keys but with plain values such as: a single string,
  // a number, or a string separated by commas.
  // NOTE: the 'acc' is initialized with an empty object ({}).
  return Object.keys(validFilters).reduce((acc, key) => {
    const filterValue = validFilters[key];

    // If the 'value' is an array, then convert it into a string separated by a comma.
    if (Array.isArray(filterValue)) {
      acc[key] = filterValue.join(',');
    } else {
      acc[key] = filterValue;
    }
    return acc;
  }, {});
};

export const formatLiveMapFilters = (filters) => {
  // Remove the "mapOptions" property since it is no needed for the API params.
  const validFilters = omit(filters, ['mapOptions']);

  // Use 'reduce' for keeping the 'filters' keys but with plain values such as: a single string,
  // a number, or a string separated by commas.
  // NOTE: the 'acc' is initialized with an empty object ({}).
  return Object.keys(validFilters).reduce((acc, key) => {
    const filterValue = validFilters[key];

    // If the 'value' is an array (checkboxes), then filter the 'checked' ones, create a new array
    // with only the 'checkbox value and convert the resulting array into a string separated by commas.
    if (Array.isArray(filterValue)) {
      const checkedOptions = filterValue
        .filter((option) => option.checked)
        .map((option) => option.value)
        .join(',');
      acc[key] = checkedOptions;
    } else {
      acc[key] = filterValue;
    }
    return acc;
  }, {});
};

export const formatTasksRowsForGrid = (tasksData, reportType, timezone) =>
  tasksData.map((task) => {
    const { id, attributes } = task;
    const {
      assignee: taskAssignee,
      bike,
      collectedBatteryPercentage,
      deployedBatteryPercentage,
      priority: taskPriority,
      status,
      statusChangedAt,
      taskDetailsPricing,
      type,
    } = attributes;
    const {
      lastActivityAt,
      plateNumber: vehiclePlateNumber,
      typeName,
    } = bike.attributes;
    const { fullName: assignee } = taskAssignee?.attributes;
    const {
      accuracy,
      actualEarnings,
      potentialEarnings,
      paymentStatus,
      paidAt,
    } = taskDetailsPricing?.attributes;
    const {
      startedAt: taskStartedAt,
      endedAt: taskEndedAt,
      createdAt: taskCreatedAt,
      durationSeconds,
      assignedAt: taskAssignedAt,
    } = attributes;

    const plateNumber =
      reportType === 'active'
        ? vehiclePlateNumber
        : vehiclePlateNumber && `• ${vehiclePlateNumber.toString().slice(-3)}`;
    const collectedBatteryLevel = collectedBatteryPercentage
      ? `${collectedBatteryPercentage}%`
      : '';
    const deployedBatteryLevel = deployedBatteryPercentage
      ? `${deployedBatteryPercentage}%`
      : '';
    const lastAction = `${status} at ${moment(statusChangedAt)
      .tz(timezone)
      .format(TASKS_DATE_TIME_FORMAT)}`;
    const lastGps = moment(lastActivityAt).tz(timezone).fromNow();
    const createdAt =
      taskCreatedAt &&
      moment(taskCreatedAt).tz(timezone).format(TASKS_DATE_TIME_FORMAT);
    const startedAt =
      taskStartedAt &&
      moment(taskStartedAt).tz(timezone).format(TASKS_DATE_TIME_FORMAT);
    const assignedAt =
      taskAssignedAt &&
      moment(taskAssignedAt).tz(timezone).format(TASKS_DATE_TIME_FORMAT);
    const completedAt =
      taskEndedAt &&
      moment(taskEndedAt).tz(timezone).format(TASKS_DATE_TIME_FORMAT);
    const duration = moment.duration(durationSeconds * 1000).humanize();
    const paymentDate =
      paymentStatus === 'paid'
        ? moment(paidAt).tz(timezone).format('YYYY-MM-DD')
        : paymentStatus || 'Processing';

    return {
      id,
      taskType: FILTERS_MAP.taskType[type],
      vehicleType: FILTERS_MAP.vehicleType[typeName],
      plateNumber,
      priority: FILTERS_MAP.priorityType[taskPriority],
      collectedBatteryLevel,
      deployedBatteryLevel,
      taskStatus: status,
      assignee,
      lastAction,
      lastGps,
      createdAt,
      startedAt,
      assignedAt,
      completedAt,
      duration,
      accuracy,
      pricing: potentialEarnings,
      gain: `${potentialEarnings} / ${actualEarnings}`,
      paymentDate,
    };
  });

export const calculateTotals = (
  totalsType: 'tasks' | 'vehicles',
  data: TasksDataType[],
  taskFilter: CheckboxType[],
  vehicleFilter: CheckboxType[],
): MapTotals => {
  const activeVehicles = vehicleFilter
    .filter((vehicle) => vehicle.checked === true)
    .map((vehicle) => vehicle.value);
  const activeTasks = taskFilter
    .filter((task) => task.checked === true)
    .map((task) => task.value);
  const isTasksType = totalsType === 'tasks';
  const totalsGroup = isTasksType
    ? FILTERS_MAP.taskType
    : FILTERS_MAP.vehicleType;
  const totals = Object.keys(totalsGroup).map((key) => {
    const dataByType = data.filter((record) => {
      const {
        attributes: {
          type,
          bike: {
            attributes: { typeName },
          },
        },
      } = record;

      if (isTasksType) {
        return (
          type === key &&
          activeTasks.includes(key) &&
          activeVehicles.includes(typeName)
        );
      }

      return (
        typeName === key &&
        activeVehicles.includes(key) &&
        activeTasks.includes(type)
      );
    });
    return [key, dataByType.length];
  });
  return fromPairs(totals);
};

export const setupFilterCheckbox = (data) => {
  const cleanData = uniq(data);
  const dataOptions: CheckboxType[] = [];

  cleanData.forEach((item: string) => {
    dataOptions.push({
      label: startCase(trim(item).replace(/\s+/g, ' ')), // Removes inner and outer extra whitespaces
      value: item,
      checked: false,
    });
  });

  return dataOptions;
};

export const setupFilterCheckboxv2 = (data) => {
  const dataOptions: CheckboxType[] = [];

  Object.keys(data).forEach((key) => {
    const label: string = data[key];
    dataOptions.push({
      label,
      value: key,
      checked: false,
    });
  });

  return dataOptions;
};

export const setupFilterSelect = (selectData): SelectFilterType[] =>
  selectData.map((item) => ({
    label: startCase(trim(item).replace(/\s+/g, ' ')),
    value: item,
  }));

export const setupFilterSelectv2 = (selectData) =>
  Object.keys(selectData).map((key) => {
    const label = selectData[key];
    return {
      label,
      value: key,
    };
  });

export const formatSelectedFilters = (
  filters: MyTasksFiltersType,
  taskStatus: string,
) => {
  const {
    batteryLevelRange,
    regionName,
    completedAtStartTime,
    completedAtEndTime,
    priority,
    assigneeName,
  } = filters;
  const selectedFilters: string[] = [];
  const prioritiesList = Array.isArray(priority)
    ? priority
    : priority?.split(',') || [];
  const assigneesList = Array.isArray(assigneeName)
    ? assigneeName
    : assigneeName?.split(',') || [];

  const [minLevel, maxLevel] = batteryLevelRange;
  selectedFilters.push(`Battery Level: ${minLevel}% - ${maxLevel}%`);

  if (regionName) {
    selectedFilters.push(`Region: ${regionName}`);
  }

  if (taskStatus !== 'active') {
    const dateFormat = 'MMM D, YYYY';
    const startDate = moment(completedAtStartTime).format(dateFormat);
    const endDate = moment(completedAtEndTime).format(dateFormat);
    selectedFilters.push(`Date Range: ${startDate} - ${endDate}`);
  }

  if (prioritiesList.length) {
    const priorityFilters = prioritiesList.map(
      (priorityItem) => FILTERS_MAP.priorityType[priorityItem],
    );
    selectedFilters.push(`Priority: ${priorityFilters.join(', ')}`);
  }

  if (assigneesList.length === 1) {
    selectedFilters.push(`Assignee: ${assigneeName}`);
  } else if (assigneesList.length > 1) {
    const totalAssignees = assigneesList.length;
    const remainingAssignees = totalAssignees - 2;
    const remainingLabel =
      remainingAssignees > 0 ? ` +${remainingAssignees} more` : '';
    const assignees: string[] = [];

    for (let index = 0; index < 2; index++) {
      const assignee = startCase(
        trim(assigneesList[index]).replace(/\s+/g, ' '),
      );
      assignees.push(assignee);
    }

    selectedFilters.push(`Assignee: ${assignees.join(', ')}${remainingLabel}`);
  }

  return selectedFilters;
};
