import { Theme } from '@mui/material';
import { pxToRem } from 'components/theme/typography';

export default function Table(theme: Theme): Record<string, unknown> {
  const { palette } = theme;
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          '&[class$="DataGrid-root"]': {
            '& .MuiTableBody-root': {
              '& .MuiTableRow-root': {
                '&:nth-of-type(even)': {
                  backgroundColor: palette.grey[100],
                  '&:hover': {
                    backgroundColor: palette.grey[100],
                  },
                },
                '&:hover': {
                  backgroundColor: 'inherit',
                },
              },
            },
            '& .MuiTableCell-root': {
              '&.MuiTableCell-head': {
                padding: `${pxToRem(8)} ${pxToRem(16)}`,
                backgroundColor: palette.grey[200],
                borderRadius: 0,
                border: 'none',
                '&:first-of-type': {
                  borderRadius: `${pxToRem(8)} 0 0 ${pxToRem(8)}`,
                },
                '&:last-of-type': {
                  borderRadius: `0 ${pxToRem(8)} ${pxToRem(8)} 0`,
                },
                color: palette.grey[600],
                fontSize: 14,
                fontWeight: 600,
              },
              '&.MuiTableCell-body.MuiTableCell-sizeMedium': {
                padding: `${pxToRem(8)} ${pxToRem(16)}`,
              },
            },
          },
        },
      },
    },
  };
}
