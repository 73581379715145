import { FILTERS_MAP } from 'constants/filterOptions';

export const LIVE_MAP_MIN_ZOOM = 10;
export const LIVE_MAP_MAX_ZOOM = 16;
export const DEFAULT_MAP_ZOOM = 12;
export const DEFAULT_MAP_LAT = 37.78858814278785;
export const DEFAULT_MAP_LNG = -122.39966411540836;
export const LIVE_MAP_MIN_MEDIA_QUERY_REQUIRED =
  '@media (max-width: 374px), (max-width: 768px) and (max-height: 799px)';
export const LIVE_MAP_REFRESH_AREA = 'Refresh this area';
export const LIVE_MAP_RESET_MAP_VIEW = 'Reset Map View';
export const LIVE_MAP_RESET_MAP_VIEW_TOOLTIP =
  'Restores the map view to the minimal zoom and to the original Region/Sub-region bounds';
// See style options here: https://docs.mapbox.com/api/maps/#styles
export const LIVE_MAP_MAPBOX_STYLES = {
  default: 'mapbox://styles/mtmoore55/ckdm9t3ch24tl1invkowelele',
  streets: 'mapbox://styles/mapbox/streets-v11',
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
};
export const LIVE_MAP_PIN_TYPES = {
  vehicle: 'vehicle',
  density: 'density',
};

export const LIVE_MAP_INITIAL_FILTERS = {
  plateNumber: '',
  vehicleType: 'scooter,electric',
  taskType: Object.keys(FILTERS_MAP.taskType)
    .map((key) => key)
    .join(','),
  batteryLevelMin: 0,
  batteryLevelMax: 100,
  enableFilterByLpAbilities: true,
};

export const LIVE_MAP_EMPTY_FORM_FILTERS = {
  plateNumber: '',
  vehicleType: [],
  taskType: [],
  batteryLevelMin: 0,
  batteryLevelMax: 100,
  mapOptions: [],
};

export const LIVE_MAP_DENSITY_PIN_SVG = `<svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="7" r="5" fill="currentColor" stroke="white" stroke-width="2"/>
</svg>`;

export const LIVE_MAP_VEHICLE_EBIKE_PIN_SVG = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14" cy="14" r="14" fill="currentColor"/>
<path d="M15.0431 8H12.5932L8.78223 17.0435" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.6084 12.1738H19.1475L18.5215 13.5651" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 11.5L15.7284 16.9044H18.8005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9.20004" cy="16.9043" r="3.20004" stroke="white" stroke-width="1.5"/>
<circle cx="18.7996" cy="16.9043" r="3.20004" stroke="white" stroke-width="1.5"/>
</svg>`;

export const LIVE_MAP_VEHICLE_SCOOTER_PIN_SVG = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14" cy="14" r="14" fill="currentColor"/>
<path d="M18.3712 19.6956H11.7496L8.94043 15.933L12.3291 6.75H15.4219" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.125 19.4677C9.125 20.1879 8.53916 20.775 7.8125 20.775C7.08584 20.775 6.5 20.1879 6.5 19.4677C6.5 18.7474 7.08584 18.1603 7.8125 18.1603C8.53916 18.1603 9.125 18.7474 9.125 19.4677Z" stroke="white" stroke-width="1.5"/>
<path d="M21.5 19.4677C21.5 20.1879 20.9142 20.775 20.1875 20.775C19.4608 20.775 18.875 20.1879 18.875 19.4677C18.875 18.7474 19.4608 18.1603 20.1875 18.1603C20.9142 18.1603 21.5 18.7474 21.5 19.4677Z" stroke="white" stroke-width="1.5"/>
</svg>`;

export const LIVE_MAP_TASK_CHARGE = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00025 7.00055H17.0015C18.1062 7.00055 19.0017 7.89609 19.0017 9.00079V12.5011C19.0017 14.9867 16.9867 17.0017 14.5012 17.0017H9.50055C7.01497 17.0017 5 14.9867 5 12.5011V9.00079C5 7.89609 5.89554 7.00055 7.00025 7.00055Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2403 14L13.2403 12H10.8403L11.8403 10" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.08057 7V3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.9214 7V3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.001 21.0016C12.001 18.0925 12.001 17.0016 12.001 17.0016" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const LIVE_MAP_TASK_DEPLOY = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.8572 9.87733V4.88889C18.8572 4.39822 18.4589 4 17.9683 4H16.3176C15.8269 4 15.4287 4.39822 15.4287 4.88889V6.93867" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 10.8569L10.8427 4.99203C11.5084 4.42136 12.4907 4.42136 13.1564 4.99203L20 10.8569" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.14355 9.87732V18.2222C5.14355 19.2044 5.93911 20 6.92133 20H11.1116" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 18L20 16L18 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 16H20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const LIVE_MAP_TASK_MOVE = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 16.6317L6.98667 16.6417C7.8275 16.6458 8.61333 16.2275 9.08 15.5283L14.2542 7.76666C14.7192 7.06833 15.5042 6.65083 16.3433 6.65333L20 6.66666" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3335 18.2984L20.0002 16.6317L18.3335 14.965" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.91083 8.83669L9.08667 7.69336C8.61417 7.03836 7.85417 6.65169 7.04583 6.65586L5 6.66669" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3076 14.4617L14.246 15.675C14.721 16.2892 15.4551 16.6475 16.2318 16.645L20.0001 16.6317" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3335 8.33333L20.0002 6.66667L18.3335 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const LIVE_MAP_TASK_RETRIEVE = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.8572 9.87733V4.88889C18.8572 4.39822 18.4589 4 17.9683 4H16.3176C15.8269 4 15.4287 4.39822 15.4287 4.88889V6.93867" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 10.8569L10.8427 4.99203C11.5084 4.42136 12.4907 4.42136 13.1564 4.99203L20 10.8569" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.14355 9.87732V18.2222C5.14355 19.2044 5.93911 20 6.92133 20H11.1116" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 18L14 16L16 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 16H14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const LIVE_MAP_TASK_SWAP = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 7.41667V18.4167C17.5 19.4296 16.6796 20.25 15.6667 20.25H8.33333C7.32042 20.25 6.5 19.4296 6.5 18.4167L6.5 7.41667C6.5 6.40375 7.32042 5.58333 8.33333 5.58333H15.6667C16.6796 5.58333 17.5 6.40375 17.5 7.41667Z" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.25 5.58331V4.65381C9.25 4.51173 9.283 4.37148 9.34717 4.24406L9.56992 3.79856C9.72483 3.48781 10.0429 3.29165 10.3894 3.29165L13.6106 3.29165C13.958 3.29165 14.2752 3.48781 14.4301 3.79856L14.6528 4.24406C14.717 4.37148 14.75 4.51173 14.75 4.65381V5.58331" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.1 16L13.6 13H10L11.5 10" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const LIVE_MAP_TASK_PINS = {
  swap: LIVE_MAP_TASK_SWAP,
  charge: LIVE_MAP_TASK_CHARGE,
  retrieve: LIVE_MAP_TASK_RETRIEVE,
  move: LIVE_MAP_TASK_MOVE,
  deploy: LIVE_MAP_TASK_DEPLOY,
};
