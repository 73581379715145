import React, { useState, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import LimeLogo from 'assets/img/lime_logo_green_horizontal.svg';
import MenuFooter from 'components/Layout/Menu/MenuFooter';
import MobileMenuOptions from 'components/Layout/Menu/MobileMenuOptions';
import OperatorNameAndPosition from 'components/Layout/OperatorNameAndPosition';
import OperatorAvatar from 'components/OperatorAvatar';
import { pxToRem } from 'components/theme/typography';
import { BASE_PATH, SIDE_MENU_WIDTH } from 'constants/app';
import { logoutUser } from 'reduxStore/actions/session';

const useStyles = makeStyles({
  mainMenu: {
    width: SIDE_MENU_WIDTH,
    '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
      width: '100%',
    },
  },
});

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: { xs: 'flex', lg: 'none' },
    alignItems: 'center',
  }),
);

const MenuBlockStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  }),
);

const LinkStyle = styled(Link)({
  display: 'flex',
  width: pxToRem(78),
});

const LogoStyle = styled(Box)({
  height: pxToRem(28),
}) as typeof Box;

const AvatarContainerStyle = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    p: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  }),
);

const MenuStyle = styled(Menu)(({ theme }) =>
  theme.unstable_sx({
    p: 0,
    display: 'block',
  }),
);

const MenuUserStyle = styled(Menu)(({ theme }) =>
  theme.unstable_sx({
    p: 0,
    display: 'block',
    left: pxToRem(-20),
    '& .MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation':
      {
        width: pxToRem(160),
      },
  }),
);

const MenuItemTextContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(16),
    py: pxToRem(6),
  }),
);

type MobileMenuProps = {
  isSignedIn: boolean;
  dispatchLogoutUser: () => void;
};

const MobileMenu = (props: MobileMenuProps) => {
  const classes = useStyles();
  const { isSignedIn, dispatchLogoutUser } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [anchorUserElement, setAnchorUserElement] =
    useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setIsMenuOpen(true);
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setAnchorElement(null);
  };

  const handleUserMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setIsUserMenuOpen(true);
    setAnchorUserElement(event.currentTarget);
  };

  const handleUserMenuClose = (menuOption?: string) => {
    setIsUserMenuOpen(false);
    setAnchorUserElement(null);

    if (menuOption === 'logout') {
      dispatchLogoutUser();
    }
  };

  if (!isSignedIn) {
    return null;
  }

  return (
    <RootStyle>
      <MenuBlockStyle>
        <IconButton
          aria-controls="mobile-menu"
          size="large"
          color="inherit"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <LinkStyle href={`${BASE_PATH}/dashboard`} underline="none">
          <LogoStyle component="img" src={LimeLogo} />
        </LinkStyle>
      </MenuBlockStyle>
      <AvatarContainerStyle
        aria-controls="user-menu"
        onClick={handleUserMenuOpen}
      >
        <OperatorAvatar size={28} />
      </AvatarContainerStyle>
      <MenuStyle
        id="mobile-menu"
        className={classes.mainMenu}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MobileMenuOptions onOptionSelect={handleMenuClose} />
        <MenuFooter data-e2e="footer-section" />
      </MenuStyle>
      <MenuUserStyle
        id="user-menu"
        anchorEl={anchorUserElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isUserMenuOpen}
        onClose={() => handleUserMenuClose()}
      >
        <MenuItemTextContainerStyle>
          <OperatorNameAndPosition />
        </MenuItemTextContainerStyle>
        <MenuItem onClick={() => handleUserMenuClose('logout')}>
          <Typography variant="body2">Log Out</Typography>
        </MenuItem>
      </MenuUserStyle>
    </RootStyle>
  );
};

const mapStateToProps = ({ session }) => ({
  isSignedIn: session.isSignedIn,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
