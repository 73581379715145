import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import OperatorAvatar from 'components/OperatorAvatar';
import { pxToRem } from 'components/theme/typography';
import { CheckboxType } from 'types/filtering.d';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mb: pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
);

const OptionStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
  }),
);

const LabelStyle = styled(FormControlLabel)(({ theme }) =>
  theme.unstable_sx({
    flex: 1,
  }),
);

type CheckBoxGroupProps = {
  addAvatar?: boolean;
  groupLabel?: string;
  options: CheckboxType[];
  onChange: (newValues) => void;
};

export const CheckBoxGroup = (props: CheckBoxGroupProps) => {
  const { addAvatar, groupLabel, options, onChange } = props;
  const [checkboxOptions, setCheckboxOptions] =
    useState<CheckboxType[]>(options);
  const formStyles = { m: 0 };
  const formGroupStyles = { m: 1 };
  const optionStyles = {
    marginBottom: addAvatar ? 1 : 0,
  };

  const handleChange = (event) => {
    const {
      target: { name, checked },
    } = event;

    const optionIndex = checkboxOptions.findIndex(
      (option) => option.value === name,
    );
    const optionsCopy = cloneDeep(checkboxOptions);
    optionsCopy[optionIndex].checked = checked;
    setCheckboxOptions(optionsCopy);
    onChange(optionsCopy);
  };

  return (
    <RootStyle component="form">
      <FormControl sx={formStyles}>
        {!!groupLabel && (
          <Typography variant="subtitle1">{groupLabel}</Typography>
        )}
        <FormGroup sx={formGroupStyles}>
          {checkboxOptions.map((option) => (
            <OptionStyle key={option.id || option.value} sx={optionStyles}>
              <LabelStyle
                control={
                  <Checkbox
                    checked={option.checked}
                    name={option.value}
                    onChange={handleChange}
                  />
                }
                label={option.label}
              />
              {addAvatar && (
                <OperatorAvatar
                  operator={{
                    fullName: option.label,
                  }}
                />
              )}
            </OptionStyle>
          ))}
        </FormGroup>
      </FormControl>
    </RootStyle>
  );
};

CheckBoxGroup.defaultProps = {
  addAvatar: false,
  groupLabel: '',
};
