import { merge } from 'lodash';
import Autocomplete from 'components/theme/overrides/Autocomplete';
import Backdrop from 'components/theme/overrides/Backdrop';
import Button from 'components/theme/overrides/Button';
import Card from 'components/theme/overrides/Card';
import IconButton from 'components/theme/overrides/IconButton';
import Input from 'components/theme/overrides/Input';
import Lists from 'components/theme/overrides/Lists';
import Paper from 'components/theme/overrides/Paper';
import Slider from 'components/theme/overrides/Slider';
import Tab from 'components/theme/overrides/Tab';
import Table from 'components/theme/overrides/Table';
import Tooltip from 'components/theme/overrides/Tooltip';
import Typography from 'components/theme/overrides/Typography';

export default function ComponentsOverrides(theme) {
  return merge(
    Autocomplete(theme),
    Backdrop(theme),
    Button(theme),
    Card(theme),
    IconButton(theme),
    Input(theme),
    Lists(theme),
    Paper(),
    Slider(theme),
    Tab(theme),
    Table(theme),
    Tooltip(theme),
    Typography(theme),
  );
}
