import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, trim } from 'lodash';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import { Operator, OperatorAvatarType } from 'types/operator.d';

const AvatarStyle = styled(Avatar)(({ theme }) =>
  theme.unstable_sx({
    mr: 2,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[400],
  }),
);

type OperatorAvatarProps = {
  lpOperator: Operator;
  noMargin?: boolean;
  operator?: OperatorAvatarType;
  size?: number;
};

const OperatorAvatar: React.FC<OperatorAvatarProps> = (props) => {
  const { lpOperator, noMargin, operator, size } = props;
  const avatarSize = size
    ? {
        width: size,
        height: size,
        fontSize: pxToRem(size / 2),
      }
    : {};
  const style = noMargin
    ? {
        ...avatarSize,
        mr: 0,
      }
    : avatarSize;
  let photoUrl = lpOperator?.attributes?.photoUrl || undefined;
  let firstName = lpOperator?.attributes?.firstName;
  let lastName = lpOperator?.attributes?.lastName;

  if (!isEmpty(operator)) {
    photoUrl = operator?.photoUrl || undefined;
    firstName =
      trim(operator?.firstName) || trim(operator?.fullName)?.split(' ').shift();
    lastName =
      trim(operator?.lastName) || trim(operator?.fullName)?.split(' ').pop();
  }

  const getInitials = (fName = '', lName = '') =>
    `${fName.slice(0, 1).toUpperCase()}${lName.slice(0, 1).toUpperCase()}`;

  return (
    <AvatarStyle src={photoUrl} sx={style}>
      {getInitials(firstName, lastName)}
    </AvatarStyle>
  );
};

const mapStateToProps = ({ session }) => ({
  lpOperator: session.lpOperator,
});

export const UnconnectedSideMenu = OperatorAvatar;
export default connect(mapStateToProps)(OperatorAvatar);
