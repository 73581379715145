import { createActionTypesFromArray } from 'lib/ActionHelpers';

export default createActionTypesFromArray([
  // assets
  'ASSETS_BATTERY_DETAILS',
  'ASSETS_BATTERY_TIMELINE',
  'ASSETS_UPDATE_SUMMARY',
  'ASSETS_ERROR_SUMMARY',
  'ASSETS_UPDATE_BATTERY_POSSESSIONS',
  'ASSETS_ERROR_BATTERY_POSSESSIONS',
  'ASSETS_UPDATE_BATTERY_INVENTORY',
  'ASSETS_ERROR_BATTERY_INVENTORY',
  'ASSETS_CLEAR_BATTERY_INVENTORY',
  'ASSETS_SMART_CARTS_LIST',
  'ASSETS_UPDATE_SMART_CART_DETAIL',
  'UPDATE_IS_SMART_CARTS_LIST_LOADING',
  'UPDATE_IS_ASSET_SUMMARY_PAGE_LOADING',
  'UPDATE_IS_BATTERY_INVENTORY_LOADING',
  'UPDATE_IS_BATTERY_POSSESSIONS_LOADING',
  'UPDATE_IS_BATTERY_DETAILS_LOADING',
  'UPDATE_IS_BATTERY_TIMELINE_LOADING',
  'UPDATE_IS_SMART_CART_DETAIL_LOADING',
  // messages
  'SET_LAST_REQUEST_ID',
  'UPDATE_MESSAGE_CONFIG',
  // session
  'SESSION_LOGIN',
  'SESSION_LOGOUT',
  'SESSION_UPDATE_LOGIN_PHASE',
  'SESSION_UPDATE_PHONE_NUMBER',
  'SESSION_UPDATE_REGION',
  'SESSION_VALIDATE_SESSION',
  'SESSION_UPDATE_TIMEZONE',
  'SESSION_UPDATE_WINDOW_SIZE',
  // tasks
  'TASKS_UNSET_TOTAL_TASKS',
  'TASKS_UPDATE_DATA',
  'TASKS_UPDATE_STATUS',
  'TASKS_UPDATE_DOWNLOADING_STATUS',
  'TASKS_UPDATE_FILTER_OPTIONS',
  'TASKS_UPDATE_FILTERS',
  'TASKS_UPDATE_LOADING_STATUS',
  'TASKS_UPDATE_PAGE_NUMBER',
  'TASKS_UPDATE_PAGE_SIZE',
  'TASKS_MAP_UPDATE_MAP_BOUNDS',
  'TASKS_MAP_UPDATE_MAP_CENTER',
  'TASKS_MAP_UPDATE_DATA',
  'TASKS_MAP_UPDATE_FILTERED_DATA',
  'TASKS_MAP_UPDATE_FILTERS',
  'TASKS_MAP_UPDATE_LOADING_STATUS',
  'TASKS_MAP_UPDATE_ZOOM_LEVEL',
  // task forecasting
  'TASKFORECASTING_FETCH_DATA',
  'TASKFORECASTING_CHOOSE_VEHICLE',
  'TASKFORECASTING_HIDE_TASK',
  'TASKFORECASTING_SHOW_TASK',
  // dashboard
  'DASHBOARD_RESET_DATA',
  'DASHBOARD_UPDATE_DATA',
  'DASHBOARD_INIT_LOADING_STATUS',
  'DASHBOARD_UPDATE_LOADING_STATUS',
  // performance
  'PERFORMANCE_FETCH_DATA',
  'PERFORMANCE_RESET_DATA',
  'PERFORMANCE_UPDATE_LOADING_STATUS',
  'PERFORMANCE_UPDATE_FILTERS',
  // earnings
  'EARNINGS_FETCH_DATA',
  'EARNINGS_FETCH_SUMMARY_DATA',
  'EARNINGS_RESET_DATA',
  'EARNINGS_UPDATE_LOADING_STATUS',
  'EARNINGS_UPDATE_FILTERS',
  // profile
  'PROFILE_FETCH_FLEET_GROUP',
  'PROFILE_UPDATE_FLEET_GROUP',
  'PROFILE_UPDATE_LOADING_STATUS',
  // settings
  'SETTINGS_FETCH_SETTINGS',
  'SETTINGS_UPDATE_SETTINGS',
  'SETTINGS_UPDATE_LOADING_STATUS',
  // employee management
  'EMPLOYEE_MANAGEMENT_FETCH_DATA',
  'EMPLOYEE_MANAGEMENT_LOADING_STATUS',
  'EMPLOYEE_MANAGEMENT_PAGE_SETUP',
  'EMPLOYEE_MANAGEMENT_CREATE_SUBACCOUNT',
  'EMPLOYEE_MANAGEMENT_UPDATE_SUBACCOUNT',
  'EMPLOYEE_MANAGEMENT_UPDATE_FILTERS',
  'EMPLOYEE_MANAGEMENT_UPDATE_STATUS',
  'EMPLOYEE_MANAGEMENT_VALIDATE_EMAIL',
  'EMPLOYEE_MANAGEMENT_VALIDATE_PHONE',
]);
