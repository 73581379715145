// Global
export const PAGE_VISIT_EVENT = 'Page: Visit';
export const PAGINATION_UPDATE_DUE_TIMEZONE_EVENT =
  'Pagination update due a change on the Timezone';
export const TIMEZONE_CHANGE_EVENT = 'Timezone change';
export const USER_BROWSER_INFO_EVENT = 'Browser Info';

// Login
export const LOGIN_PAGE = 'Login';
export const LOGIN_PATH = '/login';
export const LOGIN_IMPERSONATE_PATH = '/login/impersonate';
export const LOGIN_CLIENT_AUTH_EVENT = 'Client Auth Check';
export const LOGIN_PHONE_NUMBER_ENTER_EVENT =
  'Phone Number entered by Input Field';
export const LOGIN_PIN_CODE_ENTER_EVENT = '6 Digit Code entered by Input Field';
export const LOGIN_REQUEST_HELP_EVENT = 'Request for Help: Link Click';
export const LOGIN_RETURN_TO_SIGN_IN_EVENT = 'Return to Sign-in: Button Click';
export const LOGIN_SEND_PIN_CODE_EVENT = 'Send a new 6 Digit Code';
export const LOGIN_SUBMIT_PHONE_NUMBER_EVENT = 'Submit Phone Number';
export const LOGIN_SUBMIT_PIN_CODE_EVENT = 'Submit 6 Digit Code';

// Dashboard
export const DASHBOARD_PAGE = 'Homepage';
export const DASHBOARD_PATH = '/dashboard';
export const DASHBOARD_FETCH_METRICS_EVENT = 'Get {METRIC_NAME} metrics';

// Asset Management
export const ASSETS_PAGE = 'Assets';
export const ASSETS_PATH = '/assets';
export const ASSETS_BATTERY_POSSESSIONS_PATH = `${ASSETS_PATH}/battery_possessions`;
export const ASSETS_BATTERY_INVENTORY_PATH = `${ASSETS_BATTERY_POSSESSIONS_PATH}/inventory`;
export const ASSETS_SMART_CARTS_PATH = `${ASSETS_PATH}/smart_carts`;
export const ASSETS_SMART_CARTS_DETAIL = `${ASSETS_PATH}/smart_cart/:id`;
export const ASSETS_BATTERY_DETAIL = `${ASSETS_PATH}/battery/:id`;

export const ASSETS_SUMMARY_EVENT = 'Fetch asset summary data';
export const ASSETS_SMART_CARTS_EVENT = 'Fetch In Smart Carts';
export const ASSETS_BATTERY_POSSESSIONS_EVENT =
  'Fetch battery possessions data';
export const ASSETS_BATTERY_INVENTORY_EVENT = 'Fetch battery inventory data';

// Task Forecasting
export const TASK_FORECAST_PAGE = 'Task Forecast';
export const TASK_FORECAST_PATH = '/task-forecast';
export const TASK_FORECAST_FETCH_DATA_EVENT = 'Fetch data';
export const TASK_FORECAST_SELECT_VEHICLE_EVENT = 'Select vehicle';
export const TASK_FORECAST_SHOW_TASK_TYPE_EVENT = 'Show task type';
export const TASK_FORECAST_HIDE_TASK_TYPE_EVENT = 'Hide task type';

// Performance
export const PERFORMANCE_PAGE = 'Performance';
export const PERFORMANCE_PATH = '/performance';
export const PERFORMANCE_FETCH_DATA_EVENT = 'Fetch performance management data';
export const PERFORMANCE_FILTERS_EVENT = 'Update Filters';
export const PERFORMANCE_CHANGE_PAGE_EVENT = 'Change Page';
export const PERFORMANCE_CHANGE_PAGE_SIZE_EVENT = 'Change Rows per Page';

// Earnings
export const EARNINGS_PAGE = 'Earnings';
export const EARNINGS_PATH = '/earnings';
export const EARNINGS_FETCH_DATA_EVENT = 'Fetch earnings data';
export const EARNINGS_FETCH_SUMMARY_DATA_EVENT = 'Fetch earnings summary data';
export const EARNINGS_FILTERS_EVENT = 'Update Filters';
export const EARNINGS_CHANGE_PAGE_EVENT = 'Change Page';
export const EARNINGS_CHANGE_PAGE_SIZE_EVENT = 'Change Rows per Page';

// Live Map
export const LIVE_MAP_PAGE = 'Live Map';
export const LIVE_MAP_PATH = '/live-map';
export const LIVE_MAP_FETCH_DATA_EVENT = 'Fetch tasks data';
export const LIVE_MAP_FILTERS_EVENT = 'Update Filters';
export const LIVE_MAP_ZOOM_LEVEL_EVENT = 'Update Zoom Level';
export const LIVE_MAP_CENTER_EVENT = 'Update Map Center';
export const LIVE_MAP_UPDATE_BOUNDS_EVENT = 'Update Map Bounds';

// My Tasks
export const MY_TASKS_PAGE = 'My Tasks';
export const MY_TASKS_PATH = '/my-tasks';
export const MY_TASKS_CHANGE_PAGE_EVENT = 'Change Page';
export const MY_TASKS_CHANGE_PAGE_SIZE_EVENT = 'Change Rows per Page';
export const MY_TASKS_FETCH_DATA_EVENT = 'Fetch tasks data';
export const MY_TASKS_FETCH_FILTER_OPTIONS_EVENT = 'Fetch LP filter options';
export const MY_TASKS_FILTERS_EVENT = 'Update Filters';

// Employee Management
export const EMPLOYEE_MANAGEMENT_PAGE = 'Sub-account Management';
export const EMPLOYEE_MANAGEMENT_PATH = '/employee-management';
export const EMPLOYEE_MANAGEMENT_PAGE_ADD = 'Add New Team Member';
export const EMPLOYEE_MANAGEMENT_PATH_ADD = `${EMPLOYEE_MANAGEMENT_PATH}/add`;
export const EMPLOYEE_MANAGEMENT_PAGE_EDIT = 'Edit Team Member';
export const EMPLOYEE_MANAGEMENT_PATH_EDIT = `${EMPLOYEE_MANAGEMENT_PATH}/edit/:id`;
export const EMPLOYEE_MANAGEMENT_CHANGE_PAGE_EVENT = 'Change Page';
export const EMPLOYEE_MANAGEMENT_CHANGE_PAGE_SIZE_EVENT =
  'Change Rows per Page';
export const EMPLOYEE_MANAGEMENT_FETCH_DATA_EVENT = 'Fetch employees data';
export const EMPLOYEE_MANAGEMENT_FILTERS_EVENT = 'Update Filters';
export const EMPLOYEE_MANAGEMENT_UPDATE_STATUS_EVENT =
  'Update Sub-account status';
export const EMPLOYEE_MANAGEMENT_VALIDATE_EMAIL_EVENT =
  'Validate Email address for Sub-account';
export const EMPLOYEE_MANAGEMENT_VALIDATE_PHONE_EVENT =
  'Validate Phone number for Sub-account';
export const EMPLOYEE_MANAGEMENT_CREATE_EVENT = 'Create Sub-account';
export const EMPLOYEE_MANAGEMENT_UPDATE_EVENT = 'Update Sub-account';

// My Team
export const MY_TEAM_PAGE = 'My Team';
export const MY_TEAM_PATH = '/my-team';

// Profile
export const PROFILE_PAGE = 'Profile';
export const PROFILE_PATH = '/profile';
export const PROFILE_FETCH_FLEET_GROUP_EVENT = 'Fetch profile data';

// Settings
export const SETTINGS_PAGE = 'Settings';
export const SETTINGS_PATH = '/settings';
export const SETTINGS_FETCH_SETTINGS_EVENT = 'Fetch settings data';

// Page not found
export const UNKNOWN_PAGE = 'Page Not Found';

// CSV Dowloader
export const CSV_DOWNLOAD_FILE_EVENT = 'Download CSV File';
export const CSV_TRIGGER_DOWNLOAD_EVENT = 'Download CSV: Button Click';
