import { INITIAL_FILTERS_FORM } from 'constants/earnings';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  currencyCode: undefined,
  loading: false,
  filters: INITIAL_FILTERS_FORM,
  payments: [],
  totalEarnings: 0,
  totalPages: 0,
  totalPayments: 0,
  summary: {},
};

const earningsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.EARNINGS_UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.EARNINGS_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case actionTypes.EARNINGS_RESET_DATA:
    case actionTypes.EARNINGS_FETCH_DATA:
      return {
        ...state,
        currencyCode: action.payload.currencyCode,
        payments: action.payload.payments,
        totalEarnings: action.payload.totalEarnings,
        totalPages: action.payload.totalPages,
        totalPayments: action.payload.totalPayments,
      };
    case actionTypes.EARNINGS_FETCH_SUMMARY_DATA:
      return {
        ...state,
        summary: action.payload,
      };
    default:
      return state;
  }
};

export default earningsReducer;
