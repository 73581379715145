import _ from 'lodash';
import {
  VehicleTypes,
  TaskTypes,
  VehicleDailyTaskVolume,
  TaskTypeKeyName,
  DateKeyName,
  VehicleTypeKeyName,
  RawResponse,
  VolumeKeyName,
  ForecastingValueKeyName,
  ForecastingLowerBoundKeyName,
  ForecastingUpperBoundKeyName,
  DateShortKeyName,
} from 'types/taskForecasting.d';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  data: {
    taskTomorrow: [
      [
        {
          taskType: 'swap_task',
          vehicleType: 'scooter',
          forecastedValue: 0,
        },
        {
          taskType: 'swap_task',
          vehicleType: 'electric',
          forecastedValue: 0,
        },
      ],
      [
        {
          taskType: 'charge_task',
          vehicleType: 'scooter',
          forecastedValue: 0,
        },
        {
          taskType: 'charge_task',
          vehicleType: 'electric',
          forecastedValue: 0,
        },
      ],
      [
        {
          taskType: 'retrieval_task',
          vehicleType: 'scooter',
          forecastedValue: 0,
        },
        {
          taskType: 'retrieval_task',
          vehicleType: 'electric',
          forecastedValue: 0,
        },
      ],
      [
        {
          taskType: 'onsite_repair_task',
          vehicleType: 'scooter',
          forecastedValue: 0,
        },
        {
          taskType: 'onsite_repair_task',
          vehicleType: 'electric',
          forecastedValue: 0,
        },
      ],
      [
        {
          taskType: 'move_task',
          vehicleType: 'scooter',
          forecastedValue: 0,
        },
        {
          taskType: 'move_task',
          vehicleType: 'electric',
          forecastedValue: 0,
        },
      ],
      [
        {
          taskType: 'deploy_task',
          vehicleType: 'scooter',
          forecastedValue: 0,
        },
        {
          taskType: 'deploy_task',
          vehicleType: 'electric',
          forecastedValue: 0,
        },
      ],
    ],
    taskTrend: [],
    taskVolumes: [],
  },
  vehicle: VehicleTypes.Scooter,
  hiddenTasks: [],
};

const typeCast = (item: VehicleDailyTaskVolume) => {
  const IntegerKeys = [
    VolumeKeyName,
    ForecastingValueKeyName,
    ForecastingLowerBoundKeyName,
    ForecastingUpperBoundKeyName,
  ];
  _.map(IntegerKeys, (key: string) => {
    item[key] = _.toInteger(item[key]);
  });
  item[DateShortKeyName] = item[DateKeyName].slice(-5);

  return item;
};

const flattenRawData = (rawData: RawResponse) => {
  const addLocalDate = (
    taskVolume: VehicleDailyTaskVolume[],
    localDate: string,
  ) => {
    _.map(taskVolume, (item) => {
      item[DateKeyName] = localDate;
    });
  };
  _.forIn(rawData, addLocalDate);
  return _.chain(rawData).values().flatten().map(typeCast).value();
};

const removeExperiedForecasting = (
  item: VehicleDailyTaskVolume,
  localCurrent: string,
) => {
  if (item[DateKeyName] < localCurrent) {
    item[ForecastingLowerBoundKeyName] = null;
    item[ForecastingUpperBoundKeyName] = null;
  }
  if (item[DateKeyName] >= localCurrent) {
    item[VolumeKeyName] = null;
  }
  return item;
};

const groupSortData = (
  taskVolume: VehicleDailyTaskVolume[],
  localDates: string[],
  vehicles: VehicleTypes[],
) => {
  const serviceTasksEnabled = taskVolume.some(
    (task: VehicleDailyTaskVolume) =>
      task[TaskTypeKeyName] === TaskTypes.ServiceTask,
  );
  let taskTypeValues = _.values(TaskTypes);
  if (!serviceTasksEnabled) {
    taskTypeValues = taskTypeValues.filter(
      (taskType: string) => taskType !== TaskTypes.ServiceTask,
    );
  }
  const vehicleTypeValues = _.values(VehicleTypes);
  const sortByTaskType = (task: VehicleDailyTaskVolume) =>
    _.indexOf(taskTypeValues, task[TaskTypeKeyName]);
  const sortByVehicleType = (task: VehicleDailyTaskVolume) =>
    _.indexOf(vehicleTypeValues, task[VehicleTypeKeyName]);
  const filterByTaskType = (task: VehicleDailyTaskVolume) =>
    _.includes(taskTypeValues, task[TaskTypeKeyName]);
  const filterByVehicleType = (task: VehicleDailyTaskVolume) =>
    _.isEmpty(vehicles) ? true : _.includes(vehicles, task[VehicleTypeKeyName]);
  const filterBylocalDates = (task: VehicleDailyTaskVolume) =>
    _.isEmpty(localDates) ? true : _.includes(localDates, task[DateKeyName]);
  return _.chain(taskVolume)
    .filter(filterBylocalDates)
    .filter(filterByTaskType)
    .filter(filterByVehicleType)
    .sortBy(sortByTaskType)
    .sortBy(sortByVehicleType)
    .sortBy(DateKeyName)
    .groupBy(TaskTypeKeyName)
    .values()
    .value();
};

/**
 * filter forecasting data for tomorrow only, then group by task and sort by vehicle
 * @param taskVolumes
 * @param tomorrow
 */
const filterTaskTomorrow = (
  taskVolumes: VehicleDailyTaskVolume[],
  tomorrow: string,
) => groupSortData(taskVolumes, [tomorrow], []);

/**
 * filter current vehicle only data, and then group by task type and sort by date
 * @param taskVolumes
 * @param vehicle
 */
const filterTaskTrendingByVehicle = (
  taskVolumes: VehicleDailyTaskVolume[],
  vehicle: VehicleTypes,
) => groupSortData(taskVolumes, [], [vehicle]);

const taskForecastingReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.TASKFORECASTING_FETCH_DATA: {
      const taskVolumes = _.chain(flattenRawData(action.payload.data))
        .map((item) =>
          removeExperiedForecasting(item, action.payload.currentLocal),
        )
        .value();
      return {
        ...state,
        data: {
          taskTomorrow: filterTaskTomorrow(
            taskVolumes,
            action.payload.tomorrowLocal,
          ),
          taskTrend: filterTaskTrendingByVehicle(taskVolumes, state.vehicle),
          taskVolumes,
        },
      };
    }
    case actionTypes.TASKFORECASTING_CHOOSE_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
        data: {
          ...state.data,
          taskTrend: filterTaskTrendingByVehicle(
            state.data.taskVolumes,
            action.payload,
          ),
        },
      };
    case actionTypes.TASKFORECASTING_HIDE_TASK:
      return {
        ...state,
        hiddenTasks: _.uniq(_.concat(state.hiddenTasks, action.payload)),
      };
    case actionTypes.TASKFORECASTING_SHOW_TASK:
      return {
        ...state,
        hiddenTasks: _.pull(state.hiddenTasks, action.payload),
      };
    default:
      return state;
  }
};

export default taskForecastingReducers;
