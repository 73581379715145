import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import OperatorNameAndPosition from 'components/Layout/OperatorNameAndPosition';
import OperatorAvatar from 'components/OperatorAvatar';
import { pxToRem } from 'components/theme/typography';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pt: pxToRem(12),
    pb: pxToRem(40),
    px: pxToRem(20),
  }),
);

const AccountStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(20),
    py: pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[200],
  }),
);

const UserDataStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),
);

const UserInfo = () => (
  <RootStyle>
    <AccountStyle data-e2e="user-section">
      <OperatorAvatar />
      <UserDataStyle>
        <OperatorNameAndPosition />
      </UserDataStyle>
    </AccountStyle>
  </RootStyle>
);

export default UserInfo;
