export const enum TaskTypes {
  Charge = 'charge',
  Move = 'move',
  Retrieve = 'retrieve',
  Swap = 'swap',
  Deploy = 'deploy',
  Service = 'service',
}
export type DashboardMetrics = {
  TaskType: TaskTypes;
  Total?: number;
  Scooter: number;
  Electric: number;
};

export type RealtimeCompletedTasks = {
  TaskType: TaskTypes;
  Total: number;
};

export type YesterdayMetric = {
  TaskType: TaskTypes;
  Metric: number;
};

export type DashboardState = {
  dataLoading: boolean;

  metrics: {
    realtimeAvailableMetrics: DashboardMetrics[];
    realtimeCollectedTasks: DashboardMetrics[];
    realtimeCompletedTasks: DashboardMetrics[];
    yesterdayCollectionRate: DashboardMetrics[];
    yesterdayCompletedTasks: DashboardMetrics[];
    yesterdayCancellationTasks: DashboardMetrics[];
  };
};
