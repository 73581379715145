import { createStore, applyMiddleware, compose, Store } from 'redux';
import ReduxThunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
// routing
import { routerMiddleware } from 'connected-react-router';
import History from 'core/History';
import { Window } from 'types/global.d';
import Reducers from './reducers';
import { AppState } from './types';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? composeWithDevTools({ trace: true })
    : compose;

const middleware = [routerMiddleware(History), ReduxThunk, promiseMiddleware()];

const logger = createLogger({
  collapsed: true,
  duration: true,
});

if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger);
}
Reducers.setHistory(History);

const store: Store<AppState> = createStore(
  Reducers.getReducers(),
  composeEnhancers(applyMiddleware(...middleware)),
);

Reducers.setStore(store);

export default store;
