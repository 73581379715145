import React, { ChangeEvent, ReactNode } from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mt: pxToRem(-8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),
);

const LabelContainerStyled = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
);

const IconContainerStyled = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mr: pxToRem(8),
  }),
);

type FilterSwitchProps = {
  group: string;
  label: string;
  value: string;
  checked: boolean;
  icon?: ReactNode;
  onChange: (group: string, value: string, checked: boolean) => void;
};

export const FilterSwitch = (props: FilterSwitchProps) => {
  const { group, label, value, checked, icon, onChange } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(group, value, event.target.checked);
  };

  return (
    <RootStyle>
      <LabelContainerStyled>
        {!!icon && <IconContainerStyled>{icon}</IconContainerStyled>}
        <Typography variant="body2">{label}</Typography>
      </LabelContainerStyled>
      <Switch checked={checked} onChange={handleChange} />
    </RootStyle>
  );
};
