import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { UNKNOWN_PAGE, PAGE_VISIT_EVENT } from 'constants/amplitude';
import { APP_NAME, PAGE_NOT_FOUND } from 'constants/app';
import { flattenedRoutesList } from 'routes/routesConfig';
import { logAction } from 'lib/amplitude';
import { Route } from 'types/global.d';

type Props = {
  children: ReactNode;
  title?: string;
};

const Page = ({ children, title }: Props) => {
  const { pathname } = useLocation();
  const sectionData: Route | undefined = flattenedRoutesList.find(
    (route) => route.path === pathname,
  );
  let sectionTitle = title || PAGE_NOT_FOUND;
  let amplitudePage = UNKNOWN_PAGE;
  let amplitudeEvent = PAGE_VISIT_EVENT;
  let amplitudeProperties: any = {
    path: pathname,
  };

  if (sectionData) {
    const { label: pageName, amplitudeEvent: ampEvent } = sectionData;
    sectionTitle = pageName;
    amplitudePage = pageName;
    amplitudeEvent = ampEvent;
    amplitudeProperties = {};
  }

  logAction(amplitudePage, amplitudeEvent, amplitudeProperties);

  return (
    <>
      <Helmet>
        <title>
          {sectionTitle} | {APP_NAME}
        </title>
      </Helmet>
      {children}
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
