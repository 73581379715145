export const COUNTRY_CODE = 'country_code';
export const CURRENT_LP_REGION = 'current_lp_region';
export const EARNINGS_FILTERS = 'earnings_filters';
export const EMPLOYEE_MANAGEMENT_FILTERS = 'employee_management_filters';
export const IS_IMPERSONATING = 'is_impersonating';
export const LAST_REQUEST_ID = 'last_request_id';
export const LIVE_MAP_CENTER = 'map_center';
export const LIVE_MAP_BOUNDS = 'map_bounds';
export const LIVE_MAP_FILTERS = 'live_map_filters';
export const LIVE_MAP_SHOW_FILTERS = 'map_show_filters';
export const LIVE_MAP_ZOOM = 'map_zoom';
export const LOGIN_PHASE = 'login_phase';
export const LP_ID = 'lp_id';
export const LP_TOKEN = 'lp_token';
export const LP_PROFILE = 'lp_profile';
export const LP_PHONE = 'lp_phone';
export const LP_REGIONS = 'lp_regions';
export const MY_TASKS_FILTERS = 'my_tasks_filters';
export const PERFORMANCE_FILTERS = 'performance_filters';
export const SELECTED_TIMEZONE = 'timezone';
export const SETTINGS_FILTERS = 'settings_filters';
