import React, { useState, useEffect } from 'react';
import { cloneDeep, find, isEqual, omit, uniqWith } from 'lodash';
import { MenuItem, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import FlagSelector from 'components/FlagSelector';
import { ALL_REGIONS } from 'constants/common';
import { Region, Regions, RegionAttributes } from 'types/lp.d';
import { pxToRem } from 'components/theme/typography';

const RegionSelectorStyle = styled(Select)(({ theme }) =>
  theme.unstable_sx({
    p: 0,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.common.black,
    height: pxToRem(44),
    width: '100%',
    display: { xs: 'flex', lg: 'inline-flex' },
    flexGrow: { xs: 1, md: 0 },
  }),
);

const MenuItemStyle = styled(MenuItem)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'visible',
    fontSize: 14,
  }),
);

const styles = (theme) => ({
  icon: {
    paddingleft: pxToRem(8),
    top: 0,
    height: pxToRem(44),
    color: theme.palette.common.black,
  },
});

type RegionSelectorProps = {
  flagCountryCode?: string;
  label?: string;
  value: string;
  options: Regions;
  showRegionsOnly?: boolean;
  showAllRegionsOption?: boolean;
  onChange: (regionData?: Region) => void;
};

export const RegionSelector = (props: RegionSelectorProps) => {
  const classes = styles(useTheme());
  const {
    flagCountryCode,
    label,
    value,
    options,
    showRegionsOnly,
    showAllRegionsOption,
    onChange,
  } = props;
  const [regions, setRegions] = useState<Regions>([]);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const allRegionsOption = {
    regionName: ALL_REGIONS.name,
    regionToken: ALL_REGIONS.token,
    attributes: {} as RegionAttributes,
  };

  const updateRegion = (newRegionToken: string, regionsList: Regions) => {
    const regionData =
      newRegionToken === ALL_REGIONS.token
        ? allRegionsOption
        : regionsList.find((region) => region.regionToken === newRegionToken);

    if (regionData) {
      onChange(regionData);
    }
  };

  const handleRegionChange = (event: SelectChangeEvent) => {
    const newRegionToken = event.target.value as string;
    setSelectedRegion(newRegionToken);
    updateRegion(newRegionToken, regions);
  };

  useEffect(() => {
    // Defines the Region values to use in the dropdown depending if it is required to show
    // only Regions (Parent) or to show Regions and Sub-regions.
    const newRegions: Regions = uniqWith(
      options.map((region) => {
        const {
          attributes: { parentRegion },
        } = region;
        const regionData: Region = cloneDeep(region);

        // If it is required to show only Regions and the current record is a Sub-region, then
        // update the Sub-region values with its corresponding Parent Region values.
        if (showRegionsOnly && parentRegion) {
          regionData.regionToken = parentRegion.id;
          regionData.regionName = parentRegion.name;
          regionData.attributes = omit(parentRegion, ['id']); // Remove the 'id' prop to avoid a type mismatching.
        }

        return regionData;
      }),
      isEqual,
    );

    if (showAllRegionsOption) {
      newRegions.unshift(allRegionsOption);
    }

    // Update the Region values to show in the dropdown.
    setRegions(newRegions);

    // Look for the corresponding element (into the LP Regions) where the selected option is located.
    // This is because the selected option could be a Sub-region but the dropdown should shows only Parent Regions.
    // Also, it could be that previously it was selected a Parent Regions (which belongs to a Sub-region) and now
    // it is required to show Regions/Sub-regions.
    const currentRegion =
      find(newRegions, (region) => {
        const {
          attributes: { parentRegion },
          regionToken,
        } = region;
        return regionToken === value || parentRegion?.id === value;
      }) || newRegions[0];

    // Define the new value to show on the Region Selector.
    const newValue: string = currentRegion?.regionToken || '';

    // Update the value to show.
    if (currentRegion?.attributes) {
      const {
        attributes: { parentRegion },
        regionToken = '',
      } = currentRegion;
      let currentRegionToken: string = regionToken;

      if (showRegionsOnly && parentRegion) {
        currentRegionToken = parentRegion.id || '';
      }

      setSelectedRegion(currentRegionToken);
      updateRegion(currentRegionToken, newRegions);
    } else {
      setSelectedRegion(newValue);
    }
  }, [options, showRegionsOnly, value]);

  return (
    <>
      {!!flagCountryCode && <FlagSelector countryCode={flagCountryCode} />}
      {!!label && <InputLabel id="region-label">{label}</InputLabel>}
      <RegionSelectorStyle
        label={label}
        labelId="region-label"
        size="small"
        value={selectedRegion}
        inputProps={classes.icon}
        onChange={handleRegionChange}
      >
        {!regions.length && (
          <MenuItemStyle key="region" value="region">
            Region
          </MenuItemStyle>
        )}
        {regions.map((region) => {
          const {
            attributes: { parentRegion },
            regionName,
            regionToken,
          } = region;
          const regionLabel = parentRegion?.name
            ? `${regionName} (${parentRegion.name})`
            : regionName;
          return (
            <MenuItemStyle key={regionToken} value={regionToken}>
              {regionLabel}
            </MenuItemStyle>
          );
        })}
      </RegionSelectorStyle>
    </>
  );
};
