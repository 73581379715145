import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import { TIMEZONE_CHANGE_EVENT } from 'constants/amplitude';
import { REGEX_UNDERSCORE } from 'constants/filterOptions';
import { SELECTED_TIMEZONE } from 'constants/localStorage';
import { logAction } from 'lib/amplitude';
import { updateTimezone } from 'reduxStore/actions/session';
import { flattenedRoutesList } from 'routes/routesConfig';
import { Route } from 'types/global.d';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pl: { xs: 0, sm: pxToRem(8) },
    display: 'flex',
    alignItems: 'center',
    flexGrow: { xs: 1, md: 'unset' },
  }),
);

const TimezoneSelectorStyle = styled(Select)(({ theme }) =>
  theme.unstable_sx({
    height: pxToRem(44),
    minWidth: { xs: 'unset', md: pxToRem(264) },
    flexGrow: { xs: 1, md: 'unset' },
  }),
);

const MenuItemStyle = styled(MenuItem)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'visible',
    fontSize: 14,
  }),
);

const styles = (theme) => ({
  icon: {
    paddingleft: 8,
    top: 0,
    height: 44,
    color: theme.palette.common.black,
  },
});

type TimezoneSelectorProps = {
  regionTz: string;
  dispatchUpdateTimezone: (timezone) => void;
};

const TimezoneSelector = (props: TimezoneSelectorProps) => {
  const classes = styles(useTheme());
  const { pathname } = useLocation();
  const { regionTz, dispatchUpdateTimezone } = props;
  const userTimezone = moment.tz.guess();
  const storedTimezone = localStorage.getItem(SELECTED_TIMEZONE);
  const initialTimezone = storedTimezone || userTimezone || regionTz;
  const timezonesList = moment.tz.names();
  const [selectedTimezone, setSelectedTimeZone] =
    useState<string>(initialTimezone);
  localStorage.setItem(SELECTED_TIMEZONE, initialTimezone);

  const handleSelectTimezone = (event: SelectChangeEvent) => {
    const newTimezone = event.target.value as string;
    const sectionData: Route | undefined = flattenedRoutesList.find(
      (route) => route.path === pathname,
    );

    setSelectedTimeZone(newTimezone);
    dispatchUpdateTimezone(newTimezone);

    if (sectionData) {
      const { label: pageName } = sectionData;

      // Log the action into Amplitude.
      logAction(pageName, TIMEZONE_CHANGE_EVENT, {
        timezone: newTimezone,
      });
    }
  };

  return (
    <RootStyle>
      <TimezoneSelectorStyle
        value={selectedTimezone}
        onChange={handleSelectTimezone}
        inputProps={classes.icon}
      >
        {timezonesList.map((tzName) => (
          <MenuItemStyle key={tzName} value={tzName}>
            {tzName.replace(REGEX_UNDERSCORE, ' ')}
          </MenuItemStyle>
        ))}
      </TimezoneSelectorStyle>
    </RootStyle>
  );
};

const mapStateToProps = ({ session }) => {
  const {
    lpRegion: {
      attributes: { timeZone: regionTz },
    },
  } = session;

  return {
    regionTz,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateTimezone: (timezone) => dispatch(updateTimezone(timezone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneSelector);
