import _ from 'lodash';
import { TaskTypes } from 'types/dashboard.d';
import actionTypes from '../actions/actionTypes';

const initialMetrics = () =>
  [
    TaskTypes.Swap,
    TaskTypes.Charge,
    TaskTypes.Move,
    TaskTypes.Deploy,
    TaskTypes.Retrieve,
    TaskTypes.Service,
  ].map((TaskType) => ({
    TaskType,
    Total: undefined,
    Scooter: 0,
    Electric: 0,
  }));
const INITIAL_STATE = {
  dataLoading: false,
  loadingStatus: {},
  metrics: {
    realtimeAvailableMetrics: initialMetrics(),
    realtimeCollectedTasks: initialMetrics(),
    realtimeCompletedTasks: initialMetrics(),
    yesterdayCollectionRate: initialMetrics(),
    yesterdayCompletedTasks: initialMetrics(),
    yesterdayCancellationTasks: initialMetrics(),
  },
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  const newMetrics = { ...state.metrics };
  const loadingStatus = {
    ...state.loadingStatus,
  };
  switch (action.type) {
    case actionTypes.DASHBOARD_RESET_DATA:
      return {
        ...state,
        metrics: {
          realtimeAvailableMetrics: initialMetrics(),
          realtimeCollectedTasks: initialMetrics(),
          realtimeCompletedTasks: initialMetrics(),
          yesterdayCollectionRate: initialMetrics(),
          yesterdayCompletedTasks: initialMetrics(),
          yesterdayCancellationTasks: initialMetrics(),
        },
      };
    case actionTypes.DASHBOARD_UPDATE_DATA:
      newMetrics[action.payload.metric.metricName] = action.payload.data;
      // TODO: [Chao] Refactor code below to make it more readable
      if (
        _.startsWith(
          action.payload.metric.metricName,
          'yesterdayCollectionRate',
        )
      ) {
        const yesterdayCollectionRate = [...newMetrics.yesterdayCollectionRate];
        _.forEach(yesterdayCollectionRate, (collectionRate) => {
          collectionRate[action.payload.metric.vehicleType] =
            action.payload.data[collectionRate.TaskType];
        });
        newMetrics.yesterdayCollectionRate = yesterdayCollectionRate;
      }
      return {
        ...state,
        metrics: newMetrics,
      };
    case actionTypes.DASHBOARD_INIT_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.payload,
        dataLoading: true,
      };
    case actionTypes.DASHBOARD_UPDATE_LOADING_STATUS:
      loadingStatus[action.payload.metricName] = false;
      return {
        ...state,
        loadingStatus,
        dataLoading: _.some(loadingStatus),
      };

    default:
      return state;
  }
};

export default dashboardReducer;
