import { Column, ORDER_VALUES } from 'types/table.d';

export const SWAP_TASK = 'BatterySwapTask';
export const CHARGE_TASK = 'ScooterChargeTask';
export const RETRIEVAL_TASK = 'VehicleRetrievalTask';
export const REBALANCE_TASK = 'VehicleRebalanceTask';
export const MOVE_TASK = 'VehicleMoveTask';
export const DEPLOY_TASK = 'VehicleDeployTask';
export const SERVICE_TASK = 'OnsiteRepairTask';

export const FIRST_TIME_FILTERING_LABEL = 'Click here to Search';
export const NO_RESULTS = 'No results were found!';
export const DEFAULT_BATTERY_LEVEL_RANGE = [0, 100];
export const TASKS_DATE_TIME_FORMAT = 'MMM DD HH:mm:ss z';

export const TASKS_TAB_OPTIONS = [
  {
    id: 'active',
    label: `Collected Tasks`,
  },
  {
    id: 'completed',
    label: `Completed Tasks`,
  },
  {
    id: 'uncompleted',
    label: `Cancelled Tasks`,
  },
];

export const COLUMN_SORTING_MAP = {
  assignedAt: 'assigned_at',
  batteryLevel: 'bikes.battery_percentage',
  completedAt: 'ended_at',
  priority: 'priority_numeric',
  plateNumber: 'bikes.plate_number',
  startedAt: 'started_at',
  vehicleType: 'bikes.type_name',
};

export const COLLECTED_TASKS_COLUMNS: Column[] = [
  {
    addAvatar: false,
    id: 'assignee',
    label: 'Assignee',
    width: 340,
  },
  {
    id: 'assignedAt',
    label: 'Collected At',
    initialOrder: ORDER_VALUES.Desc,
    sortable: true,
  },
  {
    id: 'taskType',
    label: 'Task Type',
  },
  {
    id: 'vehicleType',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Vehicle Type',
    sortable: true,
  },
  {
    id: 'priority',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Priority',
    sortable: true,
  },
  {
    id: 'collectedBatteryLevel',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Battery (Collected)',
    sortable: true,
  },
  {
    id: 'plateNumber',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Plate No.',
    sortable: true,
  },
  {
    id: 'pricing',
    label: 'Pricing',
  },
];

export const COMPLETED_TASKS_COLUMNS: Column[] = [
  {
    addAvatar: false,
    id: 'assignee',
    label: 'Assignee',
  },
  {
    id: 'completedAt',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Completed At',
    sortable: true,
  },
  {
    id: 'taskType',
    label: 'Task Type',
  },
  {
    id: 'vehicleType',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Vehicle Type',
    sortable: true,
  },
  {
    id: 'priority',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Priority',
    sortable: true,
  },
  {
    id: 'collectedBatteryLevel',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Battery (Collected)',
    sortable: true,
  },
  {
    id: 'deployedBatteryLevel',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Battery (Deployed)',
    sortable: true,
  },
  {
    id: 'plateNumber',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Plate No.',
    sortable: true,
  },
  {
    id: 'pricing',
    label: 'Pricing',
  },
];

export const CANCELLED_TASKS_COLUMNS: Column[] = [
  {
    addAvatar: false,
    id: 'assignee',
    label: 'Assignee',
  },
  {
    id: 'taskType',
    label: 'Task Type',
  },
  {
    id: 'vehicleType',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Vehicle Type',
    sortable: true,
  },
  {
    id: 'plateNumber',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Plate No.',
    sortable: true,
  },
  {
    id: 'createdAt',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Created At',
    sortable: true,
  },
  {
    id: 'startedAt',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Started At',
    sortable: true,
  },
  {
    id: 'completedAt',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Ended At',
    sortable: true,
  },
];
