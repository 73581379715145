import _ from 'lodash';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { pxToRem } from 'components/theme/typography';
import {
  ASSETS_PAGE,
  ASSETS_PATH,
  DASHBOARD_PATH,
  EARNINGS_PAGE,
  EARNINGS_PATH,
  EMPLOYEE_MANAGEMENT_PAGE,
  EMPLOYEE_MANAGEMENT_PATH,
  LIVE_MAP_PAGE,
  LIVE_MAP_PATH,
  MY_TASKS_PAGE,
  MY_TASKS_PATH,
  // TODO: (Fernando) Uncomment when "My Team" page be released.
  // MY_TEAM_PAGE,
  // MY_TEAM_PATH,
  PERFORMANCE_PAGE,
  PERFORMANCE_PATH,
  PROFILE_PAGE,
  PROFILE_PATH,
  SETTINGS_PAGE,
  SETTINGS_PATH,
  TASK_FORECAST_PAGE,
  TASK_FORECAST_PATH,
} from 'constants/amplitude';
import { BASE_PATH } from 'constants/app';
import { MenuSection } from 'types/global.d';

export const useStyles = makeStyles((theme: any) => ({
  newRelease: {
    color: theme.palette.error.main,
    fontSize: pxToRem(24),
  },
}));

export const SectionGroupStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    px: pxToRem(16),
    pb: { xs: 0, lg: pxToRem(8) },
    display: 'block',
    color: theme.palette.grey[800],
    width: '100%',
  }),
);

// Temporary way to guard features in LP-Dashboard
// TODO: Remove once we give LP-Dashboard access to AT Feature Gates.
export const MENU_TO_ALLOWED_REGIONS_MAP = {
  assets: [
    'CC34XDCRHJJWU', // Seattle
    'JEU7G7JKIWDYJ', // Lake Tahoe
    'ZYJJ72EMTLKEL', // Los Angeles
    'OAEJZJIRDYIDT', // Oakland
    'OZTYDMBICM2JH', // Phoenix
    'KKBQJNGHUHDIU', // Portland
    'GOCS44OC3ZMWO', // Reno
    'LD5RY5PKX6XAZ', // Sacramento
    'GYVVJSK7AYIDO', // San Diego
    '2VNTGUEIGEPRA', // San Francisco
    'BVSBF6SAI7CDU', // San Jose
    'FF5QETKEO3SZ7', // Santa Barbara
    'EBXJVWI4KFX7N', // Scottsdale
    'E6U2NZJR7I3DG', // Spokane
    'FZXTS72CKHEXD', // Tacoma
    'LWALDAJ3AZETI', // Denver
    'FLEM2K2JY2CBP', // Washington DC
    'L6NMOZ57PFSJS', // Nashville
    'HNM6JZSYCOHA2', // Kelowna
    '32NCII4B4VAHY', // Omaha
    'EVVYGCZGQ2V6O', // Boise
    'WJ6WPD4YIBJ3N', // North Shore, BC (aka Vancouver)
    'GBCBZA76TXEJS', // London
  ],
};

export const MENU_SECTIONS: MenuSection[] = [
  {
    id: 'General',
    items: [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'kanban',
        link: `${BASE_PATH}${DASHBOARD_PATH}`,
      },
      {
        id: _.camelCase(TASK_FORECAST_PAGE),
        label: TASK_FORECAST_PAGE,
        icon: 'analytics',
        link: `${BASE_PATH}${TASK_FORECAST_PATH}`,
      },
      {
        id: _.camelCase(PERFORMANCE_PAGE),
        label: PERFORMANCE_PAGE,
        icon: 'trophy',
        link: `${BASE_PATH}${PERFORMANCE_PATH}`,
      },
      {
        id: _.camelCase(EARNINGS_PAGE),
        label: EARNINGS_PAGE,
        icon: 'earnings',
        link: `${BASE_PATH}${EARNINGS_PATH}`,
      },
      {
        id: _.camelCase(LIVE_MAP_PAGE),
        label: LIVE_MAP_PAGE,
        icon: 'map',
        link: `${BASE_PATH}${LIVE_MAP_PATH}`,
      },
      {
        id: _.camelCase(MY_TASKS_PAGE),
        label: MY_TASKS_PAGE,
        icon: 'booking',
        link: `${BASE_PATH}${MY_TASKS_PATH}`,
      },
      {
        id: _.camelCase(ASSETS_PAGE),
        label: ASSETS_PAGE,
        icon: 'scooter',
        link: `${BASE_PATH}${ASSETS_PATH}`,
        newRelease: true,
      },
      {
        id: _.camelCase(EMPLOYEE_MANAGEMENT_PAGE),
        label: EMPLOYEE_MANAGEMENT_PAGE,
        icon: 'employeeManagement',
        link: `${BASE_PATH}${EMPLOYEE_MANAGEMENT_PATH}`,
      },
      // TODO: (Fernando) Will be restored in a future release.
      // {
      //   id: _.camelCase(MY_TEAM_PAGE),
      //   label: MY_TEAM_PAGE,
      //   icon: 'team',
      //   link: `${BASE_PATH}${MY_TEAM_PATH}`,
      // },
    ],
  },
  // TODO: (Fernando) Will be restored in a future release.
  {
    id: 'Management',
    items: [
      {
        id: _.camelCase(PROFILE_PAGE),
        label: PROFILE_PAGE,
        icon: 'user',
        link: `${BASE_PATH}${PROFILE_PATH}`,
      },
      {
        id: _.camelCase(SETTINGS_PAGE),
        label: SETTINGS_PAGE,
        icon: 'settings',
        link: `${BASE_PATH}${SETTINGS_PATH}`,
      },
    ],
  },
];
