import React, { useState } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
  }),
);

const RangeSliderStyle = styled(Slider)(({ theme }) =>
  theme.unstable_sx({
    mx: pxToRem(16),
    mt: pxToRem(32),
    mb: pxToRem(24),
    width: `calc(100% - ${pxToRem(32)})`,
  }),
);

type RangeSliderProps = {
  label?: string;
  levelRange: number[];
  valueLabelDisplay?: 'on' | 'off';
  onChange: (range: number[]) => void;
};

export const RangeSlider = (props: RangeSliderProps) => {
  const { label, levelRange, valueLabelDisplay, onChange } = props;
  const [range, setRange] = useState<number[]>(levelRange);

  const handleChange = (event: Event, value: number[]) => {
    setRange(value);
    onChange(range);
  };

  return (
    <RootStyle>
      {!!label && <Typography variant="subtitle1">{label}</Typography>}
      <RangeSliderStyle
        value={range}
        valueLabelDisplay={valueLabelDisplay}
        onChange={handleChange}
      />
    </RootStyle>
  );
};

RangeSlider.defaultProps = {
  valueLabelDisplay: 'off',
};
