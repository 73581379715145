import { RoutesNested, RoutesFlattened } from 'types/global.d';

export const getFlattenedRoutesHelper = (routesList, subroute, parentPath) => {
  const curPath = `${parentPath}${subroute.path}`;

  if (!subroute) {
    return;
  }

  if (subroute.subsections) {
    subroute.subsections.map((section) =>
      getFlattenedRoutesHelper(routesList, section, curPath),
    );
  }
  const { label, component, permission, message, status } = subroute;
  routesList.push({
    label,
    component,
    permission,
    message,
    path: curPath,
    status,
  });
};

/* Function used to get flattened routes array out of the route object
 * routesObj example in `routes/index.js` file
 */
export const getFlattenedRoutesList = (
  routesObj: RoutesNested,
): RoutesFlattened => {
  const flattenedList = [];
  getFlattenedRoutesHelper(flattenedList, routesObj, '');
  return flattenedList;
};
