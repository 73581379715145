import { combineReducers, Store } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import dashboardReducer from './dashboard';
import employeeManagementReducer from './employeeManagement';
import earningsReducer from './earnings';
import messagesReducer from './messages';
import performanceReducer from './performance';
import profileReducer from './profile';
import sessionReducer from './session';
import settingsReducer from './settings';
import tasksReducer from './tasks';
import taskForecastingReducer from './taskForecasting';
import { AppState } from '../types';
import assetsReducer from './assets';

const reducers = {
  assets: assetsReducer,
  dashboard: dashboardReducer,
  earnings: earningsReducer,
  employeeManagement: employeeManagementReducer,
  form: formReducer,
  messages: messagesReducer,
  performance: performanceReducer,
  profile: profileReducer,
  session: sessionReducer,
  settings: settingsReducer,
  taskForecasting: taskForecastingReducer,
  tasks: tasksReducer,
};

let theStore: Store<AppState>;
let theHistory: History;

const Reducers = {
  setHistory: (history): void => {
    theHistory = history;
  },
  // TODO: nested reducers
  getReducers: () => connectRouter(theHistory)(combineReducers(reducers)),

  setStore: (store: Store<AppState>): void => {
    theStore = store;
  },
  addReducer: (route, reducer): void => {
    if (
      Object.hasOwnProperty.call(reducers, route) &&
      reducers[route] !== reducer
    ) {
      throw new Error('Reducers can not be overwritten');
    }
    reducers[route] = reducer;
    if (theStore) {
      // this should only not enter on testing
      theStore.replaceReducer(Reducers.getReducers());
    }
  },
  getReducer: (route) => reducers[route],
};

export default Reducers;
