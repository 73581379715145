import { CheckboxType, SelectFilterType } from 'types/filtering.d';
import { Region } from 'types/lp.d';
import { Order } from 'types/table.d';

export type OperatorFiltersType = {
  assigneeName: SelectFilterType[];
  priority: SelectFilterType[];
  taskType: SelectFilterType[];
  vehicleType: SelectFilterType[];
};

export type FiltersType = {
  assigneeName: string[];
  priority: string[];
  taskType: string[];
  vehicleType: string[];
};

export type MyTasksFiltersType = {
  assigneeName: string;
  batteryLevelRange: number[];
  completedAtStartTime: string;
  completedAtEndTime: string;
  orderDirection?: Order;
  orderKey?: string;
  plateNumber: string;
  priority: string;
  regionName: string;
  regionToken: string;
  taskType: string[];
  vehicleType: string[];
};

export type TasksTotals = {
  active: number;
  completed: number;
  uncompleted: number;
};

export type AssigneeType = {
  id: string;
  type: string;
  attributes: {
    country: string;
    emailAddress: string;
    firstName: string;
    fleetGroupRelationship: string;
    fullName: string;
    lastName: string;
    phoneNumber: string;
    regions: Region[];
    status: string;
    token: string;
    zipCode: string;
  };
};

export type VehicleType = {
  id: string;
  type: string;
  attributes: {
    batteryPercentage: number;
    lastActivityAt: Date;
    latitude: number;
    longitude: number;
    plateNumber: string;
    typeName: string;
  };
};

export type TaskDetailsPricingType = {
  id: string;
  type: string;
  attributes: {
    accuracy: string;
    actualEarnings: string;
    paidAt: Date;
    paymentStatus: string;
    potentialEarnings: string;
  };
};

export type TasksDataType = {
  id: string;
  type: string;
  attributes: {
    assignedAt: Date;
    assignee: AssigneeType;
    assigneType: string;
    bike: VehicleType;
    createdAt: Date;
    delegateeId: string;
    durationSeconds: number;
    startedAt: Date;
    endedAt: Date;
    priority: string;
    status: string;
    statusChangedAt: Date;
    taskDetailsPricing: TaskDetailsPricingType;
    taskId: string;
    type: string;
  };
};

export type CsvParams = {
  fromTs: number;
  toTs: number;
  columns: string[];
  additionalParams: any;
  reportName: string;
  pageName: string;
};

export type CsvColumn = {
  id: string;
  displayName: string;
};

export type MapFilters = {
  plateNumber: string;
  taskType: CheckboxType[];
  vehicleType: CheckboxType[];
  batteryLevelMin: number;
  batteryLevelMax: number;
  mapOptions: CheckboxType[];
};

export type MapDataResult = {
  icon?: string;
  label: string;
  amount: number;
};

export type DateSelector = string | number | Date | null | undefined | any;

export const enum DATE_RANGE_TYPES {
  start = 'start',
  end = 'end',
}

export type DATE_RANGE_OPTIONS = DATE_RANGE_TYPES;

export const enum DATE_RANGE_ERRORS {
  invalidDate = 'invalidDate',
  invalidRange = 'invalidRange',
  shouldDisableDate = 'shouldDisableDate',
  disableFuture = 'disableFuture',
  disablePast = 'disablePast',
  minDate = 'minDate',
  maxDate = 'maxDate',
}

export const enum DATE_TIME_ERRORS {
  disableFuture = 'disableFuture',
  disablePast = 'disablePast',
  invalidDate = 'invalidDate',
  maxDate = 'maxDate',
  minDate = 'minDate',
  shouldDisableDate = 'shouldDisableDate',
}

export type DateTimeValidator = DATE_TIME_ERRORS | null;
