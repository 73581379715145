import { LOGIN_PAGE, LOGIN_CLIENT_AUTH_EVENT } from 'constants/amplitude';
import { DEFAULT_TIMEZONE } from 'constants/filterOptions';
import {
  COUNTRY_CODE,
  CURRENT_LP_REGION,
  IS_IMPERSONATING,
  LOGIN_PHASE,
  LP_ID,
  LP_PROFILE,
  LP_REGIONS,
  LP_TOKEN,
  LIVE_MAP_ZOOM,
  SELECTED_TIMEZONE,
  LIVE_MAP_FILTERS,
  MY_TASKS_FILTERS,
  LIVE_MAP_SHOW_FILTERS,
} from 'constants/localStorage';
import { DEFAULT_MAP_ZOOM } from 'constants/maps';
import { logAction } from 'lib/amplitude';
import { setToken } from 'lib/api';
import { MapFilters } from 'types/tasks.d';

const LOGIN_SECTION = '/login';

export const hasValidAuthDataInLocalStorage = (): boolean => {
  const operatorId = localStorage.getItem(LP_ID);
  const operatorToken = localStorage.getItem(LP_TOKEN);
  return !!operatorId && !!operatorToken;
};

/**
 * Removes any data stored into the Session Storage and also removes the LP Token
 * stored into the Local Storage.
 */
export const clearStorage = (): void => {
  // Check if the current user was impersonating or not.
  const isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);

  // Get the localStorage values to keep.
  const countryCode: string = localStorage.getItem(COUNTRY_CODE) || 'US';
  const timezone: string =
    localStorage.getItem(SELECTED_TIMEZONE) || DEFAULT_TIMEZONE;
  const mapZoom: string =
    localStorage.getItem(LIVE_MAP_ZOOM) || DEFAULT_MAP_ZOOM.toString();
  const liveMapFilters: MapFilters = JSON.parse(
    localStorage.getItem(LIVE_MAP_FILTERS) || '{}',
  );
  const showLiveMapFilters = localStorage.getItem(LIVE_MAP_SHOW_FILTERS) || '1';
  const myTasksFilters = JSON.parse(
    localStorage.getItem(MY_TASKS_FILTERS) || '{}',
  );

  // Reset some properties from "My Tasks" filters in case the user signs in with a different LP Token.
  delete myTasksFilters.assigneeName;
  myTasksFilters.plateNumber = '';
  myTasksFilters.regionName = '';
  myTasksFilters.regionToken = '';

  // Destroy all data from the local storage.
  localStorage.clear();

  // Clear the TOKEN from the API lib.
  setToken('');

  // Destroy the Login Phase.
  localStorage.removeItem(LOGIN_PHASE);

  // Restore the localStorage values kept.
  localStorage.setItem(COUNTRY_CODE, countryCode);
  localStorage.setItem(LIVE_MAP_ZOOM, mapZoom);
  localStorage.setItem(SELECTED_TIMEZONE, timezone);
  localStorage.setItem(LIVE_MAP_SHOW_FILTERS, showLiveMapFilters);

  // If the user was impersonating, restore that value into the local storage.
  if (isImpersonating) {
    localStorage.setItem(IS_IMPERSONATING, isImpersonating.toString());
  }

  // If the user already set some filters for the Live Map page, restore that value into the local storage.
  if (liveMapFilters) {
    localStorage.setItem(LIVE_MAP_FILTERS, JSON.stringify(liveMapFilters));
  }

  // If the user already set some filters for the My Tasks page, restore that value into the local storage.
  if (myTasksFilters) {
    localStorage.setItem(MY_TASKS_FILTERS, JSON.stringify(myTasksFilters));
  }
};

export const logout = (): void => {
  clearStorage();

  const isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
  const webbAppUrl = window.location.origin;
  const impersonatePage = isImpersonating ? `/impersonate` : '';

  const currentURL = new URL(window.location.href);
  if (!currentURL.pathname.includes(LOGIN_SECTION)) {
    window.location.href = `${webbAppUrl}${LOGIN_SECTION}${impersonatePage}`;
  }
};

export const authenticate = (): boolean => {
  if (!hasValidAuthDataInLocalStorage()) {
    console.error('Auth verification failed');
    logAction(LOGIN_PAGE, `${LOGIN_CLIENT_AUTH_EVENT}: Failed`);
    logout();
    return false;
  }

  // TODO: use exposed authenticate method (validate token per page refresh) to gate the UI?
  return true;
};

export const updateLpAuthData = (token: string, id: string, profile): void => {
  localStorage.setItem(LP_TOKEN, token);
  localStorage.setItem(LP_ID, id);
  localStorage.setItem(LP_PROFILE, profile);
};

export const updateLpRegionAuthData = (regions): void => {
  localStorage.setItem(LP_REGIONS, JSON.stringify(regions));
  localStorage.setItem(CURRENT_LP_REGION, JSON.stringify(regions[0]));
};
