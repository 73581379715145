// csv: csv format data, including header row
// fileName: depend on the data, could be for tasks or earnings?

export const downloadCsvFile = (csv: string, fileName: string): void => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const hiddenElement = document.createElement('a');
  hiddenElement.href = url;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${fileName}.csv`;
  hiddenElement.click();
  // cleanup object URL
  URL.revokeObjectURL(url);
};
