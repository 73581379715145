import React, { useState } from 'react';
import { cloneDeep, isNull } from 'lodash';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomDateTimePicker from 'components/CustomDateTimePicker';
import { pxToRem } from 'components/theme/typography';
import { DateSelector, DATE_RANGE_TYPES } from 'types/tasks.d';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  }),
);

const TitleStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    pb: pxToRem(8),
  }),
);

const PickerContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    width: { xs: '100%', md: pxToRem(248) },
  }),
);

const SeparatorStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    m: pxToRem(8),
  }),
);

const getEndDateAfter = (
  date: DateSelector,
  amount: number,
  timePeriod: moment.unitOfTime.Base,
) => {
  if (!date || !amount) {
    return undefined;
  }

  return moment(date).add(amount, timePeriod).toDate();
};

type DateTimeRangePickerProps = {
  disableFuture?: boolean;
  endDateTime: DateSelector;
  endDateTimeLabel?: string;
  endDateLimit?: {
    timeAmount: number;
    timePeriod: moment.unitOfTime.Base;
  };
  hideSeparatorLabel?: boolean;
  separatorLabel?: string;
  startDateTime: DateSelector;
  startDateTimeLabel?: string;
  title?: string;
  onChange: (
    newDateRange: [DateSelector, DateSelector],
    rangeHasError: boolean,
  ) => void;
};

export const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
  const {
    disableFuture = false,
    endDateTime,
    endDateTimeLabel = 'End Date-Time',
    endDateLimit = {
      timeAmount: 0,
      timePeriod: 'days',
    },
    hideSeparatorLabel,
    separatorLabel = 'to',
    startDateTime,
    startDateTimeLabel = 'Start Date-Time',
    title,
    onChange,
  } = props;
  const { timeAmount, timePeriod } = endDateLimit;
  const [dateRange, setDateRange] = useState<[DateSelector, DateSelector]>([
    startDateTime,
    endDateTime,
  ]);
  let maxAllowedDateTime;

  if (timeAmount && timePeriod) {
    const maxAllowedDate = getEndDateAfter(
      dateRange[0],
      timeAmount,
      timePeriod,
    );
    maxAllowedDateTime = maxAllowedDate?.setHours(23, 59, 59);
  }

  const handleChange =
    (isStartDate = true) =>
    (newDateTime: DateSelector, hasError: boolean) => {
      const newDateRange = cloneDeep(dateRange);

      if (isStartDate && !isNull(newDateTime)) {
        newDateRange[0] = newDateTime;
      } else if (!isStartDate && !isNull(newDateTime)) {
        newDateRange[1] = newDateTime;
      }

      setDateRange(newDateRange);
      onChange(newDateRange, hasError);
    };

  return (
    <RootStyle>
      {!!title && <TitleStyle variant="body1">{title}</TitleStyle>}
      <PickerContainer>
        <CustomDateTimePicker
          disableFuture={disableFuture}
          label={startDateTimeLabel}
          onChange={handleChange()}
          rangeType={DATE_RANGE_TYPES.start}
          value={dateRange[0]}
        />
      </PickerContainer>
      {!hideSeparatorLabel && (
        <SeparatorStyle>
          <Typography variant="body2">{separatorLabel}</Typography>
        </SeparatorStyle>
      )}
      <PickerContainer>
        <CustomDateTimePicker
          disableFuture={disableFuture}
          label={endDateTimeLabel}
          maxDateTime={maxAllowedDateTime}
          onChange={handleChange(false)}
          rangeType={DATE_RANGE_TYPES.end}
          value={dateRange[1]}
        />
      </PickerContainer>
    </RootStyle>
  );
};
