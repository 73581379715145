import { Column } from 'types/table.d';

export const INITIAL_FILTERS_FORM = {
  startDate: null,
  endDate: null,
  currentPage: 1,
  rowsPerPage: 50,
};

export const EARNINGS_GRID_COLUMNS_V3: Column[] = [
  {
    id: 'startedDate',
    label: 'Date',
    width: 250,
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'earning',
    label: 'Earnings',
    width: 200,
  },
];

export const SUMMARY_CARDS_MAP = {
  pendingAmount: 'Pending Amount',
  lastPayment: 'Last Payment',
};

export const SUMMARY_CARDS_ICON_MAP = {
  pendingAmount: 'loading',
  lastPayment: 'history',
  totalCompleted: 'location',
  totalEarnings: 'income',
};

// Maps from payment status string provided by backend
// (defined here https://github.com/limebike/limebike-web/blob/c7e7bf8b4053c24058ced5bcf6fd88fa60f7efb4/app/models/transfer_record.rb#L63-L71 )
// to human readable payment status for display
export const EARNINGS_PAYMENT_STATUSES_MAP = {
  created: 'Pending', // see https://github.com/limebike/ios/blob/b6ee2f2b35e36186a5fcc226903469cf9a487c96/Apps/Supply/OneApp/Earnings/V2/TaskDetail/Models/EarningsTaskDetail.swift#L18
  pending: 'Pending',
  transfer_paid: 'Paid',
  transfer_reversed: 'Reversed',
  transfer_failed: 'Failed',
  mailed: 'Mailed',
  to_be_paid_externally: 'To Be Paid Externally',
  blocked: 'Blocked',
};
