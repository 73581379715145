import api from 'lib/api';

type LocationData = {
  countryName: string;
  countryCode: string;
};

const browserLocation = async (): Promise<LocationData> => {
  const locationData: LocationData = {
    countryName: 'United States',
    countryCode: 'US',
  };

  try {
    const response = (await api.get('https://ipapi.co/json/', {})).data;
    locationData.countryName = response.countryName;
    locationData.countryCode = response.countryCode;
  } catch (error) {
    console.error(`Fail to get Country data from https://ipapi.co: ${error}`);
    try {
      const response = (
        await api.get('https://extreme-ip-lookup.com/json/', {})
      ).data;
      locationData.countryName = response.country;
      locationData.countryCode = response.countryCode;
    } catch (err) {
      console.error(
        `Fail to get Country data from https://extreme-ip-lookup.com: ${error}`,
      );
    }
  }
  return locationData;
};

export const flagsUrl =
  'https://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg';

export default browserLocation;
