import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import AppRoot from 'core/AppRoot';
import Store from './reduxStore/Store';

import 'react-phone-number-input/style.css';
import 'simplebar/src/simplebar.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Buffer } from 'buffer';

window.Buffer = Buffer;

/**
 * Users attempting to translate LP-dashboard with browser's built in translator
 * can cause pages to crash. The issue likely stems from Chrome, but FB has provided
 * this workaround til Google can fix the issue. This also likely fixes the issue
 * for any other browser plugin that manipulates the DOM
 *
 * Source: https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
const hackyWorkaroundForChromeTranslate = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn(
            'Cannot remove a child from a different parent',
            child,
            this,
          );
        }
        return child;
      }

      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }

      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }
};

hackyWorkaroundForChromeTranslate();

const container = window.document.getElementById('react');
const root = createRoot(container);

root.render(
  <Provider store={Store}>
    <AppRoot />
  </Provider>,
);
