// E.g. FETCH_BIKE_FULFILLED -> FETCH_BIKE
export const getActionTypeBase = (type) => {
  if (type.endsWith('_PENDING')) {
    return type.slice(0, -'_PENDING'.length);
  }
  if (type.endsWith('_FULFILLED')) {
    return type.slice(0, -'_FULFILLED'.length);
  }
  if (type.endsWith('_REJECTED')) {
    return type.slice(0, -'_REJECTED'.length);
  }
  return type;
};

// E.g. FETCH_BIKE_FULFILLED -> fetchBike
export const actionTypeToKey = (type) => {
  let returnType = getActionTypeBase(type);
  returnType = returnType.toLowerCase();
  returnType = returnType.replace(
    /[a-z]_[a-z]/gi,
    (match) => match[0] + match[2].toUpperCase(),
  );
  return returnType;
};

export const createActionTypes = (type) =>
  [type, `${type}_FULFILLED`, `${type}_PENDING`, `${type}_REJECTED`].reduce(
    (obj, actionTypeName) => ({
      ...obj,
      [actionTypeName]: actionTypeName,
    }),
    {},
  );

export const createActionTypesFromArray = (types) =>
  types.reduce(
    (actionTypes, type) => ({ ...actionTypes, ...createActionTypes(type) }),
    {},
  );
