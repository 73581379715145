export const APP_NAME = 'LP Dashboard';
export const APP_VERSION = 'V2.0.0';
export const GIT_REPO = 'https://github.com/limebike/lp-dashboard';
export const BASE_PATH = '';
export const MOBILE_DEVICES =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
export const PAGE_NOT_FOUND = 'Page Not Found';
export const LOADING = 'Loading...';
export const SIDE_MENU_WIDTH = '17.5rem';
export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const DEFAULT_CONTENT_TYPE = 'application/json';
