import { DEFAULT_REGION_NAME, DEFAULT_TIMEZONE } from 'constants/filterOptions';
import { Breakpoint, BREAKPOINTS, BreakpointsValues } from 'types/global.d';
import actionTypes from '../actions/actionTypes';

const getCurrentBreakpoint = (
  windowWidth: number,
  breakpointsValues,
): Breakpoint => {
  if (windowWidth >= breakpointsValues.xl) {
    return BREAKPOINTS.xl;
  }

  if (windowWidth >= breakpointsValues.lg) {
    return BREAKPOINTS.lg;
  }

  if (windowWidth >= breakpointsValues.md) {
    return BREAKPOINTS.md;
  }

  if (windowWidth >= breakpointsValues.sm) {
    return BREAKPOINTS.sm;
  }

  return BREAKPOINTS.xs;
};

const INITIAL_STATE = {
  isSignedIn: null,
  loginPhase: 0,
  lpPhone: '',
  lpToken: '',
  lpRegion: {
    regionName: '',
    regionToken: '',
    attributes: {
      geohash: 'mock',
      name: DEFAULT_REGION_NAME,
      neLatitude: 37.81248,
      neLongitude: -122.35364,
      operational: true,
      swLatitude: 37.68519,
      swLongitude: -122.51656,
      timeZone: DEFAULT_TIMEZONE,
    },
  },
  lpRegions: [],
  lpOperator: {
    id: '',
    name: '',
    attributes: {
      country: 'US',
      regions: [
        {
          id: 'TVQ563GHUTWDH',
          type: 'regions',
          attributes: {
            geohash: 'mock',
            name: 'San Francisco',
            neLatitude: 37.81248,
            neLongitude: -122.35364,
            operational: true,
            swLatitude: 37.68519,
            swLongitude: -122.51656,
          },
        },
      ],
    },
  },
  timezone: DEFAULT_TIMEZONE,
  windowWidth: 0,
  mainContainerHeight: 0,
  currentCreakpoint: BREAKPOINTS.lg,
  breakpointsValues: {} as BreakpointsValues,
};

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN:
    case actionTypes.SESSION_VALIDATE_SESSION:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SESSION_UPDATE_LOGIN_PHASE:
      return {
        ...state,
        loginPhase: action.payload,
      };
    case actionTypes.SESSION_UPDATE_PHONE_NUMBER:
      return {
        ...state,
        lpPhone: action.payload,
      };
    case actionTypes.SESSION_UPDATE_REGION:
      return {
        ...state,
        lpRegion: action.payload,
      };
    case actionTypes.SESSION_UPDATE_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      };
    case actionTypes.SESSION_UPDATE_WINDOW_SIZE: {
      const { breakpointsValues, mainContainerHeight, windowWidth } =
        action.payload;
      return {
        ...state,
        breakpointsValues,
        currentBreakpoint: getCurrentBreakpoint(windowWidth, breakpointsValues),
        mainContainerHeight,
        windowWidth,
      };
    }
    case actionTypes.SESSION_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default sessionReducer;
