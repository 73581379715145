import {
  browserName,
  deviceType,
  fullBrowserVersion,
  isDesktop,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';
import { BrowserInfo, DeviceInfo } from 'types/global.d';

export const getBrowserInfo = (): BrowserInfo => {
  const device: DeviceInfo = {
    osName,
    osVersion,
    type: isDesktop ? 'desktop' : deviceType,
  };

  if (!isDesktop) {
    device.mobile = {
      vendor: mobileVendor,
      model: mobileModel,
    };
  }

  return {
    browser_name: browserName,
    browser_version: fullBrowserVersion,
    browser_size: `${window.screen.width} x ${window.screen.height}`,
    device,
  };
};
