import { FleetGroupResponse } from 'types/profile.d';
import { SettingsAbilities } from 'types/settings.d';

export const INITIAL_FORM_VALUES: FleetGroupResponse = {
  id: '',
  type: '',
  attributes: {
    signupDate: '',
    name: null,
    businessName: null,
    employeesHeadcount: 0,
    daysOfService: 0,
    regionSettings: {
      restrictedToFleetGroupRegionLinks: true,
      assignedRegions: [],
    },
    hyperwalletSettings: {
      hyperwalletAccountType: '',
      hyperwalletLoginUrl: '',
      hyperwalletAccountId: '',
    },
    abilities: [],
    abilitiesV2: {} as SettingsAbilities,
    partnerProfile: {
      id: '',
      emailAddress: '',
      phoneNumber: '',
      juicerId: '',
      riderId: '',
    },
  },
};

// TODO: Confirm later if the commented lines should be kept or removed.
export const ABILITIES_MAP = {
  // betaWhitelist: 'Whitelist for Beta(s)',
  ldBatterySwapWorkflow: 'Whitelist for Battery Swap - Lime Defender (Gen 4)',
  ebikeBatterySwapWorkflow: 'Whitelist for Battery Swap - JUMP E-Bike',
  okaiBatterySwapWorkflow: 'Whitelist for Battery Swap - Lime-S Okai',
  gen4BatterySwapWorkflow: 'Whitelist for Battery Swap - JUMP Defender Turbo',
  retrievalWorkflow: 'Whitelist for Scooter Retrieval/Dropoff (Deprecated)',
  deployWorkflow: 'Whitelist for Scooter Deploy',
  // dogfooder: 'Enable user in Dogfooding',
  ebikeRetrievalWorkflow: 'Whitelist for Ebike Retrieval/Dropoff',
  ebikeDeployWorkflow:
    'Whitelist for Ebike Deploy Task and Viewing Ebike Hotspots',
  onlyViewEbikeHotspots: 'Whitelist for Only View Ebike Hotspots',
  // freeRoll: 'Whitelist for Free Roll without Speed Cap',
  // hideSubPayout: 'Hide Sub Payout',
};
