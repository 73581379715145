import { INITIAL_FORM_VALUES } from 'constants/profile';
import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  fleetGroup: INITIAL_FORM_VALUES,
  loading: false,
};

const performanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.PROFILE_FETCH_FLEET_GROUP:
    case actionTypes.PROFILE_UPDATE_FLEET_GROUP:
      return {
        ...state,
        fleetGroup: action.payload,
      };
    default:
      return state;
  }
};

export default performanceReducer;
