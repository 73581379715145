import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  messageConfig: {
    message: '',
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'center',
    },
    alertSeverity: 'info',
    status: 200,
    requestId: '',
  },
  lastRequestId: '',
};

const messagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_LAST_REQUEST_ID:
      return {
        ...state,
        lastRequestId: action.payload,
      };
    case actionTypes.UPDATE_MESSAGE_CONFIG:
      return {
        ...state,
        messageConfig: {
          ...state.messageConfig,
          ...action.payload,
        },
        lastRequestId: action.payload.requestId || '',
      };
    default:
      return state;
  }
};

export default messagesReducer;
