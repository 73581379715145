import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import LimeLogo from 'assets/img/Logomark.svg';
import { RegionSelector } from 'components/Filtering';
import { pxToRem } from 'components/theme/typography';
import TimezoneSelector from 'components/TimezoneSelector';
import TimezoneCurrentDateTime from 'components/TimezoneCurrentDateTime';
import {
  DASHBOARD_PATH,
  EARNINGS_PATH,
  EMPLOYEE_MANAGEMENT_PATH,
  MY_TASKS_PATH,
  PERFORMANCE_PATH,
  PROFILE_PATH,
  SETTINGS_PATH,
} from 'constants/amplitude';
import { updateRegion } from 'reduxStore/actions/session';
import { Region, Regions } from 'types/lp.d';

const useStyles = makeStyles({
  version: {
    marginTop: pxToRem(8),
    marginLeft: pxToRem(8),
  },
});

const RootNotLoggedStyle = styled(AppBar)(({ theme }) =>
  theme.unstable_sx({
    p: pxToRem(8),
    minHeight: pxToRem(75),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.common.black,
  }),
);

const RootStyle = styled(AppBar)(({ theme }) =>
  theme.unstable_sx({
    py: { xs: pxToRem(16), lg: pxToRem(24) },
    pl: { xs: pxToRem(16), lg: pxToRem(48) },
    pr: pxToRem(40),
    color: theme.palette.grey[800],
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
  }),
);

const LogoContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pr: pxToRem(16),
    display: 'flex',
    cursor: 'pointer',
  }),
);

const LogoStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: pxToRem(40),
    height: pxToRem(40),
  }),
) as typeof Box;

const TitleStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'flex-start',
  }),
);

const RegionContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pr: { md: pxToRem(8), lg: pxToRem(16) },
    display: 'flex',
    alignItems: 'center',
    width: {
      xs: '100%',
      md: pxToRem(288),
      lg: pxToRem(360),
    },
  }),
);

const TimezoneContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pl: 0,
    pt: { sm: pxToRem(8), md: 0 },
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: { lg: 1 },
    alignItems: 'center',
    justifyContent: { lg: 'flex-end' },
    width: { xs: '100%', md: 'auto' },
  }),
);

type HeaderProps = {
  country: string;
  isSignedIn: boolean;
  lpRegion: Region;
  lpRegions: Regions;
  dispatchUpdateRegion: (newRegion) => void;
};

const Header = (props: HeaderProps) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const pagesWithParentRegionOnly = [DASHBOARD_PATH];
  const pagesWithRegionSelectorHidden = [
    EARNINGS_PATH,
    EMPLOYEE_MANAGEMENT_PATH,
    MY_TASKS_PATH,
    PERFORMANCE_PATH,
    PROFILE_PATH,
    SETTINGS_PATH,
  ];
  const { country, isSignedIn, lpRegion, lpRegions, dispatchUpdateRegion } =
    props;
  const [showRegionsOnly, setShowRegionsOnly] = useState<boolean>(false);

  const handleChangeRegion = (regionData: Region) => {
    if (regionData) {
      dispatchUpdateRegion(regionData);
    }
  };

  useEffect(() => {
    setShowRegionsOnly(pagesWithParentRegionOnly.includes(pathname));
  }, [pathname]);

  // Header for Login page.
  if (!isSignedIn) {
    return (
      <RootNotLoggedStyle>
        <LogoContainerStyle>
          <LogoStyle component="img" src={LimeLogo} />
        </LogoContainerStyle>
        <TitleStyle variant="h4">Logistics Partner</TitleStyle>
        <TitleStyle variant="subtitle2" className={classes.version}>
          v2
        </TitleStyle>
      </RootNotLoggedStyle>
    );
  }

  // Header for Logged users.
  return (
    <RootStyle>
      <RegionContainerStyle>
        {!pagesWithRegionSelectorHidden.includes(pathname) && (
          <RegionSelector
            flagCountryCode={country}
            value={lpRegion?.regionToken || ''}
            options={lpRegions}
            showRegionsOnly={showRegionsOnly}
            onChange={handleChangeRegion}
          />
        )}
      </RegionContainerStyle>
      <TimezoneContainerStyle>
        <TimezoneCurrentDateTime />
        <TimezoneSelector />
      </TimezoneContainerStyle>
    </RootStyle>
  );
};

const mapStateToProps = ({ session }) => {
  const { isSignedIn, lpRegion, lpRegions, lpOperator } = session;
  const { country } = lpOperator?.attributes || {};

  return {
    country,
    isSignedIn,
    lpRegion,
    lpRegions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateRegion: (newRegion) => dispatch(updateRegion(newRegion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
