import React from 'react';
import { connect } from 'react-redux';

import { Snackbar, SnackbarOrigin, Alert, AlertColor } from '@mui/material';

import { onUpdateMessageConfig } from 'reduxStore/actions/messages';
import { MessageConfig } from 'types/global.d';

const Message = ({ messageConfig }) => {
  if (messageConfig.alertSeverity !== 'error') {
    return <span data-e2e="alert-message">{messageConfig.message}</span>;
  }

  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        Error {messageConfig.status}:&nbsp;
      </span>
      <span data-e2e="alert-message">{messageConfig.message}</span>
      <span
        style={{
          fontWeight: 'bold',
          fontStyle: 'italic',
        }}
      >
        {messageConfig.requestId && (
          <>&nbsp;Request ID: {messageConfig.requestId}</>
        )}
      </span>
    </>
  );
};

type AlertMessageProps = {
  messageConfig: MessageConfig;
  dispatchOnUpdateMessageConfig: (message: MessageConfig) => void;
};

const AlertMessage = (props: AlertMessageProps) => {
  const { messageConfig, dispatchOnUpdateMessageConfig } = props;
  return (
    <Snackbar
      key={messageConfig.message}
      anchorOrigin={messageConfig.position as SnackbarOrigin}
      open={messageConfig.open}
      data-e2e="alert-container"
    >
      <Alert
        severity={messageConfig.alertSeverity as AlertColor}
        data-e2e="alert-component"
        onClose={() =>
          dispatchOnUpdateMessageConfig({
            ...messageConfig,
            open: false,
          })
        }
      >
        <Message messageConfig={messageConfig} />
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = ({ messages }) => ({
  messageConfig: messages.messageConfig,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchOnUpdateMessageConfig: (message) =>
    dispatch(onUpdateMessageConfig(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
