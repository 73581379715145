import { push } from 'connected-react-router';
import moment from 'moment-timezone';
import {
  LOGIN_PAGE,
  LOGIN_SEND_PIN_CODE_EVENT,
  LOGIN_SUBMIT_PHONE_NUMBER_EVENT,
  LOGIN_SUBMIT_PIN_CODE_EVENT,
  USER_BROWSER_INFO_EVENT,
} from 'constants/amplitude';
import { BASE_PATH, DEFAULT_CONTENT_TYPE } from 'constants/app';
import {
  CURRENT_LP_REGION,
  IS_IMPERSONATING,
  LOGIN_PHASE,
  LP_ID,
  LP_PHONE,
  LP_PROFILE,
  LP_REGIONS,
  LP_TOKEN,
  LIVE_MAP_CENTER,
  LIVE_MAP_BOUNDS,
  SELECTED_TIMEZONE,
} from 'constants/localStorage';
import {
  BEFORE_ENTER_PHONE_NUMBER,
  BEFORE_ENTER_PIN,
  LOGGED_IN,
} from 'constants/loginPhases';
import { DEFAULT_MAP_LAT, DEFAULT_MAP_LNG } from 'constants/maps';
import { logAction } from 'lib/amplitude';
import api from 'lib/api';
import { clearStorage } from 'lib/auth';
import { getBrowserInfo } from 'lib/browserInfo';
import { getMapCenter } from 'lib/maps';
import { getUserRegions } from 'lib/user';
import { ApiError, BreakpointsValues } from 'types/global.d';
import { LoginResponse, Region } from 'types/lp.d';
import { MapboxBounds, MapCoordinates } from 'types/maps.d';
import actionTypes from './actionTypes';
import { handleError, closeAlertMessage } from './messages';
import { updateMapBounds, updateMapCenter } from './tasks';
import '../reducers/session';

/**
 * Calculates the Map Center based on the Region/Sub-region coordinates.
 * @param region
 * @returns MapboxBounds
 */
const fetchRegionMapBounds = (region: Region): MapboxBounds => {
  const {
    attributes: {
      neLatitude = DEFAULT_MAP_LAT,
      neLongitude = DEFAULT_MAP_LNG,
      swLatitude = DEFAULT_MAP_LAT,
      swLongitude = DEFAULT_MAP_LNG,
    },
  } = region;
  return [
    [swLongitude, swLatitude],
    [neLongitude, neLatitude],
  ];
};

/**
 * Calculates the Map Center based on the Region/Sub-region coordinates.
 * @param region
 * @returns MapCoordinates
 */
const fetchRegionMapCenter = (region: Region): MapCoordinates => {
  const {
    attributes: {
      neLatitude = DEFAULT_MAP_LAT,
      neLongitude = DEFAULT_MAP_LNG,
      swLatitude = DEFAULT_MAP_LAT,
      swLongitude = DEFAULT_MAP_LNG,
    },
  } = region;
  return getMapCenter(swLatitude, neLatitude, swLongitude, neLongitude);
};

/**
 * Updates the Timezone based on selected option from the Timezone selector.
 * @param newTimezone
 */
export const updateTimezone = (newTimezone: string) => {
  localStorage.setItem(SELECTED_TIMEZONE, newTimezone);
  return {
    type: actionTypes.SESSION_UPDATE_TIMEZONE,
    payload: newTimezone,
  };
};

/**
 * Updates the user's Session Data.
 * @param sessionData
 */
const updateSessionData = (sessionData) => ({
  type: actionTypes.SESSION_VALIDATE_SESSION,
  payload: sessionData,
});

/**
 * Updates the "Timezone" stored into the Session Storage.
 */
const updateStoredTimezone = () => (dispatch) => {
  const userTimezone = moment.tz.guess();
  const storedTimezone = localStorage.getItem(SELECTED_TIMEZONE);
  const newTimezone = storedTimezone || userTimezone;

  if (storedTimezone !== newTimezone) {
    localStorage.setItem(SELECTED_TIMEZONE, newTimezone);
  }

  // Dispatch the update for the "Timezone" into the Redux Store.
  dispatch(updateTimezone(newTimezone));
};

/**
 * Verifies if the user is logged or not.
 */
export const validateSession = () => (dispatch) => {
  // Check for the Session Data.
  const loginPhase =
    Number(localStorage.getItem(LOGIN_PHASE)) || BEFORE_ENTER_PHONE_NUMBER;
  const lpToken = localStorage.getItem(LP_TOKEN) || '';
  const lpRegion =
    JSON.parse(localStorage.getItem(CURRENT_LP_REGION) || '{}') || {};
  const lpRegions = JSON.parse(localStorage.getItem(LP_REGIONS) || '{}') || {};
  const lpOperator = JSON.parse(localStorage.getItem(LP_PROFILE) || '{}') || {};
  const sessionData = {
    isSignedIn: loginPhase === LOGGED_IN,
    lpToken,
    lpRegion,
    lpRegions,
    lpOperator,
  };

  // Dispatch the update for the Session values into the Store.
  dispatch(updateSessionData(sessionData));

  if (sessionData.isSignedIn) {
    // Check for the Map Bounds.
    const regionBounds = fetchRegionMapBounds(lpRegion);
    const storedBounds = JSON.parse(
      localStorage.getItem(LIVE_MAP_BOUNDS) as string,
    );
    const newBounds = storedBounds || regionBounds;
    localStorage.setItem(LIVE_MAP_BOUNDS, JSON.stringify(newBounds));

    // Check for the Map Center.
    const mapCenter = fetchRegionMapCenter(lpRegion);
    const storedCenter = JSON.parse(
      localStorage.getItem(LIVE_MAP_CENTER) as string,
    );
    const newCenter = storedCenter || mapCenter;
    localStorage.setItem(LIVE_MAP_CENTER, JSON.stringify(newCenter));

    // Dispatch the update for the "Timezone" stored into the Session Storage.
    dispatch(updateStoredTimezone());
  }
};

/**
 * Updates the Login Phase based on the provided value:
 * @param loginPhase it can be any of the following values
 *   - BEFORE_ENTER_PHONE_NUMBER = When the user needs to provide a phone number.
 *   - BEFORE_ENTER_PIN = When the user has submitted a valid phone number.
 *   - LOGGED_IN = When the user is already logged.
 */
export const updateLoginPhase = (loginPhase: number) => {
  // Update the Login Phase into the session storage.
  localStorage.setItem(LOGIN_PHASE, loginPhase.toString());

  return {
    type: actionTypes.SESSION_UPDATE_LOGIN_PHASE,
    payload: loginPhase,
  };
};

/**
 * Updates the "Phone Number" into the Store once it has been submitted.
 * @param phoneNumber
 */
const updatePhoneNumber = (phoneNumber: string) => {
  // Update the Login Phase to "Before Enter Pin" and store the PhoneNumber.
  localStorage.setItem(LP_PHONE, phoneNumber);

  return {
    type: actionTypes.SESSION_UPDATE_PHONE_NUMBER,
    payload: phoneNumber,
  };
};

/**
 * Formats an error message depending on its type: Array | String.
 * It can also use a default message if there is no error message as well.
 * @param error
 * @param defaultMessage
 */
const _prettyErrorMessage = (error, defaultMessage) =>
  Array.isArray(error.details) && error.details.length
    ? `${error.details[0].title}: ${error.details[0].detail}`
    : error.message || defaultMessage;

/**
 * Submits a request looking for a user based on the provided "Phone Number".
 * @param phoneNumber
 */
export const submitPhoneNumber =
  (phoneNumber: string, triggeredByResendCode = false) =>
  async (dispatch) => {
    // Close any existing alert message.
    dispatch(closeAlertMessage());

    const amplitudeEvent = triggeredByResendCode
      ? LOGIN_SEND_PIN_CODE_EVENT
      : LOGIN_SUBMIT_PHONE_NUMBER_EVENT;
    const amplitudeParams = triggeredByResendCode ? {} : { phone: phoneNumber };

    try {
      // Search for the user based on the provided phone number.
      await api.get(
        'logistics_partner/v1/send_phone_login_code',
        {
          phone: phoneNumber,
        },
        false,
      );

      // Save the Phone Number into the Store.
      dispatch(updatePhoneNumber(phoneNumber));

      // Update the login phase.
      dispatch(updateLoginPhase(BEFORE_ENTER_PIN));

      // Log the action into Amplitude.
      logAction(LOGIN_PAGE, `${amplitudeEvent}: Button Click`, amplitudeParams);
    } catch (error) {
      const errorData: ApiError = {
        error,
        consoleMessage: 'Fail to get login code to login',
        amplitudePage: LOGIN_PAGE,
        amplitudeEvent: 'Enter Phone Number error',
        alertMessage: 'Invalid phone number',
      };
      dispatch(handleError(errorData));
    }
  };

/**
 * Updates the selected Region/Sub-region into the Store. Also, updates the selected value into the Session Storage.
 * @param region
 * @returns
 */
const updateCurrentRegion = (region: Region) => {
  // Update the session data into the session storage.
  localStorage.setItem(CURRENT_LP_REGION, JSON.stringify(region));

  return {
    type: actionTypes.SESSION_UPDATE_REGION,
    payload: region,
  };
};

/**
 * Recalculates the new Map Center and dispatches the update for the Selected Region/Sub-region.
 * @param region
 */
export const updateRegion = (region: Region) => (dispatch) => {
  // Get the bounds for the selected region.
  const regionBounds = fetchRegionMapBounds(region);

  // Calculate the new center based on the provided Region/Sub-region.
  const newCenter = fetchRegionMapCenter(region);

  // Dispatch the update for the Region Bounds into the Store.
  dispatch(updateMapBounds(regionBounds));

  // Dispatch the update for the Map Center into the Store.
  dispatch(updateMapCenter(newCenter));

  // Dispatch the update for the Current Region into the Store.
  dispatch(updateCurrentRegion(region));
};

/**
 * Logs in a user into the LP Portal and sets the session data.
 * @param sessionData
 * @param lpId
 */
const loginUser = (sessionData, lpId) => {
  const { lpToken, lpRegion, lpRegions, lpOperator } = sessionData;

  // Update the session data into the session storage.
  localStorage.setItem(LP_TOKEN, lpToken);
  localStorage.setItem(LP_ID, lpId);
  localStorage.setItem(LP_PROFILE, JSON.stringify(lpOperator));
  localStorage.setItem(LP_REGIONS, JSON.stringify(lpRegions));
  localStorage.setItem(CURRENT_LP_REGION, JSON.stringify(lpRegion));

  return {
    type: actionTypes.SESSION_LOGIN,
    payload: sessionData,
  };
};

/**
 * Submits a request for logging-in based on the provided "6-digit code".
 * @param pinNumber
 */
export const submitPinNumber =
  (pinNumber: string) => async (dispatch, getState) => {
    // Close any existing alert message.
    dispatch(closeAlertMessage());

    const { session } = getState();
    const phoneNumber = session.lpPhone;

    try {
      // Sign in with the provided phone number and pin.
      const res: LoginResponse = (
        await api.post(
          'logistics_partner/v1/login',
          {
            phone: phoneNumber,
            login_code: pinNumber,
          },
          'POST',
          DEFAULT_CONTENT_TYPE,
          false,
        )
      ).data;

      // Extract the required data from the response.
      const { token: lpToken, lpJuicerProfile } = res;
      const {
        attributes: { fleetGroupRegions: activeRegions, regions },
      } = lpJuicerProfile;
      const visibleRegions = activeRegions.length ? activeRegions : regions;
      const { firstName, lastName } = lpJuicerProfile.attributes;
      const lpId = lpJuicerProfile.id;
      const lpName = `${firstName} ${lastName}`;
      const lpOperator = {
        ...lpJuicerProfile,
        name: lpName,
      };
      const lpRegions = getUserRegions(visibleRegions);
      const lpRegion = lpRegions[0];

      // Set the session data to submit for Login-in.
      const sessionData = {
        isSignedIn: true,
        lpToken,
        lpRegion,
        lpRegions,
        lpOperator,
      };

      // Login the user into the LP Portal.
      dispatch(loginUser(sessionData, lpId));

      // Update the login phase.
      dispatch(updateLoginPhase(LOGGED_IN));

      // Update for the "Timezone" stored into the Session Storage.
      dispatch(updateStoredTimezone());

      // Update the current region with the first one from the avaliable Regions/Sub-regions.
      dispatch(updateRegion(lpRegion));

      // Relocate to dashbord.
      dispatch(push(`${BASE_PATH}/dashboard`));

      // Log the login action into Amplitude.
      logAction(LOGIN_PAGE, `${LOGIN_SUBMIT_PIN_CODE_EVENT}: Button Click`);

      // Get the user's browser info.
      const browserInfo = getBrowserInfo();

      // Log the user's browser info into Amplitude.
      logAction(LOGIN_PAGE, USER_BROWSER_INFO_EVENT, browserInfo);
    } catch (error) {
      const errorData: ApiError = {
        error,
        consoleMessage: `Fail to login using pin code ${pinNumber}`,
        amplitudePage: LOGIN_PAGE,
        amplitudeEvent: LOGIN_SUBMIT_PIN_CODE_EVENT,
        alertMessage: 'Incorrect pin code',
      };
      dispatch(handleError(errorData));
    }
  };

/**
 * Logs out a user from the LP Portal.
 */
export const logoutUser = () => (dispatch) => {
  const isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
  const loginPage: string = isImpersonating ? 'login/impersonate' : 'login';

  // Destroy all data into the session storage.
  clearStorage();

  // Trigger the action to logout the user.
  dispatch({
    type: actionTypes.SESSION_LOGOUT,
  });

  // Relocate to dashbord.
  dispatch(push(`${BASE_PATH}/${loginPage}`));
};

export const resizeWindow = (
  newWindowWidth: number,
  newContainerHeight: number,
  breakpointsValues: BreakpointsValues,
) => ({
  type: actionTypes.SESSION_UPDATE_WINDOW_SIZE,
  payload: {
    windowWidth: newWindowWidth,
    mainContainerHeight: newContainerHeight,
    breakpointsValues,
  },
});
