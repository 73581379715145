import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { BASE_PATH } from 'constants/app';
import { LOGGED_IN } from 'constants/loginPhases';
import { IS_IMPERSONATING, LOGIN_PHASE } from 'constants/localStorage';

type AccessProps = {
  isSignedIn: boolean;
  history: any;
};

const AccessControl = (
  WrappedComponent: React.ComponentType<unknown>,
): React.ComponentType<unknown> => {
  class SecuredControl extends PureComponent<AccessProps> {
    render() {
      const { isSignedIn } = this.props;
      const { location } = this.props.history;
      const isLoginPage = location.pathname.includes('/login');
      const loginPhase = Number(localStorage.getItem(LOGIN_PHASE));
      const isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
      const loginPage = isImpersonating ? 'login/impersonate' : 'login';

      if (!isLoginPage && !isSignedIn && loginPhase !== LOGGED_IN) {
        // Redirect to the login page if the user is not logged in and tries to access a valid section.
        return <Redirect to={`${BASE_PATH}/${loginPage}`} />;
      }

      if (isLoginPage && loginPhase === LOGGED_IN) {
        // Redirect to the Dashboard page is the user is already logged in and tries to access the login Page.
        return <Redirect to={`${BASE_PATH}/dashboard`} />;
      }

      // Render the Wrapped Component.
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ session }) => ({
    isSignedIn: session.isSignedIn,
  });

  return connect(mapStateToProps)(SecuredControl);
};

export default AccessControl;
