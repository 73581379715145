import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Operator } from 'types/operator.d';
import { datadogRum } from '@datadog/browser-rum';

const UserPositionStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.grey[600],
  }),
);

type OperatorNameAndPositionProps = {
  lpOperator: Operator;
};

const OperatorNameAndPosition = (props: OperatorNameAndPositionProps) => {
  const { lpOperator } = props;

  useEffect(() => {
    if (
      datadogRum.getInitConfiguration() !== undefined &&
      datadogRum.getUser()?.name === undefined &&
      lpOperator
    ) {
      datadogRum.setUser({
        id: lpOperator.id,
        name: lpOperator.name,
        email: lpOperator.attributes?.emailAddress,
      });
    }
  }, [lpOperator.id]);

  return (
    <>
      <Typography variant="subtitle2" data-e2e="operator-name">
        {lpOperator?.name}
      </Typography>
      <UserPositionStyle variant="body2" data-e2e="operator-position">
        Power Admin LP
      </UserPositionStyle>
    </>
  );
};

const mapStateToProps = ({ session }) => ({
  lpOperator: session.lpOperator,
});

export default connect(mapStateToProps)(OperatorNameAndPosition);
