import amplitude from 'amplitude-js/amplitude';
import { LOGIN_PAGE } from 'constants/amplitude';
import { LP_TOKEN } from 'constants/localStorage';
import { AMPLITUDE_API_KEY } from 'constants/settings';
import { getUserProperties } from 'lib/user';

declare const CURRENT_VERSION: string | undefined;

export const initAmplitude = (): void => {
  amplitude.getInstance().init(AMPLITUDE_API_KEY);
};

/**
 * Logs an event into Amplitude.
 * @param pageName
 * @param eventType
 * @param eventProperties
 */
export const logAction = (
  pageName: string,
  eventType: string,
  eventProperties = {},
): void => {
  const storedLpToken = localStorage.getItem(LP_TOKEN);

  // Avoid any API call to Amplitude in case the Token is expired.
  if (pageName !== LOGIN_PAGE && !storedLpToken) {
    return;
  }

  const userProperties = getUserProperties();
  const { id: lpToken, isImpersonated, phone, realPhone } = userProperties;
  const eventName = `LPv2 - ${pageName} - ${eventType}`;
  const userPhone = isImpersonated ? realPhone : phone;
  const userId = lpToken ? `${userPhone}#${lpToken}` : userPhone;

  amplitude.getInstance().setUserProperties(userProperties);
  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().logEvent(eventName, {
    ...eventProperties,
    event_type: eventName,
    time: Date.now(),
    app_version: CURRENT_VERSION,
  });
};
