import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { MdFiberNew } from 'react-icons/md';
import { Box, MenuItem, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import SvgIcon from 'components/svg';
import { pxToRem } from 'components/theme/typography';
import { MENU_SECTIONS, SectionGroupStyle, useStyles } from 'constants/menu';
import { VariantTypes, defaultNavbarColor } from 'constants/svg';
import { AppState } from 'reduxStore/types';
import {
  checkIsMenuItemAllowedForRegion,
  useSelectedMenuItem,
} from './menuUtils';

const MenuContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pb: pxToRem(16),
    '&:last-of-type': {
      flexGrow: 1,
    },
    width: '100%',
  }),
);

const MenuItemStyle = styled(MenuItem)(({ theme }) =>
  theme.unstable_sx({
    py: pxToRem(8),
    fontSize: 14,
    color: theme.palette.grey[600],
    width: '100%',
    '&.Mui-selected': {
      borderRight: `3px solid ${theme.palette.primary.main}`,
    },
  }),
);

const MenuItemLabelStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexGrow: 1,
  }),
);

type MobileMenuOptionsProps = {
  onOptionSelect: () => void;
};

const MobileMenuOptions: React.FC<MobileMenuOptionsProps> = ({
  onOptionSelect,
}) => {
  const classes = useStyles();
  const { palette, spacing } = useTheme();
  const [selectedItem, onRedirect] = useSelectedMenuItem();
  const iconStyles = { marginRight: spacing(2) };

  const { regionToken } = useSelector(
    (state: AppState) => state.session.lpRegion,
  );

  return (
    <>
      {MENU_SECTIONS.map((section, index) => (
        <Fragment key={section.id}>
          <SectionGroupStyle variant="overline" data-e2e={`menu-${index + 1}`}>
            {section.id}
          </SectionGroupStyle>
          <MenuContainerStyle data-e2e={`${section.id.toLowerCase()}-options`}>
            {section.items.map((item) => {
              const { icon, id, label, link, newRelease, gateKey } = item;
              const isMenuItemAllowedForRegion =
                checkIsMenuItemAllowedForRegion(id, regionToken);
              // if user is in allowed region, or sets the corresponding gateKey
              // in localStorage, show the item
              if (
                gateKey &&
                !localStorage.getItem(gateKey) &&
                !isMenuItemAllowedForRegion
              ) {
                return null;
              }
              const isSelected = selectedItem === id;
              const iconColor = isSelected
                ? palette.primary.main
                : defaultNavbarColor;

              return (
                <MenuItemStyle
                  key={id}
                  selected={isSelected}
                  onClick={() => onRedirect(id, link, onOptionSelect)}
                >
                  <SvgIcon
                    color={iconColor}
                    variant={icon as VariantTypes}
                    style={iconStyles}
                  />
                  <MenuItemLabelStyle>{label}</MenuItemLabelStyle>
                  {newRelease && <MdFiberNew className={classes.newRelease} />}
                </MenuItemStyle>
              );
            })}
          </MenuContainerStyle>
        </Fragment>
      ))}
    </>
  );
};

export default MobileMenuOptions;
