export default function IconButton(theme) {
  const { palette } = theme;
  return {
    MuiIconButton: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            '&:hover': {
              backgroundColor: palette.action.hover,
            },
          },
        },
        {
          props: { color: 'inherit' },
          style: {
            '&:hover': {
              backgroundColor: palette.action.hover,
            },
          },
        },
      ],

      styleOverrides: {
        root: {},
      },
    },
  };
}
