import { alpha } from '@mui/material/styles';

export default function Button(theme) {
  const { customShadows, palette } = theme;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: palette.grey[800],
          boxShadow: customShadows.z8,
          '&:hover': {
            backgroundColor: palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: customShadows.primary,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
        },
        containedSecondary: {
          boxShadow: customShadows.secondary,
          '&:hover': {
            backgroundColor: palette.secondary.main,
          },
        },
        containedInfo: {
          boxShadow: customShadows.info,
          '&:hover': {
            backgroundColor: palette.info.main,
          },
        },
        containedSuccess: {
          boxShadow: customShadows.success,
          '&:hover': {
            backgroundColor: palette.success.main,
          },
        },
        containedWarning: {
          boxShadow: customShadows.warning,
          '&:hover': {
            backgroundColor: palette.warning.main,
          },
        },
        containedError: {
          boxShadow: customShadows.error,
          '&:hover': {
            backgroundColor: palette.error.main,
          },
        },
        text: {
          fontSize: 12,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        outlinedInherit: {
          border: `1px solid ${palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: palette.action.hover,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            '&.Mui-disabled': {
              backgroundColor: alpha(palette.grey[900], 0.12),
            },
          },
        },
      },
    },
  };
}
