import React, { ReactNode, SyntheticEvent, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { EMPTY_VALUE_ERROR } from 'constants/notifications';
import { InputSize, InputVariant, SelectFilterType } from 'types/filtering.d';

const AutocompleteStyle = styled(Autocomplete)(({ theme }) =>
  theme.unstable_sx({
    overflow: 'hidden',
    '& .MuiInputLabel-asterisk': {
      color: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      flexWrap: 'unset',
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused': {
      flexWrap: 'wrap',
    },
    '& .MuiAutocomplete-input': {
      minWidth: 'unset',
    },
    '& .MuiChip-root': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      '& .MuiChip-deleteIcon': {
        color: alpha(theme.palette.common.white, 0.5),
        '&:hover': {
          color: alpha(theme.palette.common.white, 0.75),
        },
      },
    },
  }),
);

const fetchSelectedOption = (
  value: string | null,
  options: SelectFilterType[],
) => {
  if (!value) {
    return null;
  }

  return options.find((option) => option.value === value) || null;
};

const fetchSelectedOptions = (
  value: string[] | null,
  options: SelectFilterType[],
) => {
  if (!value) {
    return [];
  }

  return (
    options.filter((option) => value?.includes(option.value as string)) || []
  );
};

type FilterAutocompleteProps = {
  id?: string;
  className: string;
  label?: string;
  limitTags?: number;
  multiple?: boolean;
  options: SelectFilterType[];
  placeholder?: string;
  required?: boolean;
  size?: InputSize;
  startAdornment?: ReactNode;
  value?: string | string[] | null;
  variant?: InputVariant;
  onChange: (optionValue: string | string[]) => void;
};

export const FilterAutocomplete = (props: FilterAutocompleteProps) => {
  const {
    id,
    className,
    label,
    limitTags = 2,
    multiple = false,
    options,
    placeholder,
    required = false,
    size = 'medium',
    startAdornment,
    value = null,
    variant = 'outlined',
    onChange,
  } = props;
  const [singleValue, setSingleValue] = useState<SelectFilterType | null>(null);
  const [multiValues, setMultiValues] = useState<SelectFilterType[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const inputProps = startAdornment ? { startAdornment } : {};

  const handleOptionLabel = (currentOption: SelectFilterType) => {
    const { id: optionId, label: optionLabel } = currentOption;

    if (optionId) {
      return `${optionId}. ${optionLabel}`;
    }

    return optionLabel;
  };

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: any,
  ) => {
    if (multiple) {
      setMultiValues([...newValue]);
      const selectedValues = newValue.map((option) => option.value);
      onChange(selectedValues);
    } else {
      setSingleValue(newValue);
      onChange(newValue?.value);
    }
  };

  const validateMissingValue = (isValueDirty: boolean) => {
    if (!required) {
      return false;
    }

    if (multiple) {
      return (!value || value.length === 0) && isValueDirty;
    }

    return isEmpty(value) && isValueDirty;
  };

  const handleBlur = () => {
    const isValueMissing = validateMissingValue(true);
    if (!isValueMissing) {
      return;
    }

    const inputLabel = label || 'Field';
    setIsDirty(true);
    setErrorMsg(`${inputLabel} ${EMPTY_VALUE_ERROR}`);
  };

  useEffect(() => {
    if (multiple) {
      setMultiValues(fetchSelectedOptions(value as string[], options));
    } else {
      setSingleValue(fetchSelectedOption(value as string, options));
    }

    const isValueMissing = validateMissingValue(isDirty);
    if (isValueMissing) {
      const inputLabel = label || 'Field';
      setErrorMsg(`${inputLabel} ${EMPTY_VALUE_ERROR}`);
    } else {
      setErrorMsg('');
    }
  }, [value]);

  return (
    <AutocompleteStyle
      id={id}
      multiple={multiple}
      limitTags={limitTags}
      className={className}
      value={multiple ? multiValues : singleValue}
      onChange={handleChange}
      getOptionLabel={handleOptionLabel}
      options={options}
      size={size}
      autoComplete={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          size={size}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
          }}
          required={required}
          error={errorMsg !== ''}
          helperText={errorMsg}
        />
      )}
      onBlur={handleBlur}
      clearOnBlur
      clearOnEscape
      openOnFocus
      selectOnFocus
    />
  );
};
