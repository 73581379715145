export const PRODUCTION = [
  'production',
  'staging',
  'experimental',
  'experimental-1',
  'experimental-2',
  'latest',
].includes(process.env.NODE_ENV);
export const API_URL =
  process.env.API_URL || '//local-test.lime.bike:3000/api/';
export const BASE_URL = '';

export const HTTP_TIMEOUT = 90000;
export const MOBILE_WIDTH = 768;
export const INPUT_DEBOUNCE_TIME = 500; // ms
export const POD_RING_DEBOUNCE_TIME = 5000; // ms

export const BIKES_PER_PAGE = 1000;
export const USERS_PER_PAGE = 25;
export const OPERATORS_PER_PAGE = 25;
export const OPERATOR_CLOCK_INS_PER_PAGE = 10;
export const TRIPS_PER_PAGE = 250;
export const PROMOTIONS_PER_PAGE = 50;
export const LOCKS_PER_PAGE = 100;
export const TASKS_PER_PAGE = 300;

const DEV_MODE = process.env.NODE_ENV !== 'production';

export const S3_IMAGES_ROOT =
  'https://s3-us-west-1.amazonaws.com/limebike-com/images';
export const S3_IMAGES_ADMINTOOL = `${S3_IMAGES_ROOT}/admintool`;

export const AMPLITUDE_API_KEY = DEV_MODE
  ? process.env.AMPLITUDE_API_KEY_DEV
  : process.env.AMPLITUDE_API_KEY_PROD;

export const { MAPBOX_TOKEN } = process.env;

export const HEADER_HEIGHT = '92px';
export const SIDE_MENU_WIDTH = '280px';
