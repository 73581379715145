import { camelCase, isUndefined, upperFirst } from 'lodash';
import { FILTERS_MAP } from 'constants/filterOptions';
import { isBetween } from 'lib/generalUtils';
import {
  MapboxBounds,
  MapCoordinates,
  MapPoint,
  MapPointGroup,
} from 'types/maps.d';
import { CheckboxType } from 'types/filtering.d';
import { TasksDataType, MapFilters } from 'types/tasks.d';

/**
 * Returns the Map Center based on the provided Latitudes and Logitudes.
 * @param neLatitude
 * @param swLatitude
 * @param neLongitude
 * @param swLongitude
 * @returns MapCoordinates
 */
export const getMapCenter = (
  swLatitude: number,
  neLatitude: number,
  swLongitude: number,
  neLongitude: number,
): MapCoordinates => ({
  lat: (swLatitude + neLatitude) / 2.0,
  lng: (swLongitude + neLongitude) / 2.0,
});

export const isInBounds = (
  center: MapCoordinates,
  regionBounds: MapboxBounds,
) => {
  if (!center || !regionBounds.length) {
    return false;
  }

  const { lng, lat } = center;
  const [neBounds, swBounds] = regionBounds;
  const [neLng, neLat] = neBounds;
  const [swLng, swLat] = swBounds;

  return isBetween(lng, neLng, swLng) && isBetween(lat, neLat, swLat);
};

/**
 * Returns the map points to be rendered into the Live Map.
 * @param mapData
 * @returns MapPoint[]
 */
export const getMapPoints = (mapData: TasksDataType[]): MapPoint[] =>
  mapData.map((task) => {
    const {
      id,
      type: category,
      attributes: {
        bike: { attributes: bikeAttributes },
        taskDetailsPricing: {
          attributes: { potentialEarnings: price },
        },
        type: taskType,
      },
    } = task;
    const { latitude, longitude, typeName: vehicleType } = bikeAttributes;
    const taskName = FILTERS_MAP.taskType[taskType];
    const vehicleName = upperFirst(
      camelCase(FILTERS_MAP.vehicleType[vehicleType]),
    );
    const densityPin = `pin${taskName}`;
    const icon = `taskBadge${taskName}${vehicleName}`;

    return {
      type: 'Feature',
      properties: {
        category,
        cluster: false,
        densityPin,
        icon,
        id,
        price,
        taskName,
        taskType,
        vehicleName,
      },
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
    };
  });

/**
 * Returns the map points (grouped by Tasks) to be rendered into the Live Map.
 * This data is useful when working with clusters and it is required to group them by Tasks.
 * @param mapData
 * @returns MapPoint[]
 */
export const getMapPointsPerTask = (
  mapData: TasksDataType[],
): MapPointGroup => {
  const { taskType, vehicleType } = FILTERS_MAP;
  const mapPoints = Object.keys(taskType).reduce((acc, key) => {
    const keyName = camelCase(taskType[key]);
    acc[keyName] = [];
    return acc;
  }, {});

  mapData.forEach((task) => {
    // Get the required attributes from each task.
    const {
      id,
      type: category,
      attributes: {
        bike: { attributes: bikeAttributes },
        taskDetailsPricing: {
          attributes: { potentialEarnings: price },
        },
        type,
      },
    } = task;
    const { latitude, longitude, typeName } = bikeAttributes;
    const taskName = taskType[type];
    const vehicleName = upperFirst(camelCase(vehicleType[typeName]));
    const densityPin = `pin${taskName}`;
    const icon = `taskBadge${taskName}${vehicleName}`;
    const keyName = camelCase(taskType[type]);

    // Set the data for the current map point.
    const pointData = {
      type: 'Feature',
      properties: {
        category,
        cluster: false,
        densityPin,
        icon,
        id,
        price,
        taskName,
        taskType,
        vehicleName,
      },
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
    };

    // Add the map point data into its corresponding task type group.
    mapPoints[keyName].push(pointData);
  });

  return mapPoints;
};

/**
 * Updates a group of filters for the map.
 * @param reduxFilter
 * @param stored
 * @returns
 */
const updateFilter = (
  reduxFilter: CheckboxType[],
  storedFilter: CheckboxType[],
) =>
  reduxFilter.map((filter) => {
    const storedChecked = storedFilter.find(
      (option) => option.value === filter.value,
    )?.checked;
    const checked = isUndefined(storedChecked) ? filter.checked : storedChecked;
    return {
      label: filter.label,
      value: filter.value,
      checked,
    };
  });

/**
 * Updates the filters for the map.
 * This method identifies any changes on the labels or options re-order and update them.
 * @param reduxFilters
 * @param storedFilters
 * @returns
 */
export const updateMapFilters = (
  reduxFilters: MapFilters,
  storedFilters: MapFilters,
) => {
  const { vehicleType = [], taskType = [], mapOptions = [] } = storedFilters;
  const vehicles = updateFilter(reduxFilters.vehicleType, vehicleType);
  const tasks = updateFilter(reduxFilters.taskType, taskType);
  const options = updateFilter(reduxFilters.mapOptions, mapOptions);

  return {
    ...reduxFilters,
    vehicleType: [...vehicles],
    taskType: [...tasks],
    mapOptions: [...options],
  };
};
