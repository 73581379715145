import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { flagsUrl } from 'lib/browserLocation';
import { pxToRem } from './theme/typography';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
  }),
);

const FlagIconStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mr: pxToRem(8),
    width: pxToRem(28),
    borderRadius: pxToRem(3),
    boxSizing: 'content-box',
  }),
) as typeof Box;

const getCountryFlag = (countryCode: string): string =>
  flagsUrl.replace('{XX}', countryCode);

type CountryCodeProps = {
  countryCode: string;
};

const FlagSelector = (props: CountryCodeProps) => {
  const [flagSource, setFlagSource] = useState<string>('');
  const { countryCode } = props;

  useEffect(() => {
    setFlagSource(getCountryFlag(countryCode));
  }, [countryCode]);

  return (
    <RootStyle>
      <FlagIconStyle component="img" src={flagSource} />
    </RootStyle>
  );
};

export default FlagSelector;
