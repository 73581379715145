import { ThemeCustomShape } from 'types/global.d';

const shape: ThemeCustomShape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiusLg: 32,
};

export default shape;
