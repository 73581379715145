import React from 'react';
import { defaultColor } from 'constants/svg';

export const Charge = ({ color = defaultColor }) => (
  <>
    <path
      d="M7 7h10.002a2 2 0 0 1 2 2v3.501a4.5 4.5 0 0 1-4.5 4.5H9.5A4.5 4.5 0 0 1 5 12.502v-3.5a2 2 0 0 1 2-2Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.24 14 1-2h-2.4l1-2"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.08 7V3M15.921 7V3M12.001 21.002v-4"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ChargeWithColor = () => (
  <>
    <circle cx={16} cy={16} r={16} fill="#FFF2D4" fillOpacity={1.0} />
    <path
      d="m16.24 17.999 1-2h-2.4l1-2"
      stroke="#7A4F01"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.08 11V7M19.92 11V7M16 25v-4M11 11h10a2 2 0 0 1 2 2v3.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 9 16.5V13a2 2 0 0 1 2-2Z"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Deploy = ({ color = defaultColor }) => (
  <>
    <path
      d="M18.857 9.877V4.89A.89.89 0 0 0 17.968 4h-1.65a.89.89 0 0 0-.89.889v2.05"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m4 10.857 6.843-5.865a1.777 1.777 0 0 1 2.313 0L20 10.857"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.144 9.877v8.345c0 .982.795 1.778 1.777 1.778h4.19M18 18l2-2-2-2M14 16h6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const DeployWithColor = () => (
  <>
    <circle cx={16} cy={16} r={16} fill="#FFF3D6" fillOpacity={1.0} />
    <path
      d="M22.857 13.877V8.89A.89.89 0 0 0 21.968 8h-1.65a.89.89 0 0 0-.89.889v2.05"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8 14.857 6.843-5.865a1.777 1.777 0 0 1 2.313 0L24 14.857"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.144 13.877v8.345c0 .982.795 1.778 1.777 1.778h4.19"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m22 22 2-2-2-2M18 20h6"
      stroke="#FFC107"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Diagnose = ({ color = defaultColor }) => (
  <>
    <path
      d="M18.95 11.8a1.875 1.875 0 1 1-2.65 2.65 1.875 1.875 0 0 1 2.65-2.65ZM7.5 5.25H6a1.5 1.5 0 0 0-1.5 1.5v3a4.5 4.5 0 1 0 9 0v-3a1.5 1.5 0 0 0-1.5-1.5h-1.5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.625 15v.188a4.312 4.312 0 1 1-8.625 0v-.938M7.5 4.5V6M10.5 4.5V6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const DiagnoseWithColor = () => (
  <>
    <circle opacity={0.2} cx={16} cy={16} r={16} fill="#9E86FF" />
    <path
      d="M11.2 8.8H9.6A1.6 1.6 0 0 0 8 10.4v3.2a4.8 4.8 0 1 0 9.6 0v-3.2A1.6 1.6 0 0 0 16 8.8h-1.6"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 19.2v.2a4.6 4.6 0 1 1-9.2 0v-1M11.2 8v1.6M14.4 8v1.6"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.414 15.786a2 2 0 1 1-2.828 2.828 2 2 0 0 1 2.828-2.828Z"
      stroke="#9E86FF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Move = ({ color = defaultColor }) => (
  <>
    <path
      d="m5 16.632 1.987.01a2.502 2.502 0 0 0 2.093-1.114l5.174-7.761a2.5 2.5 0 0 1 2.09-1.114L20 6.667M18.334 18.298 20 16.632l-1.666-1.667M9.91 8.837l-.823-1.144a2.5 2.5 0 0 0-2.041-1.037L5 6.666"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.308 14.462.938 1.213a2.5 2.5 0 0 0 1.986.97L20 16.632M18.334 8.333 20 6.667 18.334 5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const MoveWithColor = () => (
  <>
    <circle cx={16} cy={16} r={16} fill="#00AB55" fillOpacity={0.12} />
    <path
      d="m8 21.407 2.12.01a2.668 2.668 0 0 0 2.232-1.187l5.52-8.279a2.667 2.667 0 0 1 2.228-1.187l3.9.014M22.222 23.185l1.777-1.778-1.777-1.778"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.238 13.092-.879-1.22a2.667 2.667 0 0 0-2.177-1.106L8 10.778"
      stroke="#00AB55"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.861 19.093 1.001 1.294a2.667 2.667 0 0 0 2.118 1.034l4.02-.014M22.222 12.556l1.777-1.778L22.223 9"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Service = ({ color = defaultColor }) => (
  <>
    <path
      d="m11.97 14.628 3.905 3.904a1.657 1.657 0 0 0 2.343-2.343l-3.905-3.904"
      stroke={color}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.062 7.48a.148.148 0 0 1 .04.067 3.572 3.572 0 0 1-.884 3.567c-.977.977-2.373 1.256-3.605.872l-6.518 6.518c-.635.635-1.67.682-2.327.07a1.657 1.657 0 0 1-.044-2.385l6.546-6.545c-.384-1.233-.105-2.629.872-3.606a3.573 3.573 0 0 1 3.567-.884.156.156 0 0 1 .067.04l.126.127c.06.06.06.16 0 .22L14.844 7.6l1.812 1.812 2.058-2.059c.061-.06.16-.06.221 0l.127.128v0ZM5.02 6.267l.526 1.58a.78.78 0 0 0 .741.534h1.78V6.6a.78.78 0 0 0-.534-.74l-1.58-.527a.39.39 0 0 0-.4.095l-.438.438a.39.39 0 0 0-.095.4v0ZM8.071 8.38l2.733 2.734"
      stroke={color}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ServiceWithColor = () => (
  <>
    <circle cx={16} cy={16} r={16} fill="#9E86FF" fillOpacity={0.12} />
    <path
      d="m15.824 18.99 4.382 4.456a1.838 1.838 0 0 0 2.63 0 1.914 1.914 0 0 0 0-2.674l-4.383-4.456"
      stroke="#212B36"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m11.446 11.86 3.068 3.119"
      stroke="#826AF9"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.782 10.832a.17.17 0 0 1 .045.075c.412 1.397.093 2.968-.992 4.072a3.964 3.964 0 0 1-4.047.996l-7.315 7.44c-.712.724-1.874.778-2.611.08a1.914 1.914 0 0 1-.05-2.723l7.347-7.471c-.43-1.408-.117-3 .98-4.116a3.962 3.962 0 0 1 4.003-1.009.174.174 0 0 1 .074.046l.142.144a.18.18 0 0 1 0 .252l-2.31 2.35 2.034 2.068 2.31-2.35c.068-.07.18-.07.248 0l.142.146v0Z"
      stroke="#212B36"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.023 9.446.59 1.803c.12.365.454.61.832.61h1.997V9.828a.89.89 0 0 0-.6-.846l-1.772-.6a.433.433 0 0 0-.449.107l-.492.5a.451.451 0 0 0-.106.457v0Z"
      stroke="#826AF9"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Retrieve = ({ color = defaultColor }) => (
  <>
    <path
      d="M18.857 9.877V4.89A.89.89 0 0 0 17.968 4h-1.65a.89.89 0 0 0-.89.889v2.05"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m4 10.857 6.843-5.865a1.777 1.777 0 0 1 2.313 0L20 10.857"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.144 9.877v8.345c0 .982.795 1.778 1.777 1.778h4.19M16 18l-2-2 2-2M20 16h-6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const RetrieveWithColor = () => (
  <>
    <circle cx={16} cy={16} r={16} fill="#FF4842" fillOpacity={0.12} />
    <path
      d="M22.857 13.877V8.89A.89.89 0 0 0 21.968 8h-1.65a.89.89 0 0 0-.89.889v2.05"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8 14.857 6.843-5.865a1.777 1.777 0 0 1 2.313 0L24 14.857"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.144 13.877v8.345c0 .982.795 1.778 1.777 1.778h4.19"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20 22-2-2 2-2M24 20h-6"
      stroke="#FF4842"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Swap = ({ color = defaultColor }) => (
  <>
    <path
      clipRule="evenodd"
      d="M17.5 7.417v11c0 1.013-.82 1.833-1.833 1.833H8.333A1.833 1.833 0 0 1 6.5 18.417v-11c0-1.013.82-1.834 1.833-1.834h7.334c1.013 0 1.833.82 1.833 1.834Z"
      stroke={color}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 5.583v-.93a.91.91 0 0 1 .097-.409L9.57 3.8a.916.916 0 0 1 .82-.507h3.22c.348 0 .665.196.82.507l.223.445a.91.91 0 0 1 .097.41v.93"
      stroke={color}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.1 16 1.5-3H10l1.5-3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const SwapWithColor = () => (
  <>
    <circle cx={16} cy={16} r={16} fill="#1890FF" fillOpacity={0.12} />
    <path
      clipRule="evenodd"
      d="M21.5 11.417v11c0 1.013-.82 1.833-1.833 1.833h-7.334a1.833 1.833 0 0 1-1.833-1.833v-11c0-1.013.82-1.834 1.833-1.834h7.334c1.013 0 1.833.82 1.833 1.834Z"
      stroke="#212B36"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 9.583v-.93a.91.91 0 0 1 .097-.409l.223-.445a.916.916 0 0 1 .82-.507h3.22c.348 0 .665.196.82.507l.223.445a.91.91 0 0 1 .097.41v.93"
      stroke="#212B36"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.101 20 1.5-3h-3.6l1.5-3"
      stroke="#1890FF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
