import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  loading: false,
  filters: {
    name: '',
    status: 'all',
  },
  page: 0,
  rowsPerPage: 50,
  subaccountsList: [],
  totalPages: 0,
  totalSubaccounts: 0,
  emailHelperMessage: '',
  isEmailHelperError: false,
  phoneHelperMessage: '',
  isPhoneHelperError: false,
  isCreateUpdateSuccessful: null,
};

const employeeManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEE_MANAGEMENT_FETCH_DATA:
      return {
        ...state,
        subaccountsList: action.payload.subaccountsList,
        totalSubaccounts: action.payload.totalSubaccounts,
        totalPages: action.payload.totalPages,
        emailHelperMessage: '',
        isEmailHelperError: false,
        phoneHelperMessage: '',
        isPhoneHelperError: false,
        isCreateUpdateSuccessful: null,
      };
    case actionTypes.EMPLOYEE_MANAGEMENT_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.EMPLOYEE_MANAGEMENT_PAGE_SETUP:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
      };
    case actionTypes.EMPLOYEE_MANAGEMENT_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case actionTypes.EMPLOYEE_MANAGEMENT_VALIDATE_EMAIL:
      return {
        ...state,
        emailHelperMessage: action.payload.emailHelperMessage,
        isEmailHelperError: action.payload.isEmailHelperError,
      };
    case actionTypes.EMPLOYEE_MANAGEMENT_VALIDATE_PHONE:
      return {
        ...state,
        phoneHelperMessage: action.payload.phoneHelperMessage,
        isPhoneHelperError: action.payload.isPhoneHelperError,
      };
    case actionTypes.EMPLOYEE_MANAGEMENT_CREATE_SUBACCOUNT:
    case actionTypes.EMPLOYEE_MANAGEMENT_UPDATE_SUBACCOUNT:
      return {
        ...state,
        isCreateUpdateSuccessful: action.payload,
      };
    default:
      return state;
  }
};

export default employeeManagementReducer;
