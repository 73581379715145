import React from 'react';
import { defaultColor } from 'constants/svg';

export const Assign = ({ color = defaultColor }) => (
  <>
    <path
      d="M7 20a4.447 4.447 0 0 1 4.447-4.447h5.106A4.447 4.447 0 0 1 21 20M16.97 5.23a4.2 4.2 0 1 1-5.94 5.94 4.2 4.2 0 0 1 5.94-5.94ZM2.5 12h4M4.5 14v-4"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Assigned = ({ color = defaultColor }) => (
  <>
    <path
      d="m6.95 11-3 3L2 12.05M7 20a4.447 4.447 0 0 1 4.447-4.447h5.106A4.447 4.447 0 0 1 21 20M16.97 5.23a4.2 4.2 0 1 1-5.94 5.94 4.2 4.2 0 0 1 5.94-5.94Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Call = ({ color = defaultColor }) => (
  <>
    <path
      d="M10.856 13.144a12.635 12.635 0 0 1-2.638-3.809.749.749 0 0 1 .166-.827l.82-.818c.67-.671.67-1.62.084-2.206L8.114 4.31a2 2 0 0 0-2.828 0l-.652.652c-.74.741-1.05 1.81-.85 2.87.494 2.613 2.012 5.474 4.461 7.923 2.45 2.449 5.31 3.967 7.923 4.461 1.06.2 2.13-.109 2.87-.85l.651-.651a2 2 0 0 0 0-2.828l-1.173-1.173a1.5 1.5 0 0 0-2.12 0l-.904.904a.749.749 0 0 1-.827.166 12.66 12.66 0 0 1-3.809-2.64v0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Email = ({ color = defaultColor }) => (
  <>
    <path
      d="M3 6.782c0 .591.293 1.144.782 1.475l5.981 4.053a4.001 4.001 0 0 0 4.488 0l5.966-4.044c.49-.33.783-.883.783-1.474v-.01C21 5.798 20.202 5 19.218 5H4.782C3.798 5 3 5.798 3 6.782v0Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7M3.586 18.414l6.14-6.14M14.28 12.281l6.13 6.13"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Password = ({ color = defaultColor }) => (
  <>
    <path
      d="M17 21H6.99a2 2 0 0 1-2-2c-.01-.01-.01-.01 0-.01v-7l-.01-.01c-.01-1.11.89-2 1.99-2 0-.001 0 0 0 0h9.99v-.001c1.1-.01 1.99.89 1.99 1.99 0 0 0 0 0 0v7c0 1.1-.9 1.99-2 1.99-.01 0-.01-.01-.01-.01L17 21Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.03 15.46c.02.02.02.05 0 .07-.03.02-.06.02-.08 0-.03-.03-.03-.06-.01-.08v-.02c.02-.03.05-.03.07 0M15.437 17v-3M8 10V7h-.01c0-2.21 1.79-4 3.99-4.01 2.2 0 3.99 1.79 4 4v3M8.59 15.46c.02.02.02.05 0 .07-.03.02-.06.02-.08 0-.03-.03-.03-.06-.01-.08v-.02c.02-.03.05-.03.07 0"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Percentage = ({ color = defaultColor }) => (
  <>
    <path
      d="M8.6 6.256c.517 0 .981.136 1.392.408.41.272.733.656.968 1.152.235.496.352 1.08.352 1.752 0 .672-.117 1.253-.352 1.744-.23.49-.55.872-.96 1.144-.41.267-.877.4-1.4.4-.528 0-1-.133-1.416-.4a2.822 2.822 0 0 1-.976-1.144c-.24-.49-.36-1.072-.36-1.744 0-.672.12-1.256.36-1.752s.565-.88.976-1.152A2.53 2.53 0 0 1 8.6 6.256Zm-.016 5.776c.512 0 .904-.227 1.176-.68.277-.453.416-1.048.416-1.784 0-.747-.139-1.347-.416-1.8-.277-.459-.67-.688-1.176-.688-.341 0-.632.107-.872.32-.235.208-.416.499-.544.872a4.153 4.153 0 0 0-.184 1.296c0 .73.139 1.325.416 1.784.277.453.672.68 1.184.68Zm7.256-.488c.523 0 .99.139 1.4.416.41.272.733.656.968 1.152.235.496.352 1.077.352 1.744 0 .672-.117 1.256-.352 1.752-.23.496-.55.88-.96 1.152-.41.267-.88.4-1.408.4-.528 0-1-.133-1.416-.4a2.735 2.735 0 0 1-.976-1.152c-.235-.496-.352-1.08-.352-1.752 0-.667.117-1.248.352-1.744.24-.496.568-.88.984-1.152a2.481 2.481 0 0 1 1.408-.416Zm-.016 5.776c.33 0 .616-.104.856-.312.24-.213.424-.504.552-.872.128-.368.192-.79.192-1.264 0-.485-.067-.915-.2-1.288-.128-.379-.312-.675-.552-.888a1.235 1.235 0 0 0-.848-.32c-.325 0-.608.11-.848.328-.24.213-.424.51-.552.888-.128.373-.192.8-.192 1.28 0 .475.064.896.192 1.264s.31.659.544.872c.24.208.525.312.856.312Zm.68-11.016-7.776 11.76-1-.008 7.808-11.752h.968Z"
      fill={color}
    />
  </>
);
