import React from 'react';
import palette from 'components/theme/palette';

type Props = {
  taskType:
    | 'charge'
    | 'diagnose'
    | 'move'
    | 'move'
    | 'retrieve'
    | 'service'
    | 'swap';
};

export const Ebike = ({ taskType }: Props) => (
  <>
    <circle
      cx={16}
      cy={16}
      r={16}
      fill={palette.task[taskType]}
      fillOpacity={0.16}
    />
    <path
      d="M17.466 7h-3.3L9.034 20.2M25.533 12.867h-2.567l-1.1 2.933M13.8 14.333l4.576 5.867H22.6"
      stroke={palette.task[taskType]}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={9.4}
      cy={20.2}
      r={4.4}
      stroke={palette.task[taskType]}
      strokeWidth={2}
    />
    <circle
      cx={22.6}
      cy={20.2}
      r={4.4}
      stroke={palette.task[taskType]}
      strokeWidth={2}
    />
  </>
);

export const Moped = ({ taskType }: Props) => (
  <>
    <circle
      cx={16}
      cy={16}
      r={16}
      fill={palette.task[taskType]}
      fillOpacity={0.16}
    />
    <path
      d="M13.912 10.321s-3.279 9.017 1.654 9.017c4.666 0 4.745-2.545 3.89-3.4h5.21c1.035.789 4.849 5.372 2.193 5.372-4.521 0-8.932-.005-13.222-.005-1.095.048-1.225-.843-1.225-.843-.333-1.225-1.604-3.415-3.267-3.415-1.541 0 1.922-5.445 3.105-6.726h1.662Z"
      fill={palette.task[taskType]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.09 10.174c.04-.045.099-.07.16-.07h1.662a.218.218 0 0 1 .205.292l-.205-.075.205.075v.001l-.002.005-.007.018a9.08 9.08 0 0 0-.12.356c-.08.244-.187.593-.301 1.011-.229.84-.481 1.953-.58 3.062-.101 1.116-.045 2.197.318 2.99.179.392.43.709.772.928.34.22.786.353 1.37.353 2.303 0 3.397-.629 3.833-1.282.436-.653.259-1.388-.098-1.746a.218.218 0 0 1 .154-.371h5.21c.047 0 .094.015.132.044.278.212.722.66 1.186 1.204.467.546.965 1.203 1.349 1.838.192.318.357.634.474.93.117.295.193.584.193.844 0 .265-.079.516-.288.695-.203.175-.494.251-.853.251-2.26 0-4.494 0-6.698-.002h-.092l-6.427-.002c-.616.026-.988-.216-1.2-.488a1.293 1.293 0 0 1-.242-.523v-.003c-.161-.583-.547-1.404-1.092-2.077-.552-.682-1.23-1.167-1.963-1.167a.62.62 0 0 1-.354-.1.547.547 0 0 1-.21-.286c-.07-.211-.044-.48.02-.753.134-.56.471-1.301.883-2.065.826-1.53 1.997-3.229 2.606-3.887Zm.51 10.153 1.105.76 6.37.003h.086l6.698.002c.306 0 .477-.067.57-.146.085-.074.136-.187.136-.365 0-.182-.055-.412-.162-.683a6.024 6.024 0 0 0-.443-.866c-.368-.609-.85-1.246-1.307-1.78-.425-.498-.817-.895-1.063-1.096h-4.708a1.87 1.87 0 0 1-.12 1.924c-.556.833-1.835 1.476-4.196 1.476-.65 0-1.182-.15-1.605-.422-.424-.274-.725-.661-.933-1.114-.41-.898-.458-2.072-.356-3.21.103-1.145.363-2.285.595-3.137a22.65 22.65 0 0 1 .34-1.134h-1.26c-.593.67-1.692 2.27-2.48 3.729-.408.758-.722 1.457-.841 1.959-.062.256-.062.423-.032.514.013.04.029.056.042.064.014.01.045.024.11.024.929 0 1.718.61 2.3 1.329a6.644 6.644 0 0 1 1.154 2.169Z"
      fill={palette.task[taskType]}
    />
    <circle
      cx={7.75}
      cy={21.25}
      r={2.75}
      stroke={palette.task[taskType]}
      strokeWidth={1.8}
    />
    <circle
      cx={22.75}
      cy={21.25}
      r={2.75}
      stroke={palette.task[taskType]}
      strokeWidth={1.8}
    />
    <path
      d="M19 15.2h6.5M8 21l6.218-13h2.328"
      stroke={palette.task[taskType]}
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const Scooter = ({ taskType }: Props) => (
  <>
    <circle
      cx={16}
      cy={16}
      r={16}
      fill={palette.task[taskType]}
      fillOpacity={0.16}
    />
    <circle
      cx={8.426}
      cy={22.197}
      r={1.804}
      stroke={palette.task[taskType]}
      strokeWidth={2}
    />
    <circle
      cx={23.575}
      cy={22.197}
      r={1.804}
      stroke={palette.task[taskType]}
      strokeWidth={2}
    />
    <path
      d="M21.735 21.8H14L10 17M10.5 14.5l2.218-7.85h4.328"
      stroke={palette.task[taskType]}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
