import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { MdFiberNew } from 'react-icons/md';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import SvgIcon from 'components/svg';
import { pxToRem } from 'components/theme/typography';
import { MENU_SECTIONS, SectionGroupStyle, useStyles } from 'constants/menu';
import { VariantTypes, defaultNavbarColor } from 'constants/svg';
import { AppState } from 'reduxStore/types';
import {
  checkIsMenuItemAllowedForRegion,
  useSelectedMenuItem,
} from './menuUtils';

const ListContainerStyle = styled(List)(({ theme }) =>
  theme.unstable_sx({
    py: pxToRem(24),
    '&:last-of-type': {
      flexGrow: 1,
    },
  }),
);

const ListItemStyle = styled(ListItemButton)(({ theme }) =>
  theme.unstable_sx({
    py: pxToRem(16),
    fontSize: 14,
    color: theme.palette.grey[600],
    '&.Mui-selected': {
      borderRight: `3px solid ${theme.palette.primary.main}`,
    },
  }),
);

const SideMenuOptions: React.FC = () => {
  const classes = useStyles();
  const { palette, spacing } = useTheme();
  const iconStyles = { marginRight: spacing(2) };
  const [selectedItem, onRedirect] = useSelectedMenuItem();

  const { regionToken } = useSelector(
    (state: AppState) => state.session.lpRegion,
  );

  return (
    <>
      {MENU_SECTIONS.map((section, index) => (
        <Fragment key={section.id}>
          <SectionGroupStyle variant="overline" data-e2e={`menu-${index + 1}`}>
            {section.id}
          </SectionGroupStyle>
          <ListContainerStyle data-e2e={`${section.id.toLowerCase()}-options`}>
            {section.items.map((item) => {
              const { icon, id, label, link, newRelease, gateKey } = item;
              const isMenuItemAllowedForRegion =
                checkIsMenuItemAllowedForRegion(id, regionToken);
              // if user is in allowed region, or sets the corresponding gateKey
              // in localStorage, show the item
              if (
                gateKey &&
                !localStorage.getItem(gateKey) &&
                !isMenuItemAllowedForRegion
              ) {
                return null;
              }
              const isSelected = selectedItem === id;
              const iconColor = isSelected
                ? palette.primary.main
                : defaultNavbarColor;

              return (
                <ListItemStyle
                  key={item.id}
                  selected={isSelected}
                  onClick={() => onRedirect(id, link)}
                >
                  <SvgIcon
                    color={iconColor}
                    variant={icon as VariantTypes}
                    style={iconStyles}
                  />
                  <ListItemText primary={label} />
                  {newRelease && <MdFiberNew className={classes.newRelease} />}
                </ListItemStyle>
              );
            })}
          </ListContainerStyle>
        </Fragment>
      ))}
    </>
  );
};

export default SideMenuOptions;
