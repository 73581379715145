import getSymbolFromCurrency from 'currency-symbol-map';
import Papa from 'papaparse';
import { isUndefined, padStart, snakeCase } from 'lodash';

export const parseGeneralCSV = (
  raw,
  { lowerCase = false } = {},
): Record<string, unknown>[] => {
  const results = Papa.parse(raw);
  const { data } = results;

  const parsedRows: Record<string, unknown>[] = [];
  const parsedColumns = data[0];

  for (let i = 1; i < data.length; i++) {
    const rawRow = data[i];
    const parsedRow = {};
    for (let j = 0; j < parsedColumns.length; j++) {
      const column = lowerCase
        ? parsedColumns[j].toLowerCase()
        : parsedColumns[j];
      parsedRow[column] = rawRow[j];
    }
    parsedRows.push(parsedRow);
  }

  return parsedRows;
};

export const camelcaseToUnderscore = (obj) => {
  if (!obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(camelcaseToUnderscore);
  }
  if (typeof obj === 'string') {
    return snakeCase(obj);
  }
  if (typeof obj !== 'object' || obj.constructor !== Object) {
    return obj;
  }
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const newKey = snakeCase(key);

    newObj[newKey] = camelcaseToUnderscore(obj[key]);
  });

  return newObj;
};

// Ruby convention is underscore, JS convention is camelcase.
// Todo(leo): write test.
export const underscoreToCamelcase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(underscoreToCamelcase);
  }
  if (!obj || typeof obj !== 'object' || obj.constructor !== Object) {
    return obj;
  }
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(
      /[a-z]_[a-z]/gi,
      (match) => match[0] + match[2].toUpperCase(),
    );

    newObj[newKey] = underscoreToCamelcase(obj[key]);
  });

  return newObj;
};

export const formatParamsForAPI = (
  params: Record<string, unknown>,
): Record<string, unknown> =>
  Object.keys(params).reduce((acc, key) => {
    acc[camelcaseToUnderscore(key)] = params[key];
    return acc;
  }, {});

export const isBetween = (
  value: number,
  minLim: number,
  maxLim: number,
): boolean => value >= minLim && value <= maxLim;

export const formatCentsToCurrencyValue = (
  amountCents: number,
  currencyCode: string,
): string => {
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const amount = (amountCents / 100)
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .toString();
  return `${currencySymbol} ${amount}`;
};

export const formatTimeToAmPm = (timeInHoursAndMinutes): string => {
  const [hours, minutes] = timeInHoursAndMinutes.split(':');
  const hoursAmPm = hours % 12;
  const hoursFormatted = hoursAmPm > 0 ? hoursAmPm : 12;
  const amPmLabel = hours >= 12 ? 'pm' : 'am';
  return `${hoursFormatted}:${minutes} ${amPmLabel}`;
};

export const fromMinutesToTime = (
  timeInMinutes: number,
  addSufix = false,
): string => {
  if (isUndefined(timeInMinutes)) {
    return '';
  }

  const newTime = timeInMinutes % (60 * 24);
  const hours = Math.floor(newTime / 60);
  const minutes = timeInMinutes % 60;
  const minutesStr = padStart(minutes.toString(), 2, '0');
  const timeSufix = addSufix ? 'hrs' : '';
  return `${hours}:${minutesStr} ${timeSufix}`;
};

export const isMinutesNextDay = (timeInMinutes: number): boolean =>
  timeInMinutes >= 60 * 24;

export const isValidEmail = (email: string): boolean =>
  /\S+@\S+\.\S+/.test(email);

// replace first chars with mid-dots
export const getMaskedId = (id?: string) =>
  id ? `${'\u00B7'.repeat(3)}${id.slice(7)}` : '--';
