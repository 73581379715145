export default function Slider(theme) {
  const { palette } = theme;
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          height: 4,
          '& .MuiSlider-track': {
            border: 'none',
          },
          // Styling for the slider points.
          '& .MuiSlider-thumb': {
            height: 12,
            width: 12,
            backgroundColor: palette.primary.main,
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          // Styling for the slider values. It shows the values into a pin similar to a Google map pin.
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 24,
            height: 24,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: palette.primary.main,
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
              transform: 'rotate(45deg)',
            },
          },
        },
      },
    },
  };
}
