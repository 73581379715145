export default function Tooltip(theme) {
  const { palette, spacing } = theme;
  return {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 1300,
          '&.MuiTooltip-popperArrow': {
            '& .MuiTooltip-tooltipPlacementTop': {
              marginBottom: spacing(1),
            },
          },
        },
        tooltip: {
          backgroundColor: palette.grey[800],
        },
        arrow: {
          color: palette.grey[800],
        },
      },
    },
  };
}
